/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from 'components/ui/button';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from 'utils';
import PurchasingCarInfo from './components/carInfo';
import usePurchaseStore from './store';
import OptionDescription from './components/optionDescription';
import { CarOption, CarOptionDescription } from '@carsayo/types';
import { getCarOptionDescription } from 'apis/purchase';
import { tr } from 'date-fns/locale';
import { optionValidation } from './utils/optionValidation';
import { CarsayoToastConfirm } from 'components/common/v2/CarsayoConfirm';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex h-3 w-9 shrink-0 cursor-pointer items-center rounded-full border-transparent bg-[#111111] transition-colors focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block h-5 w-5 rounded-full bg-white shadow-[1px_1px_3px_0_rgba(0,0,0,.10)] ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0 data-[state=checked]:bg-primary',
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export default function Option() {
  const navigate = useNavigate();

  const { setTopTabbarTitle } = useSystemStore();
  const { purchaseOrderDTO, setPurchaseOrderDTO, catalogue } =
    usePurchaseStore();

  const [carOptionDescriptionOpen, setCarOptionDescriptionOpen] =
    useState<boolean>(false);
  const [carOptionDescription, setCarOptionDescription] =
    useState<CarOptionDescription | null>(null);

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 55,
        currentPath: '/purchase/option',
      },
    });
  };

  const goBack = () => {
    if (carOptionDescriptionOpen === true) {
      setCarOptionDescriptionOpen(false);
      return;
    } else navigate('/purchase/color', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/method', { replace: true });
  };

  const handleClickEvents = {
    optionSwitch: (id: number) => {
      if (!purchaseOrderDTO?.orderData) return;
      const currentOptionIdSet = new Set(
        purchaseOrderDTO.orderData.carOptionList.map((el) => {
          return el.id;
        }),
      );
      // 누른 값이 기존 옵션배열에 있으면 제거, 없으면 추가
      if (currentOptionIdSet.has(id)) {
        currentOptionIdSet.delete(id);
      } else {
        currentOptionIdSet.add(id);
      }
      // 추가된 값 임시 저장
      const newOptionArray = Array.from(currentOptionIdSet);

      if (newOptionArray) {
        // 부모자식 관계 체크
        const result = includeCheck(
          Array.from(currentOptionIdSet),
          id,
          newOptionArray,
        );
        // 유효성 통과 시 임시 저장 값 저장
        if (result === true) {
          setPurchaseOrderDTO({
            id: purchaseOrderDTO.id,
            type: purchaseOrderDTO.type,
            update: {
              carOptionIdList: Array.from(newOptionArray),
            },
          });
        }
      }
    },
  };

  /**
   * 부모 찾아서 변환 : 배열에 부모옵션으로 변환 가능한 자식 옵션들이 있다면 전부 제거 후 부모 옵션으로 변경하여 전체 배열 반환
   * @param originData 전체 옵션 데이터 배열
   * @param newOptionArray 클릭한 옵션 포함 변경된 선택 옵션 배열
   * @returns 부모로 변경 가능한 옵션 변경 적용된 선택 옵션 배열(number[])
   */
  const parentSearchReturn = (
    originData: CarOption[],
    newOptionArray: number[],
  ): number[] => {
    let newChangeOptionArray: number[] = [];
    const getOptionArray = newOptionArray;
    const filterOriginData = originData.map((item) => {
      return {
        id: item.id,
        include: item.relation?.include ?? [],
        parent: item.relation?.parent ?? [],
      };
    });
    // 부모를 가지는 자식들이 전부 모였을때 해당 부모 키 반환
    const checkResult = filterOriginData.find(
      (obj) =>
        obj.include.length > 0 &&
        obj.include.every((includeItem) =>
          newOptionArray.includes(includeItem),
        ),
    );
    if (checkResult && checkResult !== undefined) {
      // 모여서 부모로 변경 가능한 자식들을 기존 옵션 배열에서 제거
      const changeOptionArray = newOptionArray.filter(
        (item) => !checkResult?.include.includes(item),
      );
      // 걸러진 값이 최상위 부모가 아닐때 바꿀 수 있는 옵션 변경 후 재귀적으로 탐색
      if (
        checkResult.parent &&
        checkResult.parent.length > 0 &&
        changeOptionArray.length > 0
      ) {
        const updateArray = [...changeOptionArray, checkResult.id];
        return parentSearchReturn(originData, updateArray);
      }
      // 걸러진 값이 최상위 부모일때
      else {
        newChangeOptionArray = [...changeOptionArray, checkResult.id];
        return newChangeOptionArray;
      }
    }
    // 걸러진 값이 최상위 부모일때
    else {
      newChangeOptionArray = getOptionArray;
      return newChangeOptionArray;
    }
  };

  /** 부모의 자식들이 체크여부
   * @param currentOptionIdSet 기존 선택 옵션 배열
   * @param parentId 부모옵션 아이디값
   * @returns 기존 선택 옵션 배열에 부모 옵션이 자식으로 가지는 모든 값들이 다 체크 됐다면 true반환.
   */
  const targetParentAllCheck = (
    currentOptionIdSet: number[],
    parentId: number,
  ) => {
    let returnValue = true; // 부모의 자식들이 다 체크됐다면 체크.
    if (currentOptionIdSet && parentId) {
      // 부모 옵션의 include값 서치
      const parentInclude = catalogue?.option?.data.find(
        (item) => item.id === parentId,
      )?.relation?.include;
      // 기존 선택 옵션 배열이 존재하고, 부모옵션이 자식을 가지고 있을때
      if (
        currentOptionIdSet &&
        currentOptionIdSet.length > 0 &&
        parentInclude
      ) {
        // 해당 부모 옵션의 자식들이 기존 선택 옵션 배열에 전부 존재하는지 여부 체크
        const result = parentInclude.every((item) =>
          currentOptionIdSet.includes(item),
        );
        if (result) {
          returnValue = true;
        } else {
          returnValue = false;
        }
      }
      // 기존 선택 옵션 배열이 존재하고, 자식을 가지고 있지 않으면 false반환
      else {
        returnValue = false;
      }
    } else {
      returnValue = false;
    }
    return returnValue;
  };
  /** 부모체크 : 통과하면 return값 number[]
   * @param currentOptionIdSet 기존 선택 옵션 배열
   * @param targetId 현재 클릭한 옵션 id값
   * @returns 타겟아이디값이 부모를 가지고 있을 때, 기존 선택 옵션 배열에 부모가 포함되면 해당 부모 옵션 or 옵션 배열 반환  / 없으면 undefined 반환
   */
  const targetParentCheck = (
    currentOptionIdSet: number[],
    targetId: number,
  ): number[] | undefined | false => {
    let resultData: number[] | undefined | false = undefined;
    const targetData = catalogue?.option?.data.find(
      (item) => item.id === targetId,
    );
    if (targetData) {
      const targetParentId = targetData.relation?.parent;
      if (targetParentId && targetParentId.length > 0) {
        const mechId = currentOptionIdSet.filter((item) =>
          targetParentId.includes(item),
        );
        if (mechId && mechId.length > 0) {
          resultData = mechId;
        } else {
          for (const item of targetParentId) {
            const parentCheckResult = targetParentCheck(
              currentOptionIdSet,
              item,
            );
            if (parentCheckResult) {
              resultData = parentCheckResult;
            }
          }
        }
      }
    }
    return resultData;
  };
  /** 자식체크 : 통과하면 return값 number[]
   * @param currentOptionIdSet 기존 선택 옵션 배열
   * @param targetId 현재 클릭한 옵션 id값
   * @returns 타겟아이디값이 자식을 가지고 있을 때, 기존 선택 옵션 배열에 자식들이 포함되면 자식 옵션 배열 전부 반환 / 없으면 undefined 반환
   */
  const targetChildCheck = (
    currentOptionIdSet: number[],
    targetId: number,
  ): number[] | undefined => {
    let resultData: number[] | undefined = undefined;
    const targetData = catalogue?.option?.data.find(
      (item) => item.id === targetId,
    );
    if (targetData) {
      const targetChildId = includeFull([targetData]);
      if (targetChildId && targetChildId.length > 0) {
        const mechId = currentOptionIdSet.filter((item) =>
          targetChildId.includes(item),
        );
        if (mechId && mechId.length > 0) {
          resultData = mechId;
        } else {
          for (const item of targetChildId) {
            const childCheckResult = targetChildCheck(currentOptionIdSet, item);
            if (childCheckResult) {
              resultData = childCheckResult;
            }
          }
        }
      }
    }
    return resultData;
  };

  /**
   * 자식 옵션이 다 모였으면 부모 옵션으로 변경
   * @param originData 전체 옵션 데이터 배열
   * @param addArray 현재 클릭한 옵션까지 반영한 선택 옵션 배열
   * @param currentOptionIdSet 기존 선택 옵션 id 배열
   * @param targetId 현재 클릭한 옵션 id
   * @param pass return 할 boolean값
   */
  const convertChildToParent = (
    originData: CarOption[],
    addArray: number[],
    currentOptionIdSet: number[],
    targetId: number,
    pass: boolean | undefined,
  ) => {
    const targetData = catalogue?.option?.data.find(
      (item) => item.id === targetId,
    );
    // 자식옵션을 전부 포함하여 부모옵션으로 변경될 것 있는지 체크
    const parentArrayResult = parentSearchReturn(originData, addArray);
    // 기존 선택 배열이 클릭옵션을 포함한다면
    if (currentOptionIdSet.includes(targetId)) {
      const parentArrayResultData = originData.filter((item) =>
        parentArrayResult.includes(item.id),
      );
      // 부모로 바뀔 수 있는 부분 바꾼 선택 옵션 배열 중 자식이 있는 부모옵션 찾기(현재 선택 배열 중 최상위 부모)
      const finalParent = parentArrayResultData.find(
        (el) => el.relation?.include && el.relation?.include.length > 0,
      );
      if (finalParent) {
        // 최상위 부모 옵션 id가 현재 선택 옵션 id와 같지 않을때, 기존 선택 옵션 리스트에 최상위 부모 옵션이 포함 안돼있을때,
        if (
          finalParent &&
          finalParent.id !== targetId &&
          targetData?.relation?.parent &&
          targetData?.relation?.parent.length > 0 &&
          currentOptionIdSet.find((item) => finalParent.id === item) ===
            undefined
        ) {
          CarsayoToastConfirm.confirm(
            `현재 선택된 옵션 중 [${finalParent?.name}]옵션에 포함된 옵션들이 있습니다. [${finalParent?.name}]옵션으로 변경하시겠습니까?`,
            () => {
              if (purchaseOrderDTO) {
                setPurchaseOrderDTO({
                  id: purchaseOrderDTO.id,
                  type: purchaseOrderDTO.type,
                  update: {
                    carOptionIdList: parentArrayResult,
                  },
                });
              }
              pass = false;
              return pass;
            },
            () => {},
          );
        }
      }
    }
  };

  /**
   * 옵션 유효성 체크 : 부모자식 체크(include)
   * @param currentOptionIdSet 기존 선택 옵션 배열
   * @param targetId 현재 클릭한 옵션 id
   * @returns 유효성검사 결과 값(boolean : true > 걸리는게 없어서 통과 / false > 걸리는게 있어서 내부에서 처리)
   */
  const includeCheck = (
    currentOptionIdSet: number[],
    targetId: number,
    updateArray: number[],
  ) => {
    let pass = true;
    if (targetId) {
      const targetData = catalogue?.option?.data.find(
        (item) => item.id === targetId,
      );
      // 선택된 옵션 중 이미 부모가 포함 돼 있으면 false를 바로 반환
      const parentId = targetParentCheck(currentOptionIdSet, targetId);
      if (parentId) {
        CarsayoToastConfirm.alert(
          `기존 선택 옵션에 [${targetData?.name}]옵션이 포함된 ${targetData?.relation?.parent && `[${catalogue?.option?.data.find((item) => targetData.relation?.parent.includes(item.id))?.name}]`}옵션이 이미 선택되어 있습니다.`,
          () => {},
        );
        pass = false;
        return false;
      } else {
        if (parentId === false) {
          pass = false;
        }
        // 아니라면 옵션 유효성체크 나머지 진행
        else {
          const otherResult = optionRequirements(
            currentOptionIdSet,
            targetId,
            updateArray,
          );
          if (otherResult) {
            pass = true;
          } else {
            pass = false;
            return false;
          }
        }
      }
      // 선택된 옵션 중 이미 자식이 포함돼 있는지 확인 후 있으면 바꿀건지 그냥 둘건지 선택.
      const childId = targetChildCheck(currentOptionIdSet, targetId);
      if (childId && purchaseOrderDTO) {
        // 클릭 옵션의 자식이 이미 기존 선택 옵션에 포함 돼 있으므로 false 반환
        CarsayoToastConfirm.confirm(
          `기존 선택 옵션 중 일부 옵션이 [${targetData?.name}]옵션에 포함돼 있습니다. 기존 선택 옵션을 해제하고 [${targetData?.name}]옵션으로 변경하시겠습니까?`,
          () => {
            // 포합돼 있는 자식 옵션 리스트 기존 선택 옵션에서 제거
            const newReturnArray = currentOptionIdSet.filter(
              (item) => !childId.includes(item),
            );
            // 변경 된 옵션배열 유효성 체크 진행
            const requirementResult = optionRequirements(
              currentOptionIdSet,
              targetId,
              newReturnArray,
            );
            // 문제 없다면 업데이트
            if (requirementResult) {
              setPurchaseOrderDTO({
                id: purchaseOrderDTO.id,
                type: purchaseOrderDTO.type,
                update: {
                  carOptionIdList: Array.from(newReturnArray),
                },
              });
              pass = false;
              return false;
            } else {
              pass = false;
              return false;
            }
          },
          () => {
            pass = false;
            return false;
          },
        );
        pass = false;
      }
      // 걸리는게 없다면 옵션 유효성 체크 나머지 진행
      else {
        const otherResult = optionRequirements(
          currentOptionIdSet,
          targetId,
          updateArray,
        );
        if (otherResult) {
          pass = true;
        } else {
          pass = false;
          return false;
        }
      }
    }

    // include, nessary, incompatible 유효성 검사를 통과했다면
    if (pass === true) {
      const newOptionData = catalogue?.option?.data.filter((data) =>
        updateArray.includes(data.id),
      );
      const targetData = catalogue?.option?.data.find(
        (data) => targetId === data.id,
      );
      const originData = catalogue?.option?.data.filter((el) => {
        return el.price > 0 && !el.name.includes('외장색상');
      });
      if (originData && newOptionData && newOptionData.length > 0) {
        convertChildToParent(
          originData,
          updateArray,
          currentOptionIdSet,
          targetId,
          pass,
        );
      }
    }
    return pass;
  };

  /**
   * 해당 배열의 자식의 자식까지 자식은 전부 찾아서 한 배열로 반환
   * @param array 옵션 배열
   * @returns 자식이 있다면 전부 묶어서 반환(number[])
   */
  const includeFull = (array: CarOption[]): number[] => {
    const fullArray: number[] = [];

    const recursiveInclude = (arr: CarOption[], isFirstCall: boolean) => {
      arr.forEach((el) => {
        // 첫 번째 호출인 경우, 자신의 ID를 저장하지 않음, 그 이외는 아이디 저장.
        if (!isFirstCall && !fullArray.includes(el.id)) {
          fullArray.push(el.id);
        }

        // include가 있는지 확인하고 재귀 호출
        if (
          el.relation &&
          el.relation?.include &&
          el.relation.include.length > 0
        ) {
          const relatedData = catalogue?.option?.data.filter((item) =>
            el.relation?.include.includes(item.id),
          );

          // 자식이 또 존재하면 재귀 호출
          if (relatedData && relatedData.length > 0) {
            recursiveInclude(relatedData, false); // 재귀 호출할 때는 첫 호출이 아님
          }
        }
      });
    };

    recursiveInclude(array, true); // 초기 배열로 재귀 호출,이때는 첫 호출
    return fullArray;
  };

  /**
   * 옵션의 동시 선택 불가(incompatible) 전부 찾아서 한 배열로 반환
   * @param target
   * @returns 자식의 incompatible까지 전부 한 배열로 묶어서 반환(number[])
   */
  const incompatibleFull = (target: CarOption): number[] => {
    const fullArray: number[] = [];
    // 옵션의 자식이 있다면 전부 가지고 오기
    const includeArray = includeFull([target]);
    const originArray = [...includeArray, target.id];
    const originData = catalogue?.option?.data.filter((item) =>
      originArray.includes(item.id),
    );
    if (originData) {
      // 받은 옵션의 자식까지 순환하며 incompatible값이 있다면 push
      originData.forEach((el) => {
        if (el.relation?.incompatible) {
          el.relation.incompatible.map((item) => fullArray.push(item));
        }
      });
    }
    return fullArray;
  };

  /**
   * 옵션의 선행조건(nessary) 전부 찾아서 한 배열로 반환
   * @param target
   * @returns 자식의 nessary까지 전부 한 배열로 묶어서 반환(number[])
   */
  const nessaryFull = (target: CarOption): number[] => {
    const fullArray: number[] = [];
    // 옵션의 자식이 있다면 전부 가지고 오기
    const includeArray = includeFull([target]);
    const originArray = [...includeArray, target.id];
    const originData = catalogue?.option?.data.filter((item) =>
      originArray.includes(item.id),
    );
    if (originData) {
      // 받은 옵션의 자식까지 순환하며 nessary값이 있다면 push
      originData.forEach((el) => {
        if (el.relation?.nessary) {
          el.relation.nessary.map((item) => fullArray.push(item));
        }
      });
    }
    return fullArray;
  };

  /**
   * 옵션 유효성 체크 : 선행 조건 체크(nessary)
   * @param targetId 현재 클릭한 옵션 id
   * @param updateArray 현재 클릭한 옵션까지 반영한 선택 옵션 배열
   * @returns 선행조건 배열의 옵션값이 선택 배열에 다 있어서 유효성겁사 통과하면 선행조건 배열 반환(number[]) / 곂치는게 없어 유효성 겁사 통과 못하면 false 반환
   */
  const nessaryCheck = (
    targetId: number,
    updateArray: number[],
  ): number[] | undefined | false => {
    let resultData: number[] | undefined | false = undefined;
    const updateArrayData = catalogue?.option?.data.filter((item) =>
      updateArray.includes(item.id),
    );
    let lastArray: number[] = [...updateArray];
    let nessaryArray: number[] = [];
    // 선택값이 추가될때
    if (updateArray.includes(targetId) && updateArrayData) {
      const targetData = catalogue?.option?.data.find(
        (item) => item.id === targetId,
      );
      if (targetData) {
        // 클릭 옵션의 선행 조건 전부 가져오기
        const targetNessary = nessaryFull(targetData);
        nessaryArray = [...nessaryArray, ...targetNessary];
      }
      // 클릭 옵션의 id와 그의 자식들이 있다면 자식들의 id까지 전부 추가
      lastArray = [...lastArray, targetId, ...includeFull(updateArrayData)];
    }
    // 선택값이 제거될때
    else {
      if (updateArrayData) {
        // 클릭 옵션의 자식까지 전부 가져오기
        const resultData = includeFull(updateArrayData);
        // 클릭 옵션의 자식들과 현재 선택옵션의 아이디값 병합
        const newResultData =
          updateArrayData.length === 1
            ? [...resultData, ...updateArrayData.map((item) => item.id)]
            : [...resultData, ...updateArrayData.map((item) => item.id)];
        if (newResultData && newResultData.length > 0) {
          const filter = catalogue?.option?.data.filter((item) =>
            newResultData.includes(item.id),
          );
          // 선택된 옵션의 자식포함 모든 옵션 아이디값들의 선행조건 비교해서 가져오기
          if (filter) {
            filter.forEach((el) => {
              if (el.relation?.nessary) {
                nessaryArray = [...nessaryArray, ...el.relation.nessary];
              }
            });
          }
        }
      }
    }
    if (updateArrayData && nessaryArray.length > 0) {
      lastArray = [...lastArray, ...includeFull(updateArrayData)];
      // 비교해야할 옵션배열에 선행조건이 모두 포함되는지 확인
      const result = nessaryArray.every((item) => lastArray.includes(item));
      if (result) {
        // 통과되면 선행조건배열 반환
        resultData = nessaryArray;
      } else {
        // 포함 안되면 통과 못함. false반환
        if (updateArray.includes(targetId)) {
          CarsayoToastConfirm.alert(
            `선행 옵션으로 [${catalogue?.option?.data.filter((data) => nessaryArray.includes(data.id)).map((item) => item.name)}]옵션이 필요합니다.`,
            () => {},
          );
        } else {
          CarsayoToastConfirm.alert(
            `[${catalogue?.option?.data.filter((data) => nessaryArray.includes(data.id)).map((item) => item.name)}]옵션은 기존 옵션의 선행 조건을 충족하는 옵션이므로 변경 불가합니다.`,
            () => {},
          );
        }

        resultData = false;
        return false;
      }
    }

    return resultData;
  };

  /**
   * 옵션 유효성 체크 : 그룹 관계 체크(incompatible)
   * @param targetId 현재 클릭한 옵션 id
   * @param updateArray 현재 클릭한 옵션까지 반영한 선택 옵션 배열
   * @returns 동시존재불가 배열의 옵션값이 선택 배열에 매칭되는게 없어서 유효성겁사 통과하면 동시존재불가 배열 반환(number[]) / 하나라도 곂쳐서 유효성 겁사 통과 못하면 false 반환
   */
  const inCompatibleCheck = (
    targetId: number,
    updateArray: number[],
  ): number[] | undefined | false => {
    let resultData: number[] | undefined | false = undefined;
    const updateArrayData = catalogue?.option?.data.filter((item) =>
      updateArray.includes(item.id),
    );
    let lastArray: number[] = [...updateArray];
    let incompatibleArray: number[] = [];
    // 선택값이 추가될때
    if (updateArray.includes(targetId) && updateArrayData) {
      const targetData = catalogue?.option?.data.find(
        (item) => item.id === targetId,
      );
      if (targetData) {
        // 현재 클릭 옵션의 자식까지 동시 존재 불가능한 옵션 전부 가져오기
        const targetIncompatible = incompatibleFull(targetData);
        if (
          targetData &&
          targetData.relation?.incompatible &&
          targetData.relation?.incompatible.length > 0
        ) {
          incompatibleArray = [...incompatibleArray, ...targetIncompatible];
        }
        // 현재 클릭 옵션의 자식까지 전부 추가
        lastArray = [...lastArray, ...includeFull(updateArrayData)];
      }
    }

    if (updateArrayData && incompatibleArray.length > 0) {
      updateArrayData.forEach((el) => {
        if (el.relation?.include) {
          lastArray = [...lastArray, ...el.relation.include];
        }
      });
      // 비교해야할 옵션배열에 동시존재불가 배열의 옵션값이 하나라도 포함되는지 확인
      const result = incompatibleArray.some((item) => lastArray.includes(item));
      if (result) {
        // 포함되면 유효성검사 통과 못함. false반환
        resultData = false;
        return false;
      } else {
        // 포함되지 않으면 유효성검사 통과. 동시존재불가 옵션배열 반환
        resultData = incompatibleArray;
      }
    }

    return resultData;
  };

  /**
   * 동시선택 불가 옵션에 걸렸을때, 다른 옵션으로 변경 가능 여부 확인 후 액션
   * @param currentOptionIdSet 기존 선택 옵션 id 배열
   * @param updateArray 현재 클릭한 옵션까지 반영한 선택 옵션 배열
   * @param targetIncludes 클릭한 옵션의 자식까지 전부 모은 배열
   * @param targetIncompatibles 클릭한 옵션의 자식들의 incompatible까지 전부 모은 배열
   * @param targetId 클릭한 옵션 id
   * @param pass 체크하고 반환해야할 값
   * @returns 변경 가능하면 변경하고 return pass = false / 아니면 alert 띄워주고 빠져나옴.
   */
  const incompatibleOptionUpdate = (
    currentOptionIdSet: number[],
    updateArray: number[],
    targetIncludes: number[],
    targetIncompatibles: number[],
    targetId: number,
    pass: boolean | undefined,
  ) => {
    const targetData = catalogue?.option?.data.find(
      (item) => item.id === targetId,
    );
    const newOptionData = catalogue?.option?.data.filter((data) =>
      updateArray.includes(data.id),
    );
    const originData = catalogue?.option?.data.filter((el) => {
      return el.price > 0 && !el.name.includes('외장색상');
    });
    if (targetData && purchaseOrderDTO) {
      const newUpdateArray = updateArray.filter(
        (array) => !targetIncompatibles.includes(array),
      );
      const removeInclude =
        targetIncludes && targetIncludes.length > 0
          ? newUpdateArray.filter((item) => !targetIncludes.includes(item))
          : newUpdateArray;
      const addArray = [...removeInclude, targetId];
      const nessaryCheckResult = nessaryCheck(targetId, addArray);
      const incompatiblesCheck = addArray.filter((item) =>
        targetIncompatibles.includes(item),
      );
      const incompatibleCheckResult =
        incompatiblesCheck && incompatiblesCheck.length > 0
          ? false
          : incompatiblesCheck;
      // 선행조건이나 동시선택불가 조건 통과하는지 확인.
      if (
        (nessaryCheckResult || nessaryCheckResult === undefined) &&
        (incompatibleCheckResult || incompatibleCheckResult === undefined)
      ) {
        // 통과하면 변경 후 유효성에는 안걸렸지만 제안할 수 있는 부모자식변환 체크
        setPurchaseOrderDTO({
          id: purchaseOrderDTO.id,
          type: purchaseOrderDTO.type,
          update: {
            carOptionIdList: Array.from(addArray),
          },
        });

        if (originData && newOptionData && newOptionData.length > 0) {
          convertChildToParent(
            originData,
            addArray,
            currentOptionIdSet,
            targetId,
            pass,
          );
        }
        pass = false;
        return false;
      } else {
        // 선행조건에 걸렸을 때
        if (nessaryCheckResult === false) {
          CarsayoToastConfirm.alert(
            `[${targetData.name}]옵션은 기존 옵션의 선행 조건을 충족하는 옵션이므로 변경 불가합니다.`,
          );
        }
        // 동시선택불가 조건에 걸렸을 때
        else if (incompatibleCheckResult === false) {
          CarsayoToastConfirm.alert(
            `[${targetData.name}]옵션은 동시 선택이 불가한 [${catalogue?.option?.data.filter((data) => targetIncompatibles.includes(data.id)).map((item) => item.name)}]옵션이 존재하여 변경 불가합니다.`,
          );
        }
        // 둘 다 걸렸을 때
        else {
          CarsayoToastConfirm.alert(
            `[${targetData.name}]옵션은 두 개 이상의 조건에 부합하지 않습니다.`,
          );
        }
        pass = false;
        return false;
      }
    }
  };

  /**
   * 옵션 유효성 체크 묶음 (선행 nessary, 그룹 incompatible)
   * @param newOptionArray 새로 선택한 옵션을 포함한 옵션 id 배열
   * @param targetId 현재 클릭한 옵션 id
   * @returns 유효성검사 결과 값(boolean : true > 걸리는게 없어서 통과 / false > 걸리는게 있어서 내부에서 처리)
   */
  const optionRequirements = (
    currentOptionIdSet: number[],
    targetId: number,
    updateArray: number[],
  ) => {
    let pass: boolean | undefined = true;
    if (targetId) {
      const targetData = catalogue?.option?.data.find(
        (item) => item.id === targetId,
      );

      /** 선행 관계 체크 */
      const nessaryResult = nessaryCheck(targetId, updateArray);
      if (nessaryResult) {
        pass = true;
      } else {
        if (nessaryResult === false) {
          pass = false;
          return false;
        }
        pass = true;
      }

      /** 그룹 관계 체크 */
      const incompatibleResult = inCompatibleCheck(targetId, updateArray);
      if (incompatibleResult) {
        pass = true;
      } else {
        // 동시선택불가 유효성 검사 통과 못했을때
        if (incompatibleResult === false && targetData && purchaseOrderDTO) {
          CarsayoToastConfirm.confirm(
            `선택하신 [${targetData.name}]옵션은 ${catalogue?.option?.data
              .filter((data) =>
                targetData?.relation?.incompatible.includes(data.id),
              )
              .map((item) => `[${item.name}]`)
              .join(', ')}옵션과 동시 선택될 수 없습니다. 변경하시겠습니까?`,
            () => {
              const currentData = catalogue?.option?.data.filter((item) =>
                updateArray.includes(item.id),
              );
              if (currentData) {
                const targetIncludes = includeFull(currentData);
                const targetIncompatibles = incompatibleFull(targetData);

                if (
                  targetIncludes.filter((el) =>
                    targetIncompatibles.includes(el),
                  ).length > 0
                ) {
                  const incompatibleDuplication = targetIncludes.filter((el) =>
                    targetIncompatibles.includes(el),
                  );
                  CarsayoToastConfirm.alert(
                    `기존 선택 옵션에 동시 선택이 불가능한 ${catalogue?.option?.data
                      .filter((item) =>
                        incompatibleDuplication.includes(item.id),
                      )
                      .map((el) => `[${el.name}]`)
                      .join(', ')}옵션이 포함되어 변경 불가합니다.`,
                  );
                  pass = false;
                  return false;
                }
                // 동시선택 불가 옵션 변경 조건 확인 후 액션
                incompatibleOptionUpdate(
                  currentOptionIdSet,
                  updateArray,
                  targetIncludes,
                  targetIncompatibles,
                  targetId,
                  pass,
                );
              }
            },
            () => {
              pass = false;
              return false;
            },
          );
          pass = false;
          return false;
        }
        pass = true;
      }
    }
    return pass;
  };

  const openOptionDescription = async (descriptionId: number) => {
    setCarOptionDescription(
      await getCarOptionDescription({
        carOptionDescriptionId: descriptionId,
      }),
    );
    setCarOptionDescriptionOpen(true);
    return;
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, [carOptionDescriptionOpen]);

  useLayoutEffect(() => {
    setTopTabbarTitle('옵션 선택');
    setData();
  }, []);

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-auto overflow-y-scroll'>
        <PurchasingCarInfo></PurchasingCarInfo>
        <div className='px-4 py-8'>
          <div className='text-lg font-semibold'>옵션 선택</div>
          <div className='mt-4 space-y-4'>
            {catalogue?.option &&
            catalogue.option.data.filter((el) => {
              return el.price > 0;
            }).length > 0 ? (
              catalogue.option.data
                .filter((el) => {
                  return el.price > 0 && !el.name.includes('외장색상');
                })
                .map((el, index) => (
                  <label
                    id='option'
                    key={el.id}
                    className={cn(
                      'flex gap-2 justify-between rounded-md border bg-[#F7F7FB] pl-4 pr-2 py-6 text-right',
                      purchaseOrderDTO?.orderData?.carOptionList &&
                        purchaseOrderDTO.orderData.carOptionList.find((el2) => {
                          return el2.id === el.id;
                        }) !== undefined &&
                        'border-primary',
                    )}
                  >
                    <Switch
                      checked={
                        purchaseOrderDTO?.orderData?.carOptionList &&
                        purchaseOrderDTO.orderData.carOptionList.find((el2) => {
                          return el2.id === el.id;
                        }) !== undefined
                      }
                      onClick={() => {
                        if (!purchaseOrderDTO?.orderData) return;
                        if (!catalogue?.option) return;

                        let optionList: CarOption[] =
                          purchaseOrderDTO.orderData.carOptionList;

                        /**
                         * @true 선택 추가
                         * @false 선택 해제
                         */
                        const isAdded =
                          optionList.find((el2) => {
                            return el2.id === el.id;
                          }) === undefined
                            ? true
                            : false;

                        if (!isAdded) {
                          optionList = optionList.filter((el2) => {
                            return el2.id !== el.id;
                          });
                        } else {
                          optionList = [...optionList, el];
                        }

                        const optionValidity = optionValidation({
                          optionList: optionList,
                          selectedOption: el,
                          isAdded: isAdded,
                          optionData: catalogue.option.data,
                          option: { isInit: true },
                        });

                        if (optionValidity.validation.error === true) {
                          if (optionValidity.suggestion) {
                            CarsayoToastConfirm.confirm(
                              optionValidity.suggestion.message.join('\n'),
                              () => {
                                if (!optionValidity.suggestion) return;
                                setPurchaseOrderDTO({
                                  id: purchaseOrderDTO.id,
                                  type: purchaseOrderDTO.type,
                                  update: {
                                    carOptionIdList:
                                      optionValidity.suggestion.optionList.map(
                                        (el) => {
                                          return el.id;
                                        },
                                      ),
                                  },
                                });
                              },
                              () => {},
                            );
                          } else {
                            CarsayoToastConfirm.alert(
                              optionValidity.validation.errorMessage.length > 0
                                ? optionValidity.validation.errorMessage.join(
                                    '\n',
                                  )
                                : '현재 선택할 수 없는 옵션입니다.',
                              () => {
                                return;
                              },
                            );
                          }
                          return;
                        } else {
                          if (optionValidity.suggestion) {
                            CarsayoToastConfirm.confirm(
                              optionValidity.suggestion.message.join('\n'),
                              () => {
                                if (!optionValidity.suggestion) return;
                                setPurchaseOrderDTO({
                                  id: purchaseOrderDTO.id,
                                  type: purchaseOrderDTO.type,
                                  update: {
                                    carOptionIdList:
                                      optionValidity.suggestion.optionList.map(
                                        (el) => {
                                          return el.id;
                                        },
                                      ),
                                  },
                                });
                              },
                              () => {},
                            );
                          }
                        }

                        setPurchaseOrderDTO({
                          id: purchaseOrderDTO.id,
                          type: purchaseOrderDTO.type,
                          update: {
                            carOptionIdList: optionList.map((el) => {
                              return el.id;
                            }),
                          },
                        });
                      }}
                      id='option'
                      className='bg-[#111111] my-1'
                    />
                    <div className='flex-auto'>
                      <div className='text-title text-[#111] font-normal leading-4'>
                        {el.name}
                      </div>
                      <div className='mt-2 text-desc text-[#555555]'>
                        + {el.price.toLocaleString()}원
                      </div>
                    </div>
                    {catalogue.option &&
                    catalogue.option.data.find((el) => {
                      if (el.descriptionId !== null) return true;
                    }) ? (
                      <div
                        className={cn(
                          'flex justify-center items-center flex-none px-2',
                          !el.descriptionId && 'invisible',
                        )}
                        onClick={(event) => {
                          event.preventDefault();
                          if (el.descriptionId)
                            openOptionDescription(el.descriptionId);
                        }}
                      >
                        <img
                          className='w-6'
                          src='/assets/images/v2/info-circle.svg'
                          alt=''
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </label>
                ))
            ) : (
              <div className='rounded-md border bg-[#F7F7FB] py-6'>
                <div className='mx-auto h-[41px] w-[50px]'>
                  <img
                    src='/assets/images/v2/nooption.png'
                    alt='no option icon'
                  />
                </div>
                <div className='mt-6 text-center text-title text-[#55555]'>
                  추가 옵션이 없습니다.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goBack}
        >
          이전
        </Button>
        <Button
          className='rounded-l-none'
          onClick={async () => {
            if (!purchaseOrderDTO) return;
            await setPurchaseOrderDTO({
              id: purchaseOrderDTO.id,
              type: purchaseOrderDTO.type,
              update: {
                progress: 50,
                carOptionIdList:
                  catalogue?.option &&
                  catalogue.option.data.filter((el) => {
                    return el.price > 0;
                  }).length === 0
                    ? null
                    : undefined,
              },
            });
            goNext();
          }}
        >
          다음
        </Button>
      </div>
      {carOptionDescription && (
        <OptionDescription
          isOpen={carOptionDescriptionOpen}
          setIsOpen={setCarOptionDescriptionOpen}
          carOptionDescription={carOptionDescription}
        ></OptionDescription>
      )}
    </div>
  );
}
