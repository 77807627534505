/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CarOption } from '@carsayo/types';

type RequestData = {
  /** 변경할 옵션 리스트 */
  optionList: CarOption[];
  /** 선택한 옵션 */
  selectedOption: CarOption;
  /**
   * @true 선택 시
   * @false 선택 해제 시
   */
  isAdded: boolean;
  optionData: CarOption[];
  option: {
    /** 첫 실행시에 true, 재귀적 실행 시 false */
    isInit: boolean;
  };
};

type ValidationData = {
  error: boolean;
  errorMessage: string[];
};

type Suggestion = {
  optionList: CarOption[];
  message: string[];
};

/** NumberArray 중복 제거 */
function removeDuplicates(arr: number[]): number[] {
  return Array.from(new Set(arr));
}

function getOptionFromOptiondata(params: {
  optionId: number;
  data: CarOption[];
}): CarOption | undefined {
  return params.data.find((el) => {
    return el.id === params.optionId;
  });
}

/** 최상위 노드별 내부 모든 Include 노드 id 리스트 */
function parseWholeIncludeOptionId(params: {
  /** 검색할 옵션 아이디 리스트 */
  includedOptionIdList: number[];

  /** 옵션 원시데이터 */
  optionData: CarOption[];
  /** 함수 시작 시점 여부 */
  init: boolean;
  /** 내부 탐색 횟수입니다. */
  depth: number;
}): { [key: number]: number[] } {
  const data: { [key: number]: number[] } = {};

  let totalIncludedOrderIdList: number[] = params.includedOptionIdList;
  for (let i = 0; i < params.includedOptionIdList.length; i++) {
    const currentOptionId = params.includedOptionIdList[i];
    const targetOption = getOptionFromOptiondata({
      optionId: currentOptionId,
      data: params.optionData,
    });
    if (!targetOption || !targetOption.relation) continue;

    totalIncludedOrderIdList = totalIncludedOrderIdList.concat(
      getTotalIncludedOptionId({
        option: targetOption,
        data: params.optionData,
        depth: 0,
      }),
    );
  }
  totalIncludedOrderIdList = removeDuplicates(totalIncludedOrderIdList);

  for (let i = 0; i < params.includedOptionIdList.length; i++) {
    const currentOptionId = params.includedOptionIdList[i];
    const targetOption = getOptionFromOptiondata({
      optionId: currentOptionId,
      data: params.optionData,
    });

    if (
      !targetOption?.relation?.include ||
      targetOption?.relation?.include.length === 0
    )
      continue;

    if (
      isHighestNode({
        option: targetOption,
        totalIncludedOrderIdList: totalIncludedOrderIdList,
        data: params.optionData,
      })
    ) {
      /** 얘는 최상위 노드입니다. */
      data[targetOption.id] = getTotalIncludedOptionId({
        option: targetOption,
        data: params.optionData,
        depth: 0,
      });
    }
  }

  return data;
}

/** 옵션 내 모든 포함 옵션 리스트를 가져옵니다. */
function getTotalIncludedOptionId(params: {
  option: CarOption;
  data: CarOption[];
  depth: number;
}): number[] {
  let returnData: number[] = [];

  if (
    !params.option.relation?.include ||
    params.option.relation?.include.length === 0
  ) {
    return returnData;
  }

  returnData = params.option.relation.include;

  if (params.depth > 6) return returnData;

  for (let i = 0; i < params.option.relation.include.length; i++) {
    const outerOption = getOptionFromOptiondata({
      optionId: params.option.relation.include[i],
      data: params.data,
    });
    if (!outerOption) return [];

    const innerIncludeList = getTotalIncludedOptionId({
      option: outerOption,
      data: params.data,
      depth: params.depth + 1,
    });

    returnData = returnData.concat(innerIncludeList);
  }

  returnData = removeDuplicates(returnData);

  return returnData;
}

/** 옵션 내 모든 상위 옵션 리스트를 가져옵니다. */
function getTotalParentOptionId(params: {
  option: CarOption;
  data: CarOption[];
  depth: number;
}): number[] {
  let returnData: number[] = [];

  if (
    !params.option.relation?.parent ||
    params.option.relation?.parent.length === 0
  ) {
    return returnData;
  }

  returnData = params.option.relation.parent;

  if (params.depth > 6) return returnData;

  for (let i = 0; i < params.option.relation.parent.length; i++) {
    const outerOption = getOptionFromOptiondata({
      optionId: params.option.relation.parent[i],
      data: params.data,
    });
    if (!outerOption) return [];

    const innerIncludeList = getTotalParentOptionId({
      option: outerOption,
      data: params.data,
      depth: params.depth + 1,
    });

    returnData = returnData.concat(innerIncludeList);
  }

  returnData = removeDuplicates(returnData);

  return returnData;
}

/** 해당 옵션이 선택된 옵션중 최상위 옵션인지(parent가 선택되지 않았는지) 확인합니다. */
function isHighestNode(params: {
  option: CarOption;
  totalIncludedOrderIdList: number[];
  data: CarOption[];
}): boolean {
  if (
    !params.option.relation?.parent ||
    params.option.relation?.parent.length === 0
  )
    return true;

  for (let i = 0; i < params.option.relation.parent.length; i++) {
    if (
      params.totalIncludedOrderIdList.includes(params.option.relation.parent[i])
    )
      return false;
  }

  return true;
}

/** 하위 항목으로 상위 항목이 조합 가능한지 여부를 확인합니다. */
function isCombinable(params: {
  option: CarOption;
  totalIncludedOrderIdList: number[];
  data: CarOption[];

  depth: number;
}): boolean {
  if (
    !params.option.relation?.include ||
    params.option.relation.include.length === 0
  ) {
    if (params.totalIncludedOrderIdList.includes(params.option.id)) return true;
    else return false;
  }

  for (let i = 0; i < params.option.relation.include.length; i++) {
    const targetIncludeOptionId = params.option.relation.include[i];
    const targetOption = getOptionFromOptiondata({
      optionId: targetIncludeOptionId,
      data: params.data,
    });

    if (!targetOption) return false;
    if (params.depth > 6) {
      return false;
    }

    if (params.totalIncludedOrderIdList.includes(targetIncludeOptionId))
      continue;

    const isTargetOptionCombinable = isCombinable({
      option: targetOption,
      data: params.data,
      depth: params.depth + 1,
      totalIncludedOrderIdList: params.totalIncludedOrderIdList,
    });
    if (isTargetOptionCombinable === false) return false;
  }

  return true;
}

type CheckValidityReturn = {
  error: boolean;
  message: string[];
};
const checkValidity: {
  [key: string]: (params: RequestData) => CheckValidityReturn;
} = {
  include: (params: RequestData): CheckValidityReturn => {
    const returnData: CheckValidityReturn = {
      error: false,
      message: [],
    };

    const totalIncludedOrderIdList: { [key: number]: number[] } =
      parseWholeIncludeOptionId({
        includedOptionIdList: params.optionList.map((el) => {
          return el.id;
        }),
        optionData: params.optionData,
        init: true,
        depth: 0,
      });

    for (let i = 0; i < Object.keys(totalIncludedOrderIdList).length; i++) {
      const targetOptionId = Number(Object.keys(totalIncludedOrderIdList)[i]);
      const targetOption = getOptionFromOptiondata({
        optionId: targetOptionId,
        data: params.optionData,
      });
      if (!targetOption) continue;

      for (
        let j = 0;
        j <
        params.optionList.map((el) => {
          return el.id;
        }).length;
        j++
      ) {
        if (
          totalIncludedOrderIdList[targetOptionId].includes(
            params.optionList[j].id,
          )
        ) {
          returnData.error = true;

          if (params.optionList[j].id === params.selectedOption.id) {
            returnData.message = [
              ...returnData.message,
              `[${params.selectedOption.name}]옵션은 [${targetOption?.name}]옵션에 포함되어 있습니다.`,
            ];
          }
          // else if (targetOption.id === params.selectedOption.id) {
          //   returnData.message = [
          //     ...returnData.message,
          //     `[${targetOption?.name}]옵션은 [${params.selectedOption.name}]옵션을 포함하고 있습니다.`,
          //   ];
          // }
        }
      }
    }

    return returnData;
  },
  incompatible: (params: RequestData): CheckValidityReturn => {
    const returnData: CheckValidityReturn = {
      error: false,
      message: [],
    };

    let totalIncompatibleOrderList: number[] = [];
    params.optionList.forEach((el) => {
      if (el.relation?.incompatible && el.relation.incompatible.length > 0) {
        totalIncompatibleOrderList = totalIncompatibleOrderList.concat(
          el.relation.incompatible,
        );
      }
    });

    totalIncompatibleOrderList = removeDuplicates(totalIncompatibleOrderList);

    for (let i = 0; i < params.optionList.length; i++) {
      if (totalIncompatibleOrderList.includes(params.optionList[i].id)) {
        returnData.error = true;

        const crashedOption = getOptionFromOptiondata({
          optionId: params.optionList[i].id,
          data: params.optionData,
        });
        if (!crashedOption) continue;

        /** 에러 메시지 생성 */
        if (
          params.isAdded === false ||
          !params.selectedOption.relation?.incompatible.includes(
            crashedOption.id,
          )
        )
          continue;

        returnData.message = [
          ...returnData.message,
          `[${crashedOption.name}]옵션과 동시 선택될 수 없습니다.`,
        ];
      }
    }

    return returnData;
  },
  nessary: (params: RequestData): CheckValidityReturn => {
    const returnData: CheckValidityReturn = {
      error: false,
      message: [],
    };
    let totalNessaryOrderList: number[] = [];
    params.optionList.forEach((el) => {
      if (el.relation?.nessary && el.relation.nessary.length > 0) {
        totalNessaryOrderList = totalNessaryOrderList.concat(
          el.relation.nessary,
        );
      }
    });

    totalNessaryOrderList = removeDuplicates(totalNessaryOrderList);

    for (let i = 0; i < totalNessaryOrderList.length; i++) {
      if (
        !params.optionList
          .map((el) => {
            return el.id;
          })
          .includes(totalNessaryOrderList[i])
      ) {
        returnData.error = true;

        const crashedOption = getOptionFromOptiondata({
          optionId: totalNessaryOrderList[i],
          data: params.optionData,
        });
        if (!crashedOption) continue;

        returnData.message = [
          ...returnData.message,
          `[${crashedOption.name}]옵션이 선택되어야 선택할 수 있습니다.`,
        ];
      }
    }

    return returnData;
  },
};

export function optionValidation(params: RequestData): {
  validation: ValidationData;
  suggestion: Suggestion | null;
} {
  const validation = checkOptionValidity(params);
  const suggestion = createSuggestion(params, validation);

  return {
    validation: validation,
    suggestion: suggestion,
  };
}

/** 유효성 검사 */
export function checkOptionValidity(params: RequestData): ValidationData {
  const returnData: ValidationData = { error: false, errorMessage: [] };

  const validityResults = {
    include: checkValidity.include(params),
    incompatible: checkValidity.incompatible(params),
    nessary: checkValidity.nessary(params),
  };

  if (
    validityResults.include.error ||
    validityResults.incompatible.error ||
    validityResults.nessary.error
  ) {
    returnData.error = true;
    returnData.errorMessage = returnData.errorMessage
      .concat(validityResults.include.message)
      .concat(validityResults.incompatible.message)
      .concat(validityResults.nessary.message);
  }

  return returnData;
}
/** 대체 선택안 생성 */
export function createSuggestion(
  params: RequestData,
  validation: ValidationData,
): Suggestion | null {
  let returnData: Suggestion | null = null;

  /** 에러 존재 시 추가된 항목에 대하여 확인
   * @notice 추천 변경 사항을 제시합니다.
   */
  if (validation.error === true) {
    const suggestion: {
      optionList: CarOption[];
      message: string[];
    } = {
      optionList: [...params.optionList],
      message: [],
    };

    /** 옵션이 추가 선택 시도된 경우 */
    if (params.isAdded && params.selectedOption.relation !== null) {
      /** 추가되는 옵션의 모든 nessary를 추가합니다. */
      if (params.selectedOption.relation.nessary.length > 0) {
        params.selectedOption.relation.nessary.forEach((el) => {
          const addOption = getOptionFromOptiondata({
            optionId: el,
            data: params.optionData,
          });

          if (
            addOption &&
            !suggestion.optionList.find((el2) => {
              return el2.id === el;
            })
          ) {
            suggestion.optionList.push(addOption);
            suggestion.message.push(
              `[${params.selectedOption.name}]옵션은 [${addOption.name}]옵션을 먼저 선택하여야 합니다.`,
            );
          }

          return;
        });
      }
      /** 추가되는 옵션의 모든 incompatible를 선택 해제합니다. */
      if (params.selectedOption.relation.incompatible.length > 0) {
        params.selectedOption.relation.incompatible.forEach((el) => {
          /** incompatible된 항목이 현재 포함되어 있지 않을 경우 제외 */
          if (
            !suggestion.optionList.find((el2) => {
              return el2.id === el;
            })
          )
            return;
          const removeOption = getOptionFromOptiondata({
            optionId: el,
            data: params.optionData,
          });
          if (removeOption) {
            suggestion.optionList = suggestion.optionList.filter((el2) => {
              return el2.id !== el;
            });
            suggestion.message.push(
              `[${params.selectedOption.name}]옵션은 [${removeOption.name}]옵션과 같이 선택할 수 없습니다.`,
            );
          }
          return;
        });
      }
      /** 추가되는 옵션의 모든 include를 선택 해제합니다. */
      if (params.selectedOption.relation.include.length > 0) {
        const totalIncludedOptionIdList = getTotalIncludedOptionId({
          option: params.selectedOption,
          data: params.optionData,
          depth: 0,
        });

        totalIncludedOptionIdList.forEach((el) => {
          /** include된 항목이 현재 포함되어 있지 않을 경우 제외 */
          if (
            !suggestion.optionList.find((el2) => {
              return el2.id === el;
            })
          )
            return;
          const removeOption = getOptionFromOptiondata({
            optionId: el,
            data: params.optionData,
          });

          if (removeOption) {
            suggestion.optionList = suggestion.optionList.filter((el2) => {
              return el2.id !== el;
            });
            suggestion.message.push(
              `[${removeOption.name}]옵션은 [${params.selectedOption.name}]옵션에 포함되어 선택 해제하여야 합니다.`,
            );
          }
          return;
        });
      }
      /** 추가되는 옵션의 모든 parent를 선택 해제합니다. */
      if (params.selectedOption.relation.parent.length > 0) {
        const totalParentOptionIdList = getTotalParentOptionId({
          option: params.selectedOption,
          data: params.optionData,
          depth: 0,
        });

        totalParentOptionIdList.forEach((el) => {
          /** parent 항목이 현재 포함되어 있지 않을 경우 제외 */
          if (
            !suggestion.optionList.find((el2) => {
              return el2.id === el;
            })
          )
            return;

          const removeOption = getOptionFromOptiondata({
            optionId: el,
            data: params.optionData,
          });
          if (removeOption) {
            suggestion.optionList = suggestion.optionList.filter((el2) => {
              return el2.id !== el;
            });
            suggestion.message.push(
              `[${removeOption.name}]옵션이 [${params.selectedOption.name}]옵션을 포함하고 있어 선택 해제하여야 합니다.`,
            );
          }
          return;
        });
      }
    } else {
      /** 옵션이 선택 해제 시도된 경우
       * @notice nessary 조건만 같이 제외해 봅니다.
       */
      /** 제거되는 옵션의 모든 nessary를 체크 해제합니다. */
      if (params.optionList.length > 0) {
        params.optionList.forEach((el) => {
          if (!el.relation?.nessary) return;

          if (el.relation.nessary.includes(params.selectedOption.id)) {
            suggestion.optionList = suggestion.optionList.filter((el2) => {
              return el2.id !== el.id;
            });
            suggestion.message.push(
              `[${params.selectedOption.name}]옵션은 [${el.name}]옵션과 같이 선택 해제되어야 합니다.`,
            );
          }
        });
      }
    }

    const isValidSuggestion = checkOptionValidity({
      ...params,
      optionList: suggestion.optionList,
    });
    if (isValidSuggestion.error === false) returnData = suggestion;
  } else if (
    params.selectedOption.relation !== null &&
    validation.error === false
  ) {
    const suggestion: {
      optionList: CarOption[];
      message: string[];
    } = {
      optionList: [...params.optionList],
      message: [],
    };
    let isModified = false;

    // /** 옵션이 추가 선택 시도된 경우, 상위 노드가 조합될 수 있는지 확인 */
    if (params.isAdded) {
      if (params.selectedOption.relation.parent.length > 0) {
        /** 선택된 옵션의 부모 옵션이 존재하는 경우 */
        for (let i = 0; i < params.selectedOption.relation.parent.length; i++) {
          const totalParentOptionIdList = getTotalParentOptionId({
            option: params.selectedOption,
            data: params.optionData,
            depth: 0,
          });

          for (let i = 0; i < totalParentOptionIdList.length; i++) {
            const targetOption = getOptionFromOptiondata({
              optionId: totalParentOptionIdList[i],
              data: params.optionData,
            });
            if (!targetOption) continue;

            const isCombinableSelect = isCombinable({
              option: targetOption,
              totalIncludedOrderIdList: params.optionList.map((el) => {
                return el.id;
              }),
              data: params.optionData,
              depth: 0,
            });

            if (isCombinableSelect === true) {
              isModified = true;
              const totalIncludedOptionIdList = getTotalIncludedOptionId({
                option: targetOption,
                data: params.optionData,
                depth: 0,
              });

              const deletedOptionList: CarOption[] = [];

              /** 자식 속성을 모두 선택 해제하고, 상위 옵션을 선택한 상태를 제안합니다. */
              totalIncludedOptionIdList.forEach((el) => {
                suggestion.optionList = suggestion.optionList.filter((el2) => {
                  if (el2.id !== el) {
                    return true;
                  } else {
                    deletedOptionList.push(el2);
                  }
                });
              });

              suggestion.optionList.push(targetOption);
              suggestion.message.push(
                `[${targetOption.name}]옵션은 ${deletedOptionList.map((el) => {
                  return `[${el.name}]`;
                })}옵션을 포함하고 있습니다.\n[${targetOption.name}]옵션으로 변경하시겠습니까?`,
              );
            }
          }
        }
      }
    }

    if (isModified === true) {
      const isValidSuggestion = checkOptionValidity({
        ...params,
        optionList: suggestion.optionList,
      });
      if (isValidSuggestion.error === false) returnData = suggestion;
    }
  }

  return returnData;
}
