/* eslint-disable @typescript-eslint/no-unused-vars */
// 리스, 렌트, 신차 구매 페이지
import { useLayoutEffect, useRef, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import { getPurchase, initPurchase } from 'apis/purchase';
import usePurchaseStore from './store';
import { CloseConfirm } from 'components/view/buy/CloseConfirm';
import People3Icon from 'assets/images/v2/People-3.svg';
import { classNames } from 'primereact/utils';
import useMemberStore from 'store/useMemberStore';
import { getMember, isLogin } from 'apis/authentication';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Swiper as SwiperCore } from 'swiper';
import { cn } from 'utils';
import { ChargeInformationFee } from '../../../components/purchase/ChargeInformationFee';
import { PurchaseOrderDTO } from '@carsayo/types';

function CheckMysteryShopper({
  isOpen,
  setIsOpen,
  purchaseType,
  initCarModelId,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  purchaseType: PurchaseType;
  initCarModelId: number | undefined;
}) {
  const navigate = useNavigate();
  const { setPurchaseOrderDTO } = usePurchaseStore();
  const [noMysteryShopper, setNoMysteryShopper] = useState(false);
  const handleChangeEvents = {
    noMystery: () => {
      setNoMysteryShopper(!noMysteryShopper);
    },
  };

  const onConfirm = async () => {
    const newPurchase = await initPurchase({
      carModelId: initCarModelId,
      type: purchaseType === 'newCar' ? 'newCar' : 'leaseRent',
    });
    if (!newPurchase.orderData) {
      CarsayoToast.error('잠시 후 다시 시도해 주세요.');
      navigate(-1);
      console.error(newPurchase);
      throw new Error();
    }
    setPurchaseOrderDTO(
      {
        ...newPurchase,
        update: {
          additionalInfo: {
            ...newPurchase.orderData.additionalInfo,
            method:
              purchaseType === 'lease'
                ? '리스'
                : purchaseType === 'rent'
                  ? '렌트'
                  : '할부',
          },
        },
      },
      { initial: true },
    );
    if (initCarModelId) {
      navigate('/purchase/detail');
    }
    setIsOpen(false);
  };
  const onClose = () => {
    navigate('/main');
  };
  return (
    <Drawer open={isOpen}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col !pt-4'>
        <DrawerHeader className='relative flex justify-between items-center'>
          <DrawerTitle className='text-center text-2xl leading-[34px] font-semibold grow'>
            서비스 이용 규정
          </DrawerTitle>
          <Button
            onClick={onClose}
            className='absolute right-5 top-0 bottom-[3px] my-auto h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
          >
            <X className='h-7 w-7' />
          </Button>
        </DrawerHeader>
        <div className='px-4 pb-8 mt-2'>
          <div className='rounded-md border bg-[#F7F7FB] px-4 py-6 pb-2'>
            <div className='w-full flex flex-col justify-center items-center gap-4 mb-4'>
              <img
                src={People3Icon}
                alt='미스터리쇼퍼 아이콘'
                className='w-[140px] object-contain'
              />
              <p className='text-center text-[13px] text-[#111111] font-[600]'>
                미스터리 쇼퍼(고객으로 가장하여 정보를 캐거나 소속된 카
                매니저들을 색출하는 사람)는 견적 신청이 불가하며, 적발(계약
                체결, 출고, 해지 포함 5년간) 시 건 당 10억 원의 배상과 민형사상
                책임을 져야 합니다.
              </p>
            </div>
            <div className='w-full flex justify-center items-center pb-4'>
              <label
                htmlFor='mystery_shopper_check'
                className={classNames(
                  'relative block pl-[30px]',
                  'after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto',
                  'after:w-[22px] after:h-[22px] after:border after:text-center',
                  `${noMysteryShopper ? 'after:content-["✓"] after:text-[#7273F9] text-[15px] after:border-[#7273F9] after:bg-white' : 'after:content-[""] after:border-[#E5E5EC] after:bg-white'}`,
                )}
              >
                <input
                  type='checkbox'
                  id='mystery_shopper_check'
                  className='hidden'
                  checked={noMysteryShopper}
                  onChange={handleChangeEvents.noMystery}
                />
                <span className='w-full text-[15px] text-[#111111]'>
                  미스터리 쇼퍼가 아닙니다
                </span>
              </label>
            </div>
          </div>
          <button
            onClick={onConfirm}
            disabled={!noMysteryShopper}
            className='w-full py-4 px-7 mt-6 flex justify-center items-center bg-[#111111] text-[#fff] rounded-[6px] disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none'
          >
            다음
          </button>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export type PurchaseType = 'lease' | 'rent' | 'newCar';

function CheckResumeOrder({
  isOpen,
  setIsOpen,
  purchaseType,
  initCarModelId,
  resumeOrderList,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  purchaseType: PurchaseType;
  initCarModelId: number | undefined;
  resumeOrderList: PurchaseOrderDTO[];
}) {
  const navigate = useNavigate();
  const { setPurchaseOrderDTO } = usePurchaseStore();
  const [index, setIndex] = useState(0);

  const initOrder = async () => {
    const newPurchase = await initPurchase({
      carModelId: initCarModelId,
      type: purchaseType === 'newCar' ? 'newCar' : 'leaseRent',
    });
    if (!newPurchase.orderData) {
      CarsayoToast.error('잠시 후 다시 시도해 주세요.');
      navigate(-1);
      console.error(newPurchase);
      throw new Error();
    }
    setPurchaseOrderDTO(
      {
        ...newPurchase,
        update: {
          additionalInfo: {
            ...newPurchase.orderData.additionalInfo,
            method:
              purchaseType === 'lease'
                ? '리스'
                : purchaseType === 'rent'
                  ? '렌트'
                  : '할부',
          },
        },
      },
      { initial: true },
    );
    setIsOpen(false);
    if (initCarModelId) {
      navigate('/purchase/detail');
    } else {
      navigate(`/purchase/brand?purchaseType=${purchaseType}`);
    }
  };

  const progressOrder = () => {
    const targetOrder = resumeOrderList[index];
    if (targetOrder && targetOrder?.orderData?.currentPath) {
      setPurchaseOrderDTO(targetOrder, { initial: true });
      navigate(targetOrder.orderData.currentPath, { replace: true });
    }
    setIsOpen(false);
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const onSwiperInit = (swiper: any) => {
    swiper.params.navigation.prevEl = prevRef.current;
    swiper.params.navigation.nextEl = nextRef.current;
    swiper.navigation.init();
    swiper.navigation.update();
  };

  return (
    <Drawer open={isOpen}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle className='text-left text-lg font-semibold'>
            진행중인 상담을 계속 하시겠습니까?
          </DrawerTitle>
          <Button
            onClick={initOrder}
            className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
          >
            <X className='h-7 w-7' />
          </Button>
        </DrawerHeader>

        <div className='px-4'>
          <Swiper
            spaceBetween={50}
            slidesPerView={'auto'}
            modules={[Navigation]}
            onInit={onSwiperInit}
            onSlideChange={(swiper: SwiperCore) => {
              setIndex(swiper.realIndex);
            }}
          >
            {resumeOrderList.map((el) => {
              return (
                <SwiperSlide key={el.id}>
                  <div className='rounded-md border bg-[#F7F7FB] px-4 py-6'>
                    <div className='font-semibold text-primary'>
                      {el?.orderData?.progress}% 진행중
                    </div>
                    <div className='relative mt-2 h-1 bg-black/20'>
                      <div
                        className='absolute inset-0 right-auto bg-primary'
                        style={{
                          width: `${el?.orderData?.progress}%`,
                        }}
                      ></div>
                    </div>

                    <div className='mt-4'>
                      <img
                        src={el?.orderData?.carModel?.image_url}
                        alt=''
                        className='mx-auto max-h-full'
                      />
                    </div>
                    <div className='mt-4 text-center text-desc font-semibold'>
                      {el?.orderData?.carMaker?.name}{' '}
                      {el?.orderData?.carModel?.name}
                    </div>
                    {el?.orderData?.carGrade && (
                      <>
                        <div className='mt-0.5 text-center text-[#505050]'>
                          {el.orderData.carGrade.name}
                        </div>
                      </>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
            {resumeOrderList.length > 1 && (
              <div className='flex items-center justify-center'>
                <div className='my-4 inline-flex items-center gap-1 py-[5px] px-[12px] rounded-2xl bg-[#00000099]'>
                  <button
                    ref={prevRef}
                    className={cn(
                      'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
                    )}
                  >
                    <ChevronLeft className='h-4 w-4' />
                  </button>
                  <div className='text-[#fff]'>
                    <span className='text-[#fff]'>{index + 1}</span>
                    <span className='px-0.5'>/</span>
                    <span className={classNames()}>
                      {resumeOrderList.length}
                    </span>
                  </div>
                  <button
                    ref={nextRef}
                    className={cn(
                      'flex h-4 w-4 items-center justify-center text-[#CACAD7]',
                    )}
                  >
                    <ChevronRight className='h-4 w-4' />
                  </button>
                </div>
              </div>
            )}
          </Swiper>
        </div>

        <div
          className={cn(
            'px-4 pb-8 grid grid-cols-2 bg-[#F7F7FB]',
            resumeOrderList.length === 1 ? 'pt-4' : '',
          )}
        >
          <Button
            variant='outline'
            className='rounded-r-none bg-white'
            onClick={initOrder}
          >
            {initCarModelId ? '새 견적 확인' : '새로하기'}
          </Button>
          <Button className='rounded-l-none' onClick={progressOrder}>
            계속하기
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default function PurchaseIndex() {
  const navigate = useNavigate();
  const { loginMember } = useMemberStore();
  const [searchParams] = useSearchParams();
  const initCarModelId = searchParams.get('carModelId')
    ? Number(searchParams.get('carModelId'))
    : undefined;

  const purchaseType: PurchaseType =
    searchParams.get('purchaseType') === 'lease'
      ? 'lease'
      : searchParams.get('purchaseType') === 'rent'
        ? 'rent'
        : 'newCar';

  const { setTopTabbarState, setIsBottomTabbar, setLoadingDim } =
    useSystemStore();

  const { purchaseOrderDTO, resetPurchaseStore } = usePurchaseStore();

  const [isCheckResumeOrderOpen, setIsCheckResumeOrderOpen] = useState(false);
  const [resumeOrderList, setResumeOrderList] = useState<PurchaseOrderDTO[]>(
    [],
  );

  const [isCheckMysteryShopperOpen, setIsCheckMysteryShopperOpen] =
    useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [isOpenInformationFeeDrawer, setIsOpenInformationFeeDrawer] =
    useState(false);

  const checkAcceptedManager = async () => {
    try {
      if (!loginMember) {
        const {
          data: { isLogined },
        } = await isLogin();
        if (isLogined === false) {
          navigate('/main', { replace: true });
          return;
        } else {
          const member = await getMember();
          if (member.type !== 'customer') {
            CarsayoToast.error('일반 회원만 주문이 가능합니다.');
            navigate('/main', { replace: true });
            return;
          } else {
            if (
              member?.customerInfo &&
              member.customerInfo.count.avaliableOrder.purchase <= 0
            ) {
              setIsOpenInformationFeeDrawer(true);
            } else {
              checkAlreadyWritingOrder();
            }
          }
        }
      } else {
        const member = await getMember();
        if (loginMember.type !== 'customer') {
          CarsayoToast.error('일반 회원만 주문이 가능합니다.');
          navigate('/main', { replace: true });
          return;
        } else {
          if (
            member?.customerInfo &&
            member.customerInfo.count.avaliableOrder.purchase <= 0
          ) {
            setIsOpenInformationFeeDrawer(true);
          } else {
            checkAlreadyWritingOrder();
          }
        }
      }
    } catch (error: any) {
      CarsayoToast.error(error);
      navigate('/main', { replace: true });
      return;
    }
  };

  const checkAvailableOrderCount = (purchaseCount: number) => {};

  const checkAlreadyWritingOrder = async () => {
    setLoadingDim(true);

    try {
      const previousPurchase = await getPurchase();
      const targetTypePurchaseList = previousPurchase.filter((el) => {
        if (purchaseType === 'lease' || purchaseType === 'rent') {
          return el.type === 'leaseRent';
        } else {
          return el.type === 'newCar';
        }
      });

      if (targetTypePurchaseList.length > 0) {
        setResumeOrderList(targetTypePurchaseList);
        setIsCheckResumeOrderOpen(true);
      } else {
        setIsCheckMysteryShopperOpen(true);
      }
      setLoadingDim(false);
    } catch (e) {
      CarsayoToast.error(`${e}`);
      setLoadingDim(false);
    }
  };

  useLayoutEffect(() => {
    checkAcceptedManager();
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <ChevronLeft
          onClick={() => {
            if (window.native.onBackPressed) window.native.onBackPressed();
          }}
          className='h-7 w-7'
        ></ChevronLeft>
      ),
      rightElement: (
        <X
          onClick={() => {
            if (
              window.location.pathname.includes('complete') ||
              window.location.pathname.includes('brand')
            ) {
              navigate('/main', { replace: true });
              return;
            }
            setIsOpen(true);
            return;
          }}
          className='h-7 w-7'
        />
      ),
    });
    setIsBottomTabbar(false);

    return () => {
      resetPurchaseStore();
      window.native.onBackPressed = () => {};
    };
  }, []);

  return (
    <>
      <div
        className='fixed inset-0 bottom-auto z-50 h-1 bg-black/20'
        style={{
          top: 'calc(env(safe-area-inset-top, 0px)+54px)',
        }}
      >
        <div
          className='absolute inset-0 right-auto bg-[#7273F9] transition-all'
          style={{
            width: `${purchaseOrderDTO?.orderData?.progress}%`,
          }}
        ></div>
      </div>
      <Outlet />
      <CheckMysteryShopper
        isOpen={isCheckMysteryShopperOpen}
        setIsOpen={setIsCheckMysteryShopperOpen}
        purchaseType={purchaseType}
        initCarModelId={initCarModelId}
      ></CheckMysteryShopper>
      <CheckResumeOrder
        isOpen={isCheckResumeOrderOpen}
        setIsOpen={setIsCheckResumeOrderOpen}
        initCarModelId={initCarModelId}
        purchaseType={purchaseType}
        resumeOrderList={resumeOrderList}
      ></CheckResumeOrder>
      <ChargeInformationFee
        isOpen={isOpenInformationFeeDrawer}
        setIsOpen={setIsOpenInformationFeeDrawer}
        purchaseType={'purchasing'}
      />
      <CloseConfirm isOpen={isOpen} setIsOpen={setIsOpen}></CloseConfirm>
    </>
  );
}
