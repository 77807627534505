import {
  OrderState,
  OrderListOptionDTO,
  DealerOrderSearch,
  DealerOrderListData,
} from '@carsayo/types';
import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { getManagerQuoteListApi } from 'apis/quote';

const defaultTake = 10;

export const useGetDealerOrderList = ({
  type,
  take,
  page,
}: {
  type: OrderState | 'whole' | 'bidding' | 'marking';
  take?: number;
  page: number;
}) => {
  return useQuery({
    queryKey: ['mainDealerOrderList', type, take, page],
    queryFn: async () => {
      let option: OrderListOptionDTO = {};
      const takeItemCount = take ?? defaultTake;
      switch (type) {
        case 'submitted':
          option = {
            orderStateList: ['submitted'],
            isBidding: false,
          };
          break;
        case 'marking':
          option = {
            orderStateList: ['submitted'],
            isBookmarked: true,
          };
          break;
        case 'bidding':
          option = {
            orderStateList: ['submitted'],
            isBidding: true,
          };
          break;
        case 'progressing':
          option = {
            orderStateList: ['progressing'],
            isAccepted: true,
          };
          break;
        case 'holded':
          option = {
            orderStateList: ['holded'],
            isAccepted: true,
          };
          break;
        case 'canceled':
          option = {
            orderStateList: ['canceled'],
            isAccepted: true,
          };
          break;
        case 'finished':
          option = {
            orderStateList: ['finished'],
            isAccepted: true,
          };
          break;
        case 'whole':
          option = {
            isAccepted: true,
          };
          break;
        case 'failBid':
          option = {
            orderStateList: ['failBid'],
          };
          break;
        default:
          break;
      }
      const searchedOrderList: DealerOrderSearch = await getManagerQuoteListApi(
        {
          skip: page * takeItemCount,
          take: takeItemCount,
          option: option,
        },
      );
      return searchedOrderList;
    },
    placeholderData: (previousData, previousQuery) => previousData,
    gcTime: 60 * 1000, // 1min
    staleTime: 20 * 1000, // 20sec
  });
};
export const useUpdateDealerOrderList = () => {
  // 나의 주문 리스트
  const queryClient = useQueryClient();
  const updateDealerOrderList = async ({
    type,
    take,
    page,
    data,
  }: {
    type: OrderState | 'whole' | 'bidding' | 'marking';
    take?: number;
    page: number;
    data: DealerOrderSearch;
  }) => {
    queryClient.setQueryData(['mainDealerOrderList', type, take, page], () => {
      return data;
    });
    queryClient.invalidateQueries({
      queryKey: ['mainDealerOrderList'],
    });
    console.info('나의 주문 리스트 데이터, ', type, take, page);
    // queryClient.resetQueries({
    //   queryKey: ['mainDealerBiddingOrderList'],
    // });
    queryClient.refetchQueries({
      queryKey: ['mainDealerBiddingOrderList'],
      exact: true,
    });
  };

  return updateDealerOrderList;
};

export const useGetDealerBiddingOrderList = () => {
  return useInfiniteQuery({
    queryKey: ['mainDealerBiddingOrderList'],
    queryFn: async (params) => {
      const option: OrderListOptionDTO = {
        orderStateList: ['submitted'],
        isBidding: false,
      };
      const searchedOrderListData: DealerOrderSearch =
        await getManagerQuoteListApi({
          skip:
            params.pageParam && params.meta
              ? params.pageParam * defaultTake
              : 0,
          take: defaultTake,
          option: option,
          interval: {
            target: 'bid_ended_at',
            start: new Date(),
            end: null,
          },
        });
      return searchedOrderListData;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length;
      const currentItemCount = allPages
        .map((el) => {
          return el.data.length;
        })
        .reduce((el1, el2) => {
          return el1 + el2;
        });

      if (lastPage.totalCount <= currentItemCount) return null;
      return nextPage;
    },
    placeholderData: (previousData, previousQuery) => previousData,
    gcTime: 60 * 1000, // 1min
    staleTime: 20 * 1000, // 20sec
  });
};
/** useGetDealerBiddingOrderList 내 특정 page내 특정 orderId를 업데이트합니다. */
export const useUpdateDealerBiddingOrderList = () => {
  // 구매 신청서
  const queryClient = useQueryClient();

  const updateDealerBiddingOrderList = ({
    page,
    orderId,
    data,
  }: {
    orderId: string;
    page: number;
    data: DealerOrderListData;
  }) => {
    queryClient.setQueryData(
      ['mainDealerBiddingOrderList'],
      (prevData: InfiniteData<DealerOrderSearch>) => {
        const updatedPageData: DealerOrderSearch[] = prevData.pages;
        const targetItemIndex = updatedPageData[page].data.findIndex((el) => {
          return el.id === orderId;
        });
        updatedPageData[page].data[targetItemIndex] = data;
        return {
          ...prevData,
          pageParams: updatedPageData,
        };
      },
    );
    queryClient.invalidateQueries({
      queryKey: ['mainDealerOrderList'],
    });
  };

  return updateDealerBiddingOrderList;
};
