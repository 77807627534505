/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from 'components/ui/button';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import usePurchaseStore from './store';
import PurchasingCarInfo from './components/carInfo';
import { cn } from 'utils';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

export default function Color() {
  const navigate = useNavigate();

  const { setTopTabbarTitle } = useSystemStore();
  const { purchaseOrderDTO, setPurchaseOrderDTO, catalogue } =
    usePurchaseStore();

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 40,
        currentPath: '/purchase/color',
        carOptionIdList: [],
        additionalRequest: null,
        otherCompanyEstimateFileId: null,
      },
    });
  };

  const goBack = () => {
    navigate('/purchase/detail', { replace: true });
  };
  const goNext = () => {
    navigate('/purchase/option', { replace: true });
  };

  /** 색상 정보가 없을 경우 색상을 나중에 결정할게요 옵션을 기본 선택합니다. */
  const checkNoColorOption = () => {
    if (
      purchaseOrderDTO &&
      catalogue?.color?.data &&
      catalogue?.color?.data.length === 0
    ) {
      setPurchaseOrderDTO({
        id: purchaseOrderDTO.id,
        type: purchaseOrderDTO.type,
        update: {
          isNotSelectColor: true,
        },
      });
    }
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('색상 선택');
    checkNoColorOption();
    setData();
  }, []);

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-auto overflow-y-scroll'>
        <PurchasingCarInfo></PurchasingCarInfo>

        {catalogue?.color?.data && catalogue?.color?.data.length > 0 && (
          <div className='px-4 pt-8'>
            <div className='text-lg font-semibold'>외장 색상</div>
            <div className='mt-4 rounded-md border'>
              <div className='flex h-[72px] items-center justify-between border-b bg-[#F7F7FB] px-4'>
                <div className='text-title'>
                  {purchaseOrderDTO?.orderData?.carColor
                    ? purchaseOrderDTO.orderData.carColor.name
                    : purchaseOrderDTO?.orderData?.isNotSelectColor
                      ? '색상은 추후 결정합니다.'
                      : '색상이 선택되지 않았습니다.'}
                </div>
                {purchaseOrderDTO?.orderData?.carColor &&
                  purchaseOrderDTO.orderData.carColor.price > 0 && (
                    <div className='text-[#555555]'>
                      +{' '}
                      {purchaseOrderDTO.orderData.carColor.price.toLocaleString()}
                      원
                    </div>
                  )}
              </div>
              <div
                className={cn(
                  'grid gap-x-[12.75px] gap-y-4 p-4 pb-8 grid-cols-5',
                  purchaseOrderDTO?.orderData?.isNotSelectColor
                    ? 'bg-gray-50'
                    : '',
                )}
              >
                {catalogue?.color &&
                  catalogue.color.data
                    .filter((el1, index) => {
                      return (
                        catalogue!.color!.data.findIndex((el2) => {
                          return el1.name === el2.name;
                        }) === index
                      );
                    })
                    .map((el) => (
                      <div className='flex justify-center '>
                        <label
                          key={el.id}
                          className='relative flex h-[52px] w-[52px] items-center justify-center rounded-md border has-[:checked]:border-primary'
                        >
                          <input
                            type='radio'
                            name='color'
                            disabled={
                              purchaseOrderDTO?.orderData?.isNotSelectColor
                            }
                            checked={
                              purchaseOrderDTO?.orderData?.carColor?.id ===
                              el.id
                            }
                            onClick={() => {
                              if (!purchaseOrderDTO) return;
                              setPurchaseOrderDTO({
                                id: purchaseOrderDTO.id,
                                type: purchaseOrderDTO.type,
                                update: {
                                  carColorId: el.id,
                                  isNotSelectColor: null,
                                },
                              });
                            }}
                            className='w-0 appearance-none'
                          />
                          <img
                            className='h-8 w-8 rounded-full'
                            src={el.image_url}
                          ></img>
                        </label>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        )}
        <div className='px-4 py-8'>
          <div className='rounded-md border p-4'>
            <RadioGroup className='flex items-center gap-3'>
              <RadioGroupItem
                checked={purchaseOrderDTO?.orderData?.isNotSelectColor}
                onClick={() => {
                  if (!purchaseOrderDTO) return;
                  setPurchaseOrderDTO({
                    id: purchaseOrderDTO.id,
                    type: purchaseOrderDTO.type,
                    update: {
                      carColorId: null,
                      isNotSelectColor: purchaseOrderDTO?.orderData
                        ?.isNotSelectColor
                        ? false
                        : true,
                    },
                  });
                }}
                value='test'
                id='r1'
              />
              <label htmlFor='r1' className='font-semibold'>
                색상은 나중에 결정 할께요!
              </label>
            </RadioGroup>
            <ul className='mt-3 list-outside list-disc space-y-1 pl-3 text-desc marker:text-[#767676]'>
              <li>색상은 카 매니저님과의 상담 시 변경이 가능해요.</li>
              <li>
                선택 색상에 대한 차량 이미지는 이해를 위한 참고용으로 제공되며,
                실제 차량과 외관 및 색상이 상이할 수 있습니다.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goBack}
        >
          이전
        </Button>
        <Button
          className='rounded-l-none'
          onClick={async () => {
            if (
              purchaseOrderDTO?.orderData?.carColor ||
              purchaseOrderDTO?.orderData?.isNotSelectColor
            ) {
              goNext();
            } else {
              CarsayoToast.error('색상이 선택되지 않았습니다');
            }
          }}
        >
          다음
        </Button>
      </div>
    </div>
  );
}
