import { toast } from 'sonner';
class AlertToastConfirm {
  private static instance: AlertToastConfirm;
  public static getInstance() {
    if (!AlertToastConfirm.instance) {
      AlertToastConfirm.instance = new AlertToastConfirm();
    }
    return AlertToastConfirm.instance;
  }

  // 고유한 ID 생성 메서드
  private generateUniqueId() {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }

  public confirm(
    message: string,
    onConfirm: () => void,
    onCancel?: () => void,
  ) {
    this.showOverlay();

    const confirmId = this.generateUniqueId();
    toast(
      <div className='flex flex-col justify-start items-center gap-3 z-600'>
        <div className='text-[#333] whitespace-pre-line'>{message}</div>
        <div className='w-full flex gap-2'>
          <button
            onClick={() => {
              onCancel?.();
              toast.dismiss(confirmId);
              this.removeOverlay();
            }}
            className='w-full bg-gray-200 text-[#333] px-2 py-2 rounded'
          >
            취소
          </button>
          <button
            onClick={() => {
              onConfirm();
              toast.dismiss(confirmId);
              this.removeOverlay();
            }}
            className='w-full bg-[#7273F9] text-[#fff] px-2 py-2 rounded'
          >
            확인
          </button>
        </div>
      </div>,
      {
        id: confirmId,
        onDismiss: () => {
          this.removeOverlay();
        },
        position: 'bottom-center',
        duration: Infinity,
        className:
          'px-6 py-4 flex bg-[#fff] border-1 border-[#767676] justify-start items-center gap-[10px] text-[13px] leading-[18px] shadow-md',
      },
    );
  }

  public alert(message: string, onCancel?: () => void) {
    this.showOverlay();

    const alertId = this.generateUniqueId();
    toast(
      <div className='w-full flex flex-col justify-center items-center gap-3 z-600'>
        <div className='text-[#333] whitespace-pre-line'>{message}</div>
        <div className='w-full flex gap-2'>
          <button
            onClick={() => {
              onCancel?.();
              toast.dismiss(alertId);
              this.removeOverlay();
            }}
            className='w-full bg-gray-200 text-[#333] px-2 py-2 rounded'
          >
            확인
          </button>
        </div>
      </div>,
      {
        id: alertId,
        onDismiss: () => {
          this.removeOverlay();
        },
        position: 'bottom-center',
        duration: Infinity,
        className:
          'px-6 py-4 flex bg-[#fff] border-1 border-[#767676] justify-start items-center gap-[10px] text-[13px] leading-[18px] shadow-md',
      },
    );
  }
  // 오버레이 표시 메서드
  private showOverlay() {
    const overlay = document.createElement('div');
    overlay.id = 'toast-overlay';
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100vw';
    overlay.style.height = '100vh';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    overlay.style.zIndex = '500';
    overlay.style.pointerEvents = 'auto';

    document.body.appendChild(overlay);
  }

  // 오버레이 제거 메서드
  private removeOverlay() {
    const overlay = document.getElementById('toast-overlay');
    if (overlay) {
      document.body.removeChild(overlay);
    }
  }
}

export const CarsayoToastConfirm = AlertToastConfirm.getInstance();

export default AlertToastConfirm;
