/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChevronLeft, ChevronRight, Star } from 'lucide-react';
import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import useSystemStore from 'store/useSystemStore';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { cn } from 'utils/index';
import { Button } from 'components/ui/button';
import Cancel from 'components/view/quotation/cancel';
import { X } from 'lucide-react';
import {
  customerCancelQuoteApi,
  customerDeleteQuoteApi,
  customerHoldQuoteApi,
  customerReStartQuoteApi,
  getCustomerQuoteListApi,
  selectManagerApi,
} from 'apis/quote';
import {
  convertDealerType,
  convertOrderStatus,
  dateToStringHHMM,
  dateToStringMMDD,
} from 'utils/convert';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog';
import Confirm from 'components/ui/confirm';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { classNames } from 'primereact/utils';
import CarsayoImg from 'assets/images/v2/Carsayo.svg';
import { parseOrderProcessState } from 'utils/typeConvert';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import { reviewOrder } from 'apis/order';
import useFileUploader from 'store/useFileUploader';
import { encodeFileToBase64 } from 'utils/convertImage';
import download from 'utils/download';
import useOnResumeStore from 'store/useOnResumeStore';
import ReactHtmlParser from 'react-html-parser';
import {
  CustomerOrderListData,
  FileInfo,
  OrderCancelDTO,
  OrderHoldDTO,
} from '@carsayo/types';
import dayjs from 'dayjs';

export function ReviewThanks() {
  return (
    <div className='mt-3 bg-white p-4'>
      <div className='mx-auto mt-6 h-[160px] w-[160px]'>
        <img src='/assets/images/v2/complete.svg' alt='' />
      </div>
      <div className='mt-4 border-b border-dashed py-4 pb-8 text-center'>
        <div className='text-xl font-semibold'>소중한 후기 감사합니다!</div>
        <div className='mt-3 text-center text-title text-[#767676]'>
          작성하신 후기는 같은 고민이신
          <br />
          분들께 큰 도움이 됩니다.
        </div>
      </div>
    </div>
  );
}

export function Review({
  quoteList,
  page,
  setQuoteList,
}: {
  quoteList: CustomerOrderListData[];
  page: number;
  setQuoteList: React.Dispatch<
    React.SetStateAction<CustomerOrderListData[] | undefined>
  >;
}) {
  const { fileUpload } = useFileUploader();
  const titleRef = useRef<HTMLInputElement>(null);
  const [content, setContent] = useState<string>('');
  const [rating, setRating] = useState<number>();

  const keyWord = useMemo(() => {
    return quoteList[page - 1].type === 'sellCar' ? '판매' : '출고';
  }, [page]);

  const [uploadFileInfoList, setUploadFileInfoList] = useState<{
    file_1: FileInfo | null;
    file_2: FileInfo | null;
    file_3: FileInfo | null;
    file_4: FileInfo | null;
    imageSrc_1: string | null;
    imageSrc_2: string | null;
    imageSrc_3: string | null;
    imageSrc_4: string | null;
  }>({
    file_1: null,
    file_2: null,
    file_3: null,
    file_4: null,
    imageSrc_1: null,
    imageSrc_2: null,
    imageSrc_3: null,
    imageSrc_4: null,
  });

  const updateQuoteList = (newData: CustomerOrderListData) => {
    if (quoteList && page) {
      const newArray = [...quoteList];
      newArray[page - 1] = newData;
      setQuoteList(newArray);
    }
  };

  const submit = async () => {
    const title = titleRef.current?.value;

    if (!title) {
      CarsayoToast.error('리뷰 제목을 입력해 주세요');
      return;
    }
    if (!content) {
      CarsayoToast.error('리뷰 내용을 입력해 주세요');
      return;
    }
    if (!quoteList[page - 1]) {
      CarsayoToast.error('에러가 발생했습니다');
      return;
    }
    if (!rating) {
      CarsayoToast.error('별점을 등록해 주세요');
      return;
    }

    const updatedReview = await reviewOrder({
      orderId: quoteList[page - 1].id,
      title: title,
      content: content,
      rating: rating,
      file_1_id: uploadFileInfoList.file_1
        ? uploadFileInfoList.file_1.id
        : undefined,
      file_2_id: uploadFileInfoList.file_2
        ? uploadFileInfoList.file_2.id
        : undefined,
      file_3_id: uploadFileInfoList.file_3
        ? uploadFileInfoList.file_3.id
        : undefined,
      file_4_id: uploadFileInfoList.file_4
        ? uploadFileInfoList.file_4.id
        : undefined,
    });
    updateQuoteList({
      ...quoteList[page - 1],
      review: updatedReview,
    });
  };

  return (
    <div className='mt-3 bg-white p-4'>
      <div className='mx-auto mt-6 h-[160px] w-[160px]'>
        <img src='/assets/images/v2/complate-car.svg' alt='' />
      </div>
      <div className='mt-4 border-b border-dashed py-4 pb-8 text-center'>
        <div className='text-xl font-semibold'>
          상담부터 {keyWord}
          까지 여정은 어떠셨나요?
        </div>
        <div className='mt-3 flex items-center justify-center'>
          {[...Array(5)].map((_, index) => (
            <div
              key={index}
              onClick={async () => {
                setRating(index + 1);
              }}
            >
              {rating ? (
                <Star
                  fill={index < rating ? '#111111' : '#E5E5EC'}
                  width={60}
                  height={60}
                />
              ) : (
                <Star
                  fill={index < 0 ? '#111111' : '#E5E5EC'}
                  width={60}
                  height={60}
                />
              )}
            </div>
          ))}
        </div>
        <div className='mt-3 text-center text-title text-[#767676]'>
          카 매니저님의 평점을 알려주세요
        </div>
      </div>
      <div className='mt-4 bg-white p-4'>
        <div className='text-center text-xl font-semibold'>
          {keyWord} 후기를 남겨주세요
        </div>
        <div className='mt-1 break-keep text-center text-title text-[#767676]'>
          정성스러운 {keyWord} 후기를 남겨주신 모든 분들께 커피 기프티콘을
          드려요
        </div>
        <Input
          ref={titleRef}
          placeholder={`${keyWord} 후기 타이틀 입력`}
          className='mt-3 text-sm'
        ></Input>
        <div className='relative mt-2'>
          <Textarea
            value={content}
            onChange={(el) => {
              setContent(el.target.value);
            }}
            maxLength={1000}
            className='h-[158px] p-4'
            placeholder={`${keyWord} 후기 내용을 남겨주세요`}
          />
          <div className='absolute bottom-4 right-4 text-[#767676]'>
            {content.length}/1,000
          </div>
        </div>
        {uploadFileInfoList.file_1 && uploadFileInfoList.imageSrc_1 ? (
          <div className='relative flex mt-3 h-[200px] rounded border items-center justify-center'>
            <X
              onClick={() => {
                setUploadFileInfoList({
                  ...uploadFileInfoList,
                  file_1: null,
                  imageSrc_1: null,
                });
              }}
              className='absolute top-2 right-2'
            ></X>
            <img
              className='object-fill h-full'
              src={uploadFileInfoList.imageSrc_1}
            ></img>
          </div>
        ) : (
          <div
            onClick={() => {
              fileUpload(
                {
                  acceptFile: 'image',
                  fileType: 'ReviewPicture',
                  fileName: 'ReviewPicture',
                  isPublic: true,
                },
                async (fileInfo, file) => {
                  let base64String = null;
                  if (file) base64String = await encodeFileToBase64(file);
                  setUploadFileInfoList({
                    ...uploadFileInfoList,
                    file_1: fileInfo,
                    imageSrc_1: base64String,
                  });
                },
              );
            }}
            className='relative mt-3 flex h-[100px] items-center justify-center gap-2 border'
          >
            <input type='file' className='absolute w-0 hidden' />
            <img src='/assets/images/v2/camera.svg' alt='' className='w-8' />
            <div className='text-lg'>사진 첨부하기</div>
          </div>
        )}
        {uploadFileInfoList.file_2 && uploadFileInfoList.imageSrc_2 ? (
          <div className='relative flex mt-3 h-[200px] rounded border items-center justify-center'>
            <X
              onClick={() => {
                setUploadFileInfoList({
                  ...uploadFileInfoList,
                  file_2: null,
                  imageSrc_2: null,
                });
              }}
              className='absolute top-2 right-2'
            ></X>
            <img
              className='object-fill h-full'
              src={uploadFileInfoList.imageSrc_2}
            ></img>
          </div>
        ) : (
          <div
            onClick={() => {
              fileUpload(
                {
                  acceptFile: 'image',
                  fileType: 'ReviewPicture',
                  fileName: 'ReviewPicture',
                  isPublic: true,
                },
                async (fileInfo, file) => {
                  let base64String = null;
                  if (file) base64String = await encodeFileToBase64(file);
                  setUploadFileInfoList({
                    ...uploadFileInfoList,
                    file_2: fileInfo,
                    imageSrc_2: base64String,
                  });
                },
              );
            }}
            className='relative mt-3 flex h-[100px] items-center justify-center gap-2 border'
          >
            <input type='file' className='absolute w-0 hidden' />
            <img src='/assets/images/v2/camera.svg' alt='' className='w-8' />
            <div className='text-lg'>사진 첨부하기</div>
          </div>
        )}
        {uploadFileInfoList.file_3 && uploadFileInfoList.imageSrc_3 ? (
          <div className='relative flex mt-3 h-[200px] rounded border items-center justify-center'>
            <X
              onClick={() => {
                setUploadFileInfoList({
                  ...uploadFileInfoList,
                  file_3: null,
                  imageSrc_3: null,
                });
              }}
              className='absolute top-2 right-2'
            ></X>
            <img
              className='object-fill h-full'
              src={uploadFileInfoList.imageSrc_3}
            ></img>
          </div>
        ) : (
          <div
            onClick={() => {
              fileUpload(
                {
                  acceptFile: 'image',
                  fileType: 'ReviewPicture',
                  fileName: 'ReviewPicture',
                  isPublic: true,
                },
                async (fileInfo, file) => {
                  let base64String = null;
                  if (file) base64String = await encodeFileToBase64(file);
                  setUploadFileInfoList({
                    ...uploadFileInfoList,
                    file_3: fileInfo,
                    imageSrc_3: base64String,
                  });
                },
              );
            }}
            className='relative mt-3 flex h-[100px] items-center justify-center gap-2 border'
          >
            <input type='file' className='absolute w-0 hidden' />
            <img src='/assets/images/v2/camera.svg' alt='' className='w-8' />
            <div className='text-lg'>사진 첨부하기</div>
          </div>
        )}
        {uploadFileInfoList.file_4 && uploadFileInfoList.imageSrc_4 ? (
          <div className='relative flex mt-3 h-[200px] rounded border items-center justify-center'>
            <X
              onClick={() => {
                setUploadFileInfoList({
                  ...uploadFileInfoList,
                  file_4: null,
                  imageSrc_4: null,
                });
              }}
              className='absolute top-2 right-2'
            ></X>
            <img
              className='object-fill h-full'
              src={uploadFileInfoList.imageSrc_4}
            ></img>
          </div>
        ) : (
          <div
            onClick={() => {
              fileUpload(
                {
                  acceptFile: 'image',
                  fileType: 'ReviewPicture',
                  fileName: 'ReviewPicture',
                  isPublic: true,
                },
                async (fileInfo, file) => {
                  let base64String = null;
                  if (file) base64String = await encodeFileToBase64(file);
                  setUploadFileInfoList({
                    ...uploadFileInfoList,
                    file_4: fileInfo,
                    imageSrc_4: base64String,
                  });
                },
              );
            }}
            className='relative mt-3 flex h-[100px] items-center justify-center gap-2 border'
          >
            <input type='file' className='absolute w-0 hidden' />
            <img src='/assets/images/v2/camera.svg' alt='' className='w-8' />
            <div className='text-lg'>사진 첨부하기</div>
          </div>
        )}

        <div className='py-4'>
          <Button variant='black' className='rounded-none' onClick={submit}>
            등록하기
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function main() {
  const navigate = useNavigate();
  const { setTopTabbarTitle, setTopTabbarState } = useSystemStore();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);
  const [childeSwiper, setChildeSwiper] = useState<SwiperCore | null>(null);
  const [childPage, setChildPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [quoteList, setQuoteList] = useState<CustomerOrderListData[]>();
  const [targetDate, setTargetDate] = useState<string>();
  const [intervalId, setIntervalId] = useState(0);
  const [isChoiceOpen, setChoiceOpen] = useState(false);
  // 동작관련
  const [isRestartOpen, setRestartOpen] = useState(false);
  const [isCancelOpen, setCancelOpen] = useState(false);
  const [isHoldOpen, setHoldOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState<string>();
  const [holdReason, setHoldReason] = useState<string>();
  const [selectManager, setSelectManager] = useState<string>();
  const { setOnResume } = useOnResumeStore();
  const [searchParams] = useSearchParams();

  const handleSwiper = useCallback(
    (swiper: SwiperClass) => {
      setChildeSwiper(swiper);
    },
    [setChildeSwiper],
  );

  const handleIndexChange = useCallback(
    (swiper: SwiperClass) => {
      setChildPage(swiper.realIndex + 1);
    },
    [setChildPage],
  );

  const Star = ({ fill }: { fill: string }) => {
    return (
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.14809 2.11375L7.02064 4.928H9.8424C9.99449 4.928 10.0585 5.13444 9.93445 5.22712L7.65303 6.96707L8.52557 9.78132C8.5736 9.93299 8.4055 10.0636 8.28142 9.96669L6 8.22674L3.71858 9.96669C3.5945 10.0594 3.42639 9.93299 3.47442 9.78132L4.34697 6.96707L2.06554 5.22712C1.94147 5.13444 2.00551 4.928 2.1576 4.928H4.97936L5.85191 2.11375C5.89994 1.96208 6.10006 1.96208 6.14809 2.11375Z'
          fill={fill}
          stroke={fill}
          strokeLinejoin='round'
        />
      </svg>
    );
  };
  const StarRating = ({ rating }: { rating: number }) => {
    return (
      <div className='flex'>
        {[...Array(5)].map((_, index) => (
          <Star key={index} fill={index < rating ? '#111111' : '#E5E5EC'} />
        ))}
      </div>
    );
  };
  const formatTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    const paddedHrs = hrs.toString().padStart(2, '0');
    const paddedMins = mins.toString().padStart(2, '0');
    const paddedSecs = secs.toString().padStart(2, '0');
    return `${paddedHrs}:${paddedMins}:${paddedSecs}`;
  };
  const updateQuoteList = (newData: CustomerOrderListData) => {
    if (quoteList && page) {
      setQuoteList((prevList) => {
        const newList = [...(prevList as CustomerOrderListData[])];
        if (newList.length >= 1) {
          newList[page - 1] = newData;
        }
        return newList;
      });
    } else {
      return quoteList;
    }
  };

  const handleVoidEvents = {
    getQuoteList: async () => {
      try {
        const result = await getCustomerQuoteListApi();
        if (result && result.length > 0) {
          setQuoteList(result);
          setIsEmpty(false);
          console.info(result);
          const today = new Date();
          console.info(today);
          if (result[0].created_at) {
            const createDate = new Date(result[0].created_at);
            const endDate = new Date(
              createDate.getTime() + 48 * 60 * 60 * 1000,
            );
            Timer(today, endDate);
          }
        } else {
          CarsayoToast.error('견적함 데이터가 없습니다.');
          setIsEmpty(true);
        }
      } catch (error: any) {
        console.error(error);
      }
    },
  };

  const Timer = (today: Date, endDate: Date) => {
    // 이전 타이머 종료
    if (intervalId) {
      clearInterval(intervalId);
    }
    if (today < endDate) {
      const timeDifference = endDate.getTime() - today.getTime();
      let remainingSeconds = Math.floor(timeDifference / 1000); // 초단위

      const updateRemainingTime = () => {
        if (remainingSeconds <= 0) {
          setTargetDate('종료');
        } else {
          remainingSeconds--;
          setTargetDate(formatTime(remainingSeconds));
        }
      };

      updateRemainingTime();
      const newIntervalId = setInterval(updateRemainingTime, 1000);
      setIntervalId(newIntervalId as unknown as number);

      return () => clearInterval(newIntervalId);
    } else {
      setTargetDate('종료');
    }
  };

  const handleClickEvents = {
    choiceManager: async () => {
      console.info(selectManager);
      if (selectManager && quoteList && quoteList[page - 1].id) {
        const reqData = {
          orderId: quoteList[page - 1].id,
          managerId: selectManager,
        };
        try {
          const result = await selectManagerApi(reqData);
          if (result) {
            CarsayoToast.text(`${result.manager?.name_nick}님이 선택됐습니다.`);
            updateQuoteList(result);
            handleVoidEvents.getQuoteList();
          }
        } catch (error: any) {
          console.error(error);
          CarsayoToast.error(error);
        }
      } else {
        CarsayoToast.error('선택된 매니저가 없습니다.');
      }
    },
    goDetail: (id: string) => () => {
      navigate(`/quote/customer/detail/${id}`);
    },
    fileOpen: (e: React.MouseEvent<HTMLDivElement>, url: string | null) => {
      if (url) {
        e.preventDefault();
        window.open(url, '_blank');
      } else {
        return;
      }
    },
    reStart: (id: string) => async () => {
      if (id) {
        try {
          const result = await customerReStartQuoteApi(id);
          if (result) {
            CarsayoToast.success('재계약으로 진행합니다.');
            setRestartOpen(!isRestartOpen);
            updateQuoteList(result);
            setIsOpen(false);
          }
        } catch (error: any) {
          console.error(error);
          CarsayoToast.error(error);
          setRestartOpen(!isRestartOpen);
          setIsOpen(false);
        }
      }
    },
    cancel: (id: string) => async () => {
      if (id && cancelReason) {
        const reqData: OrderCancelDTO = {
          orderId: id,
          reason: cancelReason,
        };
        try {
          const result = await customerCancelQuoteApi(reqData);
          if (result) {
            CarsayoToast.success('주문을 취소했습니다.');
            setCancelOpen(!isCancelOpen);
            updateQuoteList(result);
            setIsOpen(false);
          }
        } catch (error: any) {
          console.error(error);
          CarsayoToast.error(error);
          setCancelOpen(!isCancelOpen);
          setIsOpen(false);
        }
      } else {
        CarsayoToast.error('항목을 입력해주세요');
      }
    },
    hold: (id: string) => async () => {
      if (id && holdReason) {
        const reqData: OrderHoldDTO = {
          orderId: id,
          reason: holdReason,
        };
        try {
          const result = await customerHoldQuoteApi(reqData);
          if (result) {
            CarsayoToast.success('주문을 보류했습니다.');
            setHoldOpen(!isHoldOpen);
            updateQuoteList(result);
            setIsOpen(false);
          }
        } catch (error: any) {
          console.error(error);
          CarsayoToast.error(error);
          setHoldOpen(!isHoldOpen);
          setIsOpen(false);
        }
      } else {
        CarsayoToast.error('항목을 입력해주세요');
      }
    },
    delete: (id: string) => async () => {
      if (id) {
        try {
          await customerDeleteQuoteApi(id);
          CarsayoToast.success('삭제했습니다.');
          setDeleteOpen(!isDeleteOpen);
          handleVoidEvents.getQuoteList();
          setIsOpen(false);
        } catch (error: any) {
          console.error(error);
          CarsayoToast.error(error);
          setDeleteOpen(!isDeleteOpen);
          setIsOpen(false);
        }
      }
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <ChevronLeft
          onClick={() => {
            navigate('/main');
          }}
          className='h-7 w-7'
        ></ChevronLeft>
      ),
      rightElement: (
        <X
          onClick={() => {
            navigate('/main');
          }}
          className='h-7 w-7'
        ></X>
      ),
      className: 'bg-quote-background text-white',
    });
    setTopTabbarTitle('견적함');
    handleVoidEvents.getQuoteList();
  }, []);

  useEffect(() => {
    setOnResume(async () => {
      try {
        const result = await getCustomerQuoteListApi();
        if (result && result.length > 0) {
          setQuoteList(result);
          setIsEmpty(false);
          console.info(result);
          const today = new Date();
          console.info(today);
          if (result[0].created_at) {
            const createDate = new Date(result[0].created_at);
            const endDate = new Date(
              createDate.getTime() + 48 * 60 * 60 * 1000,
            );
            Timer(today, endDate);
          }
        } else {
          CarsayoToast.error('견적함 데이터가 없습니다.');
          setIsEmpty(true);
        }
      } catch (error: any) {
        console.error(error);
      }
    });
  }, []);

  useEffect(() => {
    if (!swiper || !quoteList) return;
    // 이 값이 정의되어 있으면, 해당 주문을 먼저 리스트에서 보여줍니다.
    const initalOrderId: string | null = searchParams.get('orderId');
    searchParams.delete('orderId');

    const currentOrderId: string | null =
      searchParams.entries().next().value?.[0] ?? null;

    if (initalOrderId) {
      quoteList.map((el, index) => {
        if (el.id === initalOrderId) {
          swiper.slideTo(index);
          return;
        }
      });
      return;
    } else if (currentOrderId) {
      quoteList.map((el, index) => {
        if (el.id === currentOrderId) {
          swiper.slideTo(index, 0);
          return;
        }
      });
      return;
    }
  }, [swiper]);

  useEffect(() => {
    if (!quoteList) return;

    setTargetDate('');
    const targetOrder = quoteList[page - 1];

    navigate({ search: targetOrder.id }, { replace: true });

    const today = new Date();
    if (quoteList && quoteList[page - 1].created_at) {
      const createDate = new Date(quoteList[page - 1].created_at);
      const endDate = new Date(createDate.getTime() + 48 * 60 * 60 * 1000);
      Timer(today, endDate);
    } else {
      setTargetDate('데이터 없음');
    }
  }, [page]);

  return (
    <div className='min-h-screen pb-4'>
      {/* 상단 스와이퍼 */}
      {quoteList && quoteList.length > 0 && (
        <div className='pt-6 bg-[#16171E] bg-quotation-bg bg-no-repeat py-6 pb-8 [background-position:0_120px] [background-size:100%_636px]'>
          <Swiper
            slidesPerView={1}
            onRealIndexChange={(swiper: SwiperClass) =>
              setPage(swiper.realIndex + 1)
            }
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
          >
            {quoteList &&
              quoteList.length > 0 &&
              quoteList.map((item, index) => {
                return (
                  <SwiperSlide key={`${item.id}_${index}`}>
                    <div className='relative flex h-8 items-center justify-center'>
                      <div className='relative z-10 flex h-8 items-center justify-center rounded-3xl border border-[#35383F] bg-[#1F222A] px-4 font-semibold text-white'>
                        {item.created_at
                          ? `${dateToStringMMDD(item.created_at)} ${dateToStringHHMM(item.created_at)}`
                          : ''}
                      </div>
                      <div className='absolute left-0 right-0 top-1/2 h-[1px] -translate-y-1/2 bg-[#35383F]'></div>
                    </div>
                    <div className='px-4 py-6'>
                      <div className='flex h-6 items-center justify-between'>
                        <div className='flex items-center gap-1'>
                          {item.state === 'submitted' && (
                            <>
                              <div>
                                <img
                                  src='/assets/images/v2/clock.svg'
                                  alt='알람'
                                />
                              </div>
                              {dayjs(item.bid_ended_at).isAfter(new Date()) ? (
                                <div className='px-1 text-sm font-medium text-white'>
                                  카매니저 입찰중
                                </div>
                              ) : (
                                <div className='px-1 text-sm font-medium text-white'>
                                  입찰 종료
                                </div>
                              )}
                            </>
                          )}
                          {/* <div>
                            <img src='/assets/images/v2/clock.svg' alt='알람' />
                          </div>
                          <div className='text-white'>
                            <span>{targetDate}</span>
                          </div> */}
                        </div>
                        <div onClick={() => setIsOpen(true)}>
                          <img
                            src='/assets/images/v2/more.svg'
                            alt='상세보기'
                          />
                        </div>
                      </div>
                      {item.type === 'sellCar' ? (
                        item.imageUrl && item.imageUrl.length > 0 ? (
                          typeof item.imageUrl === 'string' ? (
                            <div className='w-full my-2 relative px-[26.6vw] py-[4.06vh] h-[20vh] overflow-hidden flex justify-center items-center'>
                              <img
                                src={item.imageUrl}
                                alt='입찰차량이미지'
                                className='object-cover'
                              />
                            </div>
                          ) : item.imageUrl && item.imageUrl.length > 0 ? (
                            item.imageUrl.length === 1 ? (
                              <div className='relative mt-4 h-[150px] w-full'>
                                <div className='flex h-[150px] items-center justify-center overflow-hidden'>
                                  <img
                                    src={item.imageUrl[0]}
                                    alt=''
                                    className='w-full h-[150px] object-cover'
                                  />
                                </div>
                              </div>
                            ) : item.imageUrl.length > 1 ? (
                              <div className='relative mt-4 h-[150px] w-full'>
                                <Swiper
                                  modules={[Pagination]}
                                  slidesPerView={1}
                                  loop={true}
                                  pagination={{ clickable: true }}
                                  onRealIndexChange={handleIndexChange}
                                  onSwiper={handleSwiper}
                                  className='bullet-bg'
                                >
                                  {item.imageUrl.map((img, index) => (
                                    <SwiperSlide
                                      key={`sub_${index}`}
                                      className='flex h-[150px] items-center justify-center overflow-hidden'
                                    >
                                      <img
                                        src={img}
                                        className='w-full h-[150px] object-cover'
                                      />
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </div>
                            ) : (
                              <div className='relative mt-4 h-[150px] w-full'>
                                <div className='flex h-[150px] items-center justify-center overflow-hidden'>
                                  <img
                                    src={CarsayoImg}
                                    alt=''
                                    className='w-full object-contain'
                                  />
                                </div>
                              </div>
                            )
                          ) : (
                            <div className='relative mt-4 h-[150px] w-full'>
                              <div className='flex h-[150px] items-center justify-center overflow-hidden'>
                                <img
                                  src={CarsayoImg}
                                  alt=''
                                  className='w-full object-contain'
                                />
                              </div>
                            </div>
                          )
                        ) : (
                          <span className='text-white min-h-[20vh] flex justify-center items-center'>
                            이미지가 없습니다.
                          </span>
                        )
                      ) : (
                        <div className='w-full relative px-[26.6vw] py-[4.06vh] flex justify-center items-center'>
                          {item.imageUrl ? (
                            typeof item.imageUrl === 'string' ? (
                              <img
                                src={item.imageUrl}
                                alt='입찰차량이미지'
                                className='h-[11vh] object-fill'
                              />
                            ) : (
                              <img
                                src={item.imageUrl[0]}
                                alt='입찰차량이미지'
                                className='h-[11vh] object-fill'
                              />
                            )
                          ) : (
                            <span className='text-white min-h-[11vh] flex justify-center items-center'>
                              이미지가 없습니다.
                            </span>
                          )}
                        </div>
                      )}

                      <div className='mt-4 text-center text-title font-semibold text-white'>
                        {item.brand && (
                          <span className='mr-[3px]'>{item.brand}</span>
                        )}
                        {item.title ?? '견적 제목 없음'}
                      </div>
                      <div className='mt-4 flex h-[50px] items-center justify-around text-center text-white'>
                        <div>
                          <img
                            src='/assets/images/v2/card.svg'
                            alt=''
                            className='inline-block'
                          />
                          <div className='mt-2 text-desc'>
                            {item.type
                              ? item.type === 'newCar'
                                ? item.newCar?.installmentTerm
                                  ? '할부'
                                  : ' 일시불'
                                : convertDealerType[item.type]
                              : '타입없음'}
                          </div>
                        </div>
                        <div className='h-[34px] w-[1px] bg-[#35383F]'></div>
                        <div>
                          <img
                            src='/assets/images/v2/pen.svg'
                            alt=''
                            className='inline-block'
                          />
                          <div className='mt-2 text-desc'>
                            {item.state
                              ? convertOrderStatus[item.state]
                              : '상태없음'}
                          </div>
                        </div>
                        <div className='h-[34px] w-[1px] bg-[#35383F]'></div>
                        <div onClick={handleClickEvents.goDetail(item.id)}>
                          <img
                            src='/assets/images/v2/email.svg'
                            alt=''
                            className='inline-block'
                          />
                          <div className='mt-2 text-desc'>상세보기</div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div className='flex items-center justify-center gap-3'>
            <div
              className={cn(
                'flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border-[#E5E5EC] bg-white text-[#111111]',
                swiper?.isBeginning && 'text-[#CACAD7]',
              )}
              onClick={() => swiper?.slidePrev()}
            >
              <ChevronLeft className='h-4 w-4' strokeWidth='1' />
            </div>
            <div className='text-base text-[#9EA3B2]' id='containerForBullets'>
              <span className='font-semibold text-white'>{page}</span>/{' '}
              {quoteList.length}
            </div>
            <div
              className={cn(
                'flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border-[#E5E5EC] bg-white text-[#111111]',
                swiper?.isEnd && 'text-[#CACAD7]',
              )}
              onClick={() => swiper?.slideNext()}
            >
              <ChevronRight className='h-4 w-4' strokeWidth='1' />
            </div>
          </div>
        </div>
      )}
      {/* 상세내용 */}
      {page &&
        quoteList &&
        quoteList.length > 0 &&
        quoteList[page - 1] &&
        quoteList[page - 1] !== null && (
          <div className='w-full bg-[#F1F1F5]'>
            {quoteList[page - 1].manager &&
            quoteList[page - 1].processCardList ? (
              // 담당 매니저 정보 박스 (낙찰 후)
              <div className='bg-white px-4 pt-6 pb-2'>
                <div className='flex items-center justify-between mb-[1.72vh]'>
                  <div className='text-[18px] text-[#111111] font-[600]'>
                    담당 매니저
                  </div>
                  <div className='flex items-center gap-1'>
                    <div className='flex items-center'>
                      <StarRating
                        rating={
                          quoteList[page - 1].manager?.dealerInfo?.rating
                            ? Number(
                                quoteList[page - 1].manager?.dealerInfo?.rating,
                              )
                            : 4
                        }
                      />
                    </div>
                    <div className='text-desc'>
                      {quoteList[page - 1].manager?.dealerInfo?.rating
                        ? quoteList[page - 1].manager?.dealerInfo?.rating
                        : '4.0'}
                    </div>
                  </div>
                </div>
                <div className='flex gap-[2vw] pb-4'>
                  <div className='flex h-[50px] w-[50px] items-center justify-center overflow-hidden rounded-full'>
                    {quoteList[page - 1]?.manager &&
                    quoteList[page - 1].manager !== null &&
                    quoteList[page - 1].manager?.profileImage_url ? (
                      <img
                        src={
                          quoteList[page - 1].manager?.profileImage_url ??
                          'https://cdn.carsayo.net/resource/image/profileImage_default_1.svg'
                        }
                        alt={
                          quoteList[page - 1].manager?.name_nick ??
                          '딜러프로필이미지'
                        }
                        className='w-[50px] h-[50px] object-cover'
                      />
                    ) : (
                      <img
                        src='https://cdn.carsayo.net/resource/image/profileImage_default_1.svg'
                        alt='딜러프로필샘플이미지'
                        className='w-[50px] h-[50px] object-cover'
                      />
                    )}
                  </div>
                  <div className='grow flex flex-col justify-start items-start'>
                    <div className='flex justify-start items-center gap-2 text-[13px]'>
                      {/* <p className='text-[#555555] text-[14px]'>
                        {quoteList[page - 1].manager?.dealerInfo
                          ?.organization ?? '소속 없음'}
                      </p>
                      <p>
                        {quoteList[page - 1].manager?.name_real ?? '이름 없음'}
                      </p> */}
                      곧 연락드릴 예정이오니 모르는 번호라도 받아주세요.
                    </div>
                    <div className='w-full mt-2.5 flex justify-between items-center gap-2'>
                      <p className='text-[#767676] texgt-[14px]'>
                        {quoteList[page - 1].manager?.name_nick ??
                          '닉네임 없음'}
                      </p>
                      {/* <p className='flex justify-start items-center'>
                        <span className='mr-3'>
                          {quoteList[page - 1]?.manager?.phoneNumber ? (
                            quoteList[page - 1]?.manager?.phoneNumber ===
                            '비공개' ? (
                              '비공개'
                            ) : (
                              '공개'
                            )
                          ) : (
                            <span className='text-[#999999]'>없음</span>
                          )}
                        </span>
                        {quoteList[page - 1]?.manager?.phoneNumber ? (
                          quoteList[page - 1]?.manager?.phoneNumber ===
                          '비공개' ? null : (
                            <a
                              className='w-[20px] h-[20px] flex justify-center items-center'
                              href={`tel:${quoteList[page - 1]?.manager?.phoneNumber ?? ''}`}
                            >
                              <TelIcon />
                            </a>
                          )
                        ) : null}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : !quoteList[page - 1].manager ? (
              // 참여 카 매니저 정보 박스 (낙찰 전)
              <div className='bg-white px-4 py-6'>
                <div className='font-sm text-lg font-semibold'>
                  참여 카 매니저
                </div>
                <div className='flex items-center justify-between'>
                  <div className='text-[48px] font-semibold leading-[62px]'>
                    {quoteList[page - 1].bidList &&
                    quoteList[page - 1].bidList.length > 0
                      ? quoteList[page - 1].bidList.length
                      : 0}
                  </div>
                  <div className='flex items-center justify-end -space-x-8'>
                    {/* <img src="/images/casayo-logo.svg" alt="" /> */}
                    {quoteList[page - 1].bidList &&
                    quoteList[page - 1].bidList.length > 0 ? (
                      quoteList[page - 1].bidList.map((item, index) => (
                        <img
                          key={index}
                          src={item.dealer.profileImage_url}
                          alt={item.dealer.name_nick}
                          className='h-12 w-12 overflow-hidden rounded-full border object-cover'
                        />
                      ))
                    ) : (
                      <img
                        key={'bidder_sample'}
                        src='https://cdn.carsayo.net/resource/image/profileImage_default_1.svg'
                        alt='견적참여딜러샘플이미지 '
                        className='h-12 w-12 overflow-hidden rounded-full border object-cover'
                      />
                    )}
                  </div>
                </div>
                <div className='mt-2 flex h-[34px] items-center gap-2 border px-2'>
                  <div>
                    <img src='/assets/images/v2/sound.svg' alt='' />
                  </div>
                  <div className='text-desc text-[#767676]'>
                    카 매니저님의 실명과 연락처는 낙찰 후 공개됩니다
                  </div>
                </div>
              </div>
            ) : null}
            {quoteList[page - 1].state === 'finished' &&
              (quoteList[page - 1].review?.isWritten !== true ? (
                <Review
                  page={page}
                  quoteList={quoteList}
                  setQuoteList={setQuoteList}
                ></Review>
              ) : (
                <ReviewThanks />
              ))}
            {/* 타임라인 - 견적서 없는데 취소나 종료됐을때 */}
            {!quoteList[page - 1].manager &&
              quoteList[page - 1].state === 'nonAccepted' && (
                <div className='mt-3 bg-white py-10 pb-[52px] text-center'>
                  <div className='text-[28px] font-semibold leading-[38px]'>
                    48시간이 경과되어
                    <br />
                    서비스가 종료되었습니다
                  </div>
                  <div className='mt-4 text-[#9EA3B2]'>
                    언제든지 견적 요청을 통해
                    <br />
                    전국 카 매니저님의 견적을 확인할 수 있습니다.
                  </div>
                  <div className='mx-auto mt-2 h-[200px] w-[200px] flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/messageIcon.svg'
                      alt=''
                      className='object-contain'
                    />
                  </div>
                </div>
              )}
            {/* 타임라인 - 견적서 없을 때 */}
            {!quoteList[page - 1].manager &&
              quoteList[page - 1].state === 'submitted' &&
              quoteList[page - 1].bidList.length === 0 && (
                <div className='mt-3 bg-white py-10 pb-[52px] text-center'>
                  <div className='text-[28px] font-semibold leading-[38px]'>
                    견적서를
                    <br />
                    작성하고 있어요
                  </div>
                  <div className='mt-4 text-[#9EA3B2]'>
                    조금만 기다리시면 카사요가 인증한
                    <br />
                    전국 카매니저님들의 견적을 확인할 수 있습니다.
                  </div>
                  <div className='mx-auto mt-2 h-[200px] w-[200px]'>
                    <img src='/assets/images/v2/People-3.png' alt='' />
                  </div>
                </div>
              )}
            {/* 타임라인 - 입찰시간 지났을 때 */}
            {/* {(quoteList[page - 1].processCardList === undefined ||
              quoteList[page - 1].bidList.length === 0) &&
              quoteList[page - 1].state === 'failBid' && (
                <div className='mt-3 bg-white py-10 pb-[52px] text-center'>
                  <div className='text-[28px] font-semibold leading-[38px]'>
                    48시간이 경과되어
                    <br />
                    서비스가 종료되었습니다
                  </div>
                  <div className='mt-4 text-[#9EA3B2]'>
                    언제든지 견적 요청을 통해
                    <br />
                    전국 카 매니저님의 견적을 확인할 수 있습니다.
                  </div>
                  <div className='mx-auto mt-2 h-[200px] w-[200px] flex justify-center items-center'>
                    <img
                      src='/assets/images/v2/messageIcon.svg'
                      alt=''
                      className='object-contain'
                    />
                  </div>
                </div>
              )} */}
            {/* 타임라인 - 견적서 있을 때(낙찰 전 입찰리스트) */}
            {quoteList[page - 1].bidList &&
              quoteList[page - 1].bidList.length > 0 &&
              (quoteList[page - 1]?.processCardList === undefined ||
                quoteList[page - 1]?.processCardList.length === 0) &&
              quoteList[page - 1].bidList.map((item, index) => {
                return (
                  <div
                    className='w-full mt-3 bg-white px-4 py-6'
                    key={`bidderData_${index}`}
                  >
                    <div className='flex gap-2 border-b pb-4'>
                      <div className='flex h-[50px] w-[50px] items-center justify-center overflow-hidden rounded-full'>
                        {item.dealer.profileImage_url ? (
                          <img
                            src={item.dealer.profileImage_url}
                            alt={item.dealer.name_nick}
                          />
                        ) : (
                          <img
                            src='/assets/images/v2/Ellipse.png'
                            alt='딜러프로필샘플이미지'
                          />
                        )}
                      </div>
                      <div className='flex-1'>
                        <div className='flex items-center justify-between'>
                          <div className='text-desc text-[#767676]'>
                            카 매니저
                          </div>
                          <div className='flex items-center gap-1'>
                            <div className='flex items-center'>
                              <StarRating
                                rating={
                                  item.dealer.dealerInfo?.rating
                                    ? item.dealer.dealerInfo.rating
                                    : 4
                                }
                              />
                            </div>
                            <div className='text-desc'>
                              {item.dealer.dealerInfo?.rating
                                ? item.dealer.dealerInfo.rating
                                : '4.0'}
                            </div>
                          </div>
                        </div>
                        <div className='mt-2.5'>
                          {item.dealer.name_nick ?? '닉네임 없음'}
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center gap-1 py-4 text-title'>
                      <div className='flex flex-row grow gap-[4px] justify-start items-center'>
                        <div className='flex h-[22px] w-5 items-center justify-center bg-[#111111] text-[11px] text-white'>
                          {/* {index + 1 < 10 ? `0${index + 1}` : index + 1} */}
                          {quoteList[page - 1].bidList.length - index < 10
                            ? `0${quoteList[page - 1].bidList.length - index}`
                            : quoteList[page - 1].bidList.length - index}
                        </div>
                        <div className='text-[#767676]'>견적서</div>
                      </div>
                      <div
                        className={classNames(
                          'min-w-[27px] py-[2px] px-[4px] border text-[11px] tracking-[-0.28px]',
                          item.state &&
                            `!border-[${parseOrderProcessState(item.state).color}] !text-[${parseOrderProcessState(item.state).color}]`,
                        )}
                      >
                        {item.state && item.created_at
                          ? `${parseOrderProcessState(item.state).text} ${dateToStringMMDD(item.created_at)} ${dateToStringHHMM(item.created_at)}`
                          : '없음'}
                      </div>
                    </div>
                    <div className='bg-[#F7F7FB] p-4 text-title'>
                      <Confirm
                        isOpen={isChoiceOpen}
                        setIsOpen={setChoiceOpen}
                        isCloseButton={true}
                        onConfirm={handleClickEvents.choiceManager}
                        title='담당 카 매니저 결정'
                        desc={`선택하신 카 매니저분과 진행을 하시겠습니까?`}
                      ></Confirm>
                      {item.estimateFile && (
                        <div
                          className='mb-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                          onClick={() => {
                            item.estimateFile !== null
                              ? download(item.estimateFile)
                              : null;
                          }}
                        >
                          견적서 보기
                        </div>
                      )}
                      {item.purchasePrice && (
                        <div className='mb-3 flex h-12 items-center justify-between border border-[#E5E5EC] bg-white px-4 text-[#999999]'>
                          <p className='text-[#767676] min-w-[12vw]'>
                            매입가격
                          </p>
                          <p className='text-[#111111]'>
                            {item.purchasePrice ? (
                              `${item.purchasePrice.toLocaleString()}원`
                            ) : (
                              <span className='text-[#999999]'>없음</span>
                            )}
                          </p>
                        </div>
                      )}
                      <div className='whitespace-pre-wrap w-full mb-3'>
                        {item.content
                          ? ReactHtmlParser(item.content)
                          : '내용 없음'}
                      </div>
                      <div
                        className='flex h-12 items-center justify-center bg-[#7273F9] font-medium text-white'
                        onClick={() => {
                          setSelectManager(item.dealer.id);
                          setChoiceOpen(!isChoiceOpen);
                        }}
                      >
                        이 카매니저와 진행하고 싶어요
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* 타임라인 - 낙찰 됐을 때 리스트 */}
            {quoteList[page - 1].manager &&
              quoteList[page - 1].processCardList &&
              quoteList[page - 1].processCardList.length > 0 &&
              quoteList[page - 1].processCardList.map((item, index) => {
                return (
                  <div
                    className='mt-3 bg-white px-4 pt-4 pb-6'
                    key={`processCard_${index}`}
                  >
                    <div className='flex justify-between items-center w-full'>
                      <div className='flex items-center gap-1 py-4 text-title grow'>
                        <div className='flex h-[22px] w-5 items-center justify-center bg-[#111111] text-[11px] text-white'>
                          {/* {index + 1 < 10 ? `0${index + 1}` : index + 1} */}
                          {quoteList[page - 1].processCardList.length - index <
                          10
                            ? `0${quoteList[page - 1].processCardList.length - index}`
                            : quoteList[page - 1].processCardList.length -
                              index}
                        </div>
                        <div className='text-[#767676]'>
                          {item.title ?? '제목없음'}
                        </div>
                      </div>
                      <div
                        className={classNames(
                          'min-w-[27px] py-[2px] px-[4px] border text-[11px] tracking-[-0.28px]',
                          item.state &&
                            `!border-[${parseOrderProcessState(item.state).color}] !text-[${parseOrderProcessState(item.state).color}]`,
                        )}
                      >
                        {item.state && item.created_at
                          ? `${parseOrderProcessState(item.state).text} ${dateToStringMMDD(item.created_at)} ${dateToStringHHMM(item.created_at)}`
                          : '없음'}
                      </div>
                    </div>
                    {/* 카드 중앙 */}
                    <div className='bg-[#F7F7FB] p-4 text-title'>
                      {item.file_1 && (
                        <div
                          className='mb-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                          onClick={() => {
                            item.file_1 !== null ? download(item.file_1) : null;
                          }}
                        >
                          {item.file_1.name}
                        </div>
                      )}
                      {item.file_2 && (
                        <div
                          className='mb-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                          onClick={() => {
                            item.file_2 !== null ? download(item.file_2) : null;
                          }}
                        >
                          {item.file_2.name}
                        </div>
                      )}
                      {item.file_3 && (
                        <div
                          className='mb-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                          onClick={() => {
                            item.file_3 !== null ? download(item.file_3) : null;
                          }}
                        >
                          {item.file_3.name}
                        </div>
                      )}
                      {item.additionalInfo_1 && (
                        <div className='mb-3 flex h-12 items-center justify-between border border-[#E5E5EC] bg-[#F1F1F5] px-4 text-[#999999]'>
                          <div className='font-medium text-[#767676]'>
                            {item.additionalInfo_1.title ?? '제목없음'}
                          </div>
                          <div className='whitespace-pre-wrap font-medium text-[#111111]'>
                            {item.additionalInfo_1.content
                              ? ReactHtmlParser(item.additionalInfo_1.content)
                              : '내용 없음'}
                          </div>
                        </div>
                      )}
                      {item.additionalInfo_2 && (
                        <div className='mb-3 flex h-12 items-center justify-between border border-[#E5E5EC] bg-[#F1F1F5] px-4 text-[#999999]'>
                          <div className='font-medium text-[#767676]'>
                            {item.additionalInfo_2.title ?? '제목없음'}
                          </div>
                          <div className='whitespace-pre-wrap font-medium text-[#111111]'>
                            {item.additionalInfo_2.content
                              ? ReactHtmlParser(item.additionalInfo_2.content)
                              : '내용 없음'}
                          </div>
                        </div>
                      )}
                      {item.additionalInfo_3 && (
                        <div className='mb-3 flex h-12 items-center justify-between border border-[#E5E5EC] bg-[#F1F1F5] px-4 text-[#999999]'>
                          <div className='font-medium text-[#767676]'>
                            {item.additionalInfo_3.title ?? '제목없음'}
                          </div>
                          <div className='whitespace-pre-wrap font-medium text-[#111111]'>
                            {item.additionalInfo_3.content
                              ? ReactHtmlParser(item.additionalInfo_3.content)
                              : '내용 없음'}
                          </div>
                        </div>
                      )}
                      <div className='whitespace-pre-wrap w-full'>
                        {item.content
                          ? ReactHtmlParser(item.content)
                          : '내용 없음'}
                      </div>
                    </div>
                    {/* 카드 하단 */}
                    {/* {item.state === 'accepted' && (
                    <div className='w-full text-[#111111] border border-[#E5E5EC] mt-4 py-4 px-2 flex flex-row justify-start items-center gap-2'>
                      <span className='w-[16px] h-[16px] flex justify-center items-center mr-1'>
                        <SoundIcon />
                      </span>
                      계약서가 준비되면 곧 업데이트됩니다.
                    </div>
                  )}
                  {item.state === 'progressing' && (
                    <div className='w-full mt-4 flex gap-[33px]'>
                      <div
                        className='flex h-9 grow items-center justify-center border text-desc'
                        onClick={() => setIsCancel(true)}
                      >
                        취소
                      </div>
                      <div
                        className='flex h-9 grow items-center justify-center border text-desc'
                        onClick={() => setIsCancel(true)}
                      >
                        보류
                      </div>
                    </div>
                  )}
                  {item.state === 'holded' && (
                    <div className='w-full mt-4 flex gap-[33px]'>
                      <div
                        className='flex h-9 grow items-center justify-center border text-desc'
                        onClick={() => setIsCancel(true)}
                      >
                        재계약
                      </div>
                    </div>
                  )} */}
                  </div>
                );
              })}
            {/* <div className='mt-3 bg-white px-4 py-6'>
            <div className='flex gap-2 border-b pb-4'>
              <div className='flex h-[50px] w-[50px] items-center justify-center overflow-hidden rounded-full'>
                <img src='/assets/images/v2/Ellipse.png' alt='' />
              </div>
              <div className='flex-1'>
                <div className='flex items-center justify-between'>
                  <div className='text-desc text-[#767676]'>카 매니저</div>
                  <div className='flex items-center gap-1'>
                    <div className='flex items-center'>
                      <StarRating rating={4} />
                    </div>
                    <div className='text-desc'>4.0</div>
                  </div>
                </div>
                <div className='mt-2.5'>낭만적인 말미잘</div>
              </div>
            </div>
            <div className='flex items-center gap-1 py-4 text-title'>
              <div className='flex h-[22px] w-5 items-center justify-center bg-[#111111] text-[11px] text-white'>
                01
              </div>
              <div className='text-[#767676]'>견적서</div>
              <div className='text-[#767676]'>
                <span className='text-[#111111]'>05.25 (23:31)</span> 발송
              </div>
            </div>
            <div className='bg-[#F7F7FB] p-4 text-title'>
              <div>첫차에 대한 감사의 마음으로 드리는 견적서.PDF</div>
              <div className='mt-3 flex h-12 items-center justify-center border border-[#E5E5EC] bg-[#F1F1F5] text-[#999999]'>
                견적서 보기
              </div>
              <div className='mt-3 flex h-12 items-center justify-between border border-[#E5E5EC] bg-[#F1F1F5] px-4 text-[#999999]'>
                <div className='font-medium text-[#767676]'>매입 가격</div>
                <div className='font-medium text-[#111111]'>8,200만원</div>
              </div>
              <div className='mt-3'>
                보내 주신 중고차 아끼고 아끼셨을 텐데 제가 좋은 가격에 매입해서
                좋은 분에게 판매하도록 하겠습니 .
              </div>
              <div className='mt-3 flex h-12 items-center justify-center bg-[#7273F9] font-medium text-white'>
                이 카매니저와 진행하고 싶어요
              </div>
            </div>
          </div> */}
          </div>
        )}

      {isEmpty && (
        <div className='absolute left-1/2 top-[80px] z-20 h-[684px] w-[343px] -translate-x-1/2 items-center justify-center rounded-[20px] bg-black/90 text-center text-white'>
          <div className='mx-auto mt-[63px] h-[100px] w-[100px]'>
            <img src='/assets/images/v2/empty.png' alt='' />
          </div>
          <div className='mt-10 text-[28px] font-semibold leading-[38px]'>
            견적함이
            <br />
            비어 있습니다
          </div>
          <div className='mt-3 text-[#9EA3B2]'>
            카사요에서 전국 카 매니저님의 견적을
            <br />
            무료로 받아 보세요
          </div>
          <Button
            className='mt-[121px] w-[205px] rounded-none bg-[#0075FF]'
            onClick={() => navigate('/purchase/brand', { replace: true })}
          >
            견적 신청하기
          </Button>
        </div>
      )}

      <Cancel isOpen={isCancel} setIsOpen={setIsCancel} />

      {/* <Setting isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      {/* 설정 메뉴 팝업 */}
      <Drawer open={isOpen}>
        <DrawerContent className='flex flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>설정</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' onClick={() => setIsOpen(false)} />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <div className='p-4'>
            <div className='border-t'>
              <a
                className='mt-4 flex h-[52px] w-full items-center justify-center border text-base font-medium'
                onClick={
                  quoteList && quoteList[page - 1]?.id
                    ? handleClickEvents.goDetail(quoteList[page - 1].id)
                    : () => {}
                }
              >
                상세보기
              </a>
              {quoteList && quoteList[page - 1].state === 'holded' && (
                <a
                  className='mt-4 flex h-[52px] w-full items-center justify-center border text-base font-medium'
                  onClick={() => setRestartOpen(!isRestartOpen)}
                >
                  재계약
                </a>
              )}
              {quoteList && quoteList[page - 1].state === 'progressing' && (
                <a
                  className='mt-4 flex h-[52px] w-full items-center justify-center border text-base font-medium'
                  onClick={() => setHoldOpen(!isHoldOpen)}
                >
                  보류하기
                </a>
              )}
              {quoteList &&
                (quoteList[page - 1].state === 'holded' ||
                  quoteList[page - 1].state === 'progressing') && (
                  <a
                    className='mt-4 flex h-[52px] w-full items-center justify-center border text-base font-medium'
                    onClick={() => setCancelOpen(!isCancelOpen)}
                  >
                    취소하기
                  </a>
                )}
              {quoteList &&
                quoteList[page - 1].state === 'submitted' &&
                (quoteList[page - 1].bidList === undefined ||
                  quoteList[page - 1].bidList === null ||
                  quoteList[page - 1].bidList.length < 1) && (
                  <a
                    className='mt-4 flex h-[52px] w-fu ll items-center justify-center border text-base font-medium'
                    onClick={() => setDeleteOpen(!isDeleteOpen)}
                  >
                    삭제하기
                  </a>
                )}
            </div>
          </div>
        </DrawerContent>
      </Drawer>

      {/* 주문 보류 팝업 */}
      <Dialog open={isHoldOpen} onOpenChange={() => setHoldOpen(!isHoldOpen)}>
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            계약 보류
          </div>
          <div className='px-4 py-2 pb-6'>
            <div className='mt-4 bg-white'>
              <div className='w-full'>
                진행 과정에서 어떤 문제가 있으셨나요?
                <br />
                불편함이 있다면 카사요가 도와 드리겠습니다.
                <p className='text-[#6100FF] mt-4'>
                  언제든지 계약을 다시 진행할 수 있습니다.
                </p>
              </div>
              {/* <Select onValueChange={handleHoldSelectChange}>
                <SelectTrigger className='mt-4 border border-[#E5E5EC] focus:ring-0 focus:outline-none focus:!border-[#E5E5EC]'>
                  <SelectValue placeholder='카 매니저와의 갈등'>
                    {holdSelectedValue || '카 매니저와의 갈등'}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value='카 매니저와의 갈등'>
                    카 매니저와의 갈등
                  </SelectItem>
                  <SelectItem value='개인적인 사유'>개인적인 사유</SelectItem>
                  <SelectItem value='타사에서 구매 예정'>
                    타사에서 구매 예정
                  </SelectItem>
                  <SelectItem value='단순 변심'>단순 변심</SelectItem>
                </SelectContent>
              </Select> */}
            </div>
            <div className='px-4 mt-4 py-2 pb-6 border relative'>
              <Textarea
                className='h-[158px] border-0 p-2 focus:border-0'
                placeholder='보류 사유를 입력해주세요'
                value={holdReason}
                onChange={(e) => setHoldReason(e.target.value)}
                maxLength={1000}
              ></Textarea>
              <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                {holdReason ? holdReason.length : 0}/1000
              </div>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setHoldOpen(!isHoldOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.hold(
                quoteList ? quoteList[page - 1].id : '',
              )}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 재계약 팝업 */}
      <Dialog
        open={isRestartOpen}
        onOpenChange={() => setRestartOpen(!isRestartOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            재계약
          </div>
          <div className='px-4 py-2 pb-6'>
            <div className='mt-4 bg-white'>
              <div className='w-full'>
                다시 돌아오셨군요!
                <br />
                재계약으로 진행 하시겠습니까?
                <p className='text-[#7273F9] mt-4'>
                  재계약 진행 시 카 매니저분이 다시 연락드릴 예정입니다.
                </p>
              </div>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setRestartOpen(!isRestartOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.reStart(
                quoteList ? quoteList[page - 1].id : '',
              )}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 계약서 취소 팝업 */}
      <Dialog
        open={isCancelOpen}
        onOpenChange={() => setCancelOpen(!isCancelOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            계약 취소
          </div>
          <div className='px-4 py-2 pb-6'>
            <div className='mt-4 bg-white'>
              <div className='w-full'>
                진행 과정에서 어떤 문제가 있으셨나요?
                <br />
                불편함이 있다면 카사요가 도와 드리겠습니다.
                <p className='text-[#6100FF] mt-4'>
                  취소 사유를 선택하신 후 사유를 입력해주세요.
                </p>
              </div>
              {/* <Select onValueChange={handleCancelSelectChange}>
                <SelectTrigger className='mt-4 border border-[#E5E5EC] focus:ring-0 focus:outline-none focus:!border-[#E5E5EC]'>
                  <SelectValue placeholder='카 매니저와의 갈등'>
                    {cancelSelectedValue || '카 매니저와의 갈등'}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value='카 매니저와의 갈등'>
                    카 매니저와의 갈등
                  </SelectItem>
                  <SelectItem value='개인적인 사유'>개인적인 사유</SelectItem>
                  <SelectItem value='타사에서 구매 예정'>
                    타사에서 구매 예정
                  </SelectItem>
                  <SelectItem value='단순 변심'>단순 변심</SelectItem>
                </SelectContent>
              </Select> */}
            </div>
            <div className='px-4 mt-4 py-2 pb-6 border relative'>
              <Textarea
                className='h-[158px] border-0 p-2 focus:border-0'
                placeholder='취소 사유를 입력해주세요'
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                maxLength={1000}
              ></Textarea>
              <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                {cancelReason ? cancelReason.length : 0}/1000
              </div>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setCancelOpen(!isCancelOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.cancel(
                quoteList ? quoteList[page - 1].id : '',
              )}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 삭제 팝업 */}
      <Dialog
        open={isDeleteOpen}
        onOpenChange={() => setDeleteOpen(!isDeleteOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            주문 삭제
          </div>
          <div className='px-4 py-2 pb-6'>
            <div className='mt-4 bg-white'>
              <div className='w-full'>등록하신 주문을 삭제하시겠습니까?</div>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setDeleteOpen(!isDeleteOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.delete(
                quoteList ? quoteList[page - 1].id : '',
              )}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
