import UnderlineSketch from '../../../components/svg/UnderlineSketch';
import Expression from '../../../components/svg/Expression';
import DoubleQuote from '../../../components/svg/DoubleQuoteSvg';
import { useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';

const CharacterMain = () => {
  const navigate = useNavigate();
  // sectioin01 - Car Heroes
  const Section01 = () => {
    return (
      <section className='m-character01'>
        <div>
          <div>
            <h3>
              <span style={{ fontFamily: 'Times New Roman' }}>Car</span>
              <span style={{ fontFamily: 'Times New Roman' }}>Heroes Rise</span>
            </h3>
            <span className='active'>
              <UnderlineSketch fill='#FFE492' width='176' height='22' />
            </span>
            <span className={'star-effect'}>
              <img src='/assets/images/svg/star-effect.svg' alt='별모양' />
            </span>
          </div>
        </div>
        <div>
          <p>
            <span>그리스에 머물던 카리테스의 세 여신은</span>
            <span>사람들에게 헹복을 가져다주는 힘을 지니고 있었다.</span>
            <span>이들은 그리스에서 자기 할일을 마치고 </span>
            <span>카스토르 별에 가서 행복한 안식을 누리고 있었다.</span>
          </p>
          <p>
            <span>이들의 취미는 </span>
            <span>쌍둥이 엔진 자동차로 드라이브를 하는 것과 지구에서 </span>
            <span>그리스와 비슷한 성향의 나라를 찾는 것이었는데, </span>
            <span>그들이 찾은 곳은 바로</span>
            <span>K-POP으로 전 세계 문화에 지대한 영향을 끼치고 있는</span>
            <span>대한민국이었다.</span>
          </p>
          <p>
            <span>
              이들은 대한민국에서 들리는 사운드에 이끌려 세모 모양의 쌍둥이 엔진
              수퍼카 CARSAYO를 타고
            </span>
            <span>대한민국으로 오게 되는데.. </span>
            <span>
              CARSAYO는 빛의 속도로 이동해서 지구로 올때까지 52년이 걸렸다.
            </span>
            <span>
              누군가 자기들을 알아볼까 싶어 이름도 외모도 조금씩 바꾼 채....
            </span>
          </p>
        </div>
        <div>
          <img
            src='/assets/images/svg/m-section01-character01.svg'
            alt='카집사 이미지'
          />
          <img
            src='/assets/images/svg/m-section01-character02.svg'
            alt='카공이 이미지'
          />
          <img
            src='/assets/images/svg/m-section01-character03.svg'
            alt='카키 이미지'
          />
        </div>
      </section>
    );
  };
  // section02 - 카키
  const Section02 = () => {
    return (
      <section className='m-character02'>
        <div className='m-character-subTitle'>
          <h4>CHARACTER CONCEPT</h4>
          <span>캐릭터 컨셉</span>
        </div>
        <div className='m-character02__contents'>
          <div className='m-character02__contents__top'>
            <div className='mini-title'>#1 HEROES 2025</div>
            <p>카키</p>
          </div>

          <div className='m-character02__contents__middle'>
            <img
              src='/assets/images/svg/character-section02-img.svg'
              alt='카키 이미지'
            />
          </div>
          <div className='m-character02__contents__bottom'>
            <span className='double-quotes'>
              <DoubleQuote />
            </span>
            <p>
              <span>실수가 없고 깔끔한 일처리를 자랑하지만</span>
              <span>그만큼 예민하기도 하고</span>
              <span>엄격한 말투와 모습을 보이기도 한다.</span>
              <span>특히 싫어하는 것은 계획이 틀어지는 것.</span>
              <span>하지만 말투와 다르게 카키의 마음은 의외로 연약하다!</span>
              <span>본격적으로 계산에 들어가기 전</span>
              <span>안경을 치켜세우는 버릇이 있다.</span>
            </p>
          </div>
        </div>
        <Expression
          className='mb'
          textColor='#fff'
          clickEvent={() => {
            navigate('detail/01');
          }}
        />
      </section>
    );
  };
  // section03 - 카집사
  const Section03 = () => {
    return (
      <section className='m-character03'>
        <div className='m-character-subTitle'>
          <h4>CHARACTER CONCEPT</h4>
          <span>캐릭터 컨셉</span>
        </div>
        <div className='m-character03__contents'>
          <div className='m-character03__contents__top'>
            <div className='mini-title'>#2 HEROES 2025</div>
            <p>카집사</p>
          </div>

          <div className='m-character03__contents__middle'>
            <img
              src='/assets/images/svg/character-section03-img.svg'
              alt='카집사 이미지'
            />
          </div>
          <div className='m-character03__contents__bottom'>
            <span className='double-quotes'>
              <DoubleQuote fill={'#FF5B79'} />
            </span>
            <p>
              <span>방치되었던 자동차에 가장 먼저 반응을 한 것은</span>
              <span>카집사라고 한다.</span>
              <span>경청을 굉장히 잘하며 차집사의 예의바른 언행과</span>
              <span>살가운 태도에 몇번 만나지 않은 사람도 금새 편안해져 </span>
              <span>상담을 요청하게 된다. 항상 존대말을 사용하며 깔끔하게</span>
              <span>관리된 머리와 수염을 유지하는데</span>
              <span>이는 자신을 마주하는 사람들에 대한 예의라고 한다.</span>
            </p>
          </div>
        </div>
        <Expression
          className='mb'
          textColor='#fff'
          fill='#ff5b79'
          clickEvent={() => {
            navigate('detail/02');
          }}
        />
      </section>
    );
  };
  // section04 - 카공이
  const Section04 = () => {
    return (
      <section className='m-character04'>
        <div className='m-character-subTitle'>
          <h4>CHARACTER CONCEPT</h4>
          <span>캐릭터 컨셉</span>
        </div>
        <div className='m-character04__contents'>
          <div className='m-character04__contents__top'>
            <div className='mini-title'>#3 HEROES 2025</div>
            <p>카공이</p>
          </div>

          <div className='m-character04__contents__middle'>
            <img
              src='/assets/images/svg/character-section04-img.svg'
              alt='카공이 이미지'
            />
          </div>
          <div className='m-character04__contents__bottom'>
            <span className='double-quotes'>
              <DoubleQuote fill={'#A7DE93'} />
            </span>
            <p>
              <span>차와 대화가 가능해</span>
              <span>문제가 무엇인지 금방 파악할 수 있다고 한다.</span>
              <span>차량 관리에 부담을 느끼는 고객들의 부담감을 덜어주는</span>
              <span>듬직하고 큰 덩치를 가지고 있으며 시원하고</span>
              <span>털털한 성격으로 수다 떠는 것을 좋아하는데,</span>
              <span>
                카공이 앞에서 섣불리 엔진 등에 대한 이야기를 꺼냈다가는
              </span>
              <span>날밤을 샐 수도 있다고 하니 조심!</span>
            </p>
          </div>
        </div>
        <Expression
          className='mb'
          textColor='#303056'
          fill='#A7DE93'
          clickEvent={() => {
            navigate('detail/03');
          }}
        />
      </section>
    );
  };
  //   section05 - Character Application
  const Section05 = () => {
    return (
      <section className='m-character05'>
        <div className='m-character-subTitle'>
          <h4>CHARACTER APPLICATION</h4>
          <span>캐릭터 모음</span>
        </div>
        <ul>
          <li>
            <img
              src='/assets/images/svg/character_section05_img01.svg'
              alt='카사요히어로즈이미지1'
            />
          </li>
          <li>
            <img
              src='/assets/images/svg/character_section05_img02.svg'
              alt='카사요히어로즈이미지2'
            />
          </li>
          <li>
            <img
              src='/assets/images/svg/character_section05_img03.svg'
              alt='카사요히어로즈이미지3'
            />
          </li>
        </ul>
      </section>
    );
  };

  useLayoutEffect(() => {
    const goBackHome = () => {
      navigate('/main');
    };
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', goBackHome);
    return () => window.removeEventListener('popstate', goBackHome);
  }, [navigate]);

  return (
    <div className='m-content'>
      <Section01 />
      <Section02 />
      <Section03 />
      <Section04 />
      <Section05 />
    </div>
  );
};
export default CharacterMain;
