/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import ArrowTop from 'assets/images/v2/arrowTop.svg';
import classNames from 'classnames';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerClose,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import NofiIcon from 'assets/images/v2/nofiIcon.svg';
import CarsayoImg from 'assets/images/v2/Carsayo.svg';
import PrevIcon from 'assets/images/v2/arrow-left.svg';
import NextIcon from 'assets/images/v2/arrow-right.svg';
// 스와이퍼관련
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { NavigationOptions } from 'swiper/types/modules/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParams } from 'react-router-dom';
import { getCustomerQuoteDetailApi, selectManagerApi } from 'apis/quote';
import {
  DutyFreeConditionType,
  convertBoughtType,
  convertBuyType,
  convertDealerType,
  convertOrderStatus,
  dateToStringHHMM,
  dateToStringMMDD,
  slicePrice,
  stringDateAddDot,
} from 'utils/convert';
import { parseOrderProcessState, parseSigunguId } from 'utils/typeConvert';
import Confirm from 'components/ui/confirm';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import SellingAccidentHistoryDetail from 'pages/v2/selling/detail/insurance';
import dayjs from 'dayjs';
import Cancel from 'components/view/quotation/cancel';
import download from 'utils/download';
import ReactHtmlParser from 'react-html-parser';
import SellingCarEstimatePrice from 'pages/v2/selling/detail/price';
import getPurchasePrice from 'utils/price/getPurchasePrice';
import useOnResumeStore from 'store/useOnResumeStore';
import { cn } from 'utils';
import { CustomerOrderDetail } from '@carsayo/types';

const Star = ({ fill }: { fill: string }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.14809 2.11375L7.02064 4.928H9.8424C9.99449 4.928 10.0585 5.13444 9.93445 5.22712L7.65303 6.96707L8.52557 9.78132C8.5736 9.93299 8.4055 10.0636 8.28142 9.96669L6 8.22674L3.71858 9.96669C3.5945 10.0594 3.42639 9.93299 3.47442 9.78132L4.34697 6.96707L2.06554 5.22712C1.94147 5.13444 2.00551 4.928 2.1576 4.928H4.97936L5.85191 2.11375C5.89994 1.96208 6.10006 1.96208 6.14809 2.11375Z'
        fill={fill}
        stroke={fill}
        strokeLinejoin='round'
      />
    </svg>
  );
};
const StarRating = ({ rating }: { rating: number }) => {
  return (
    <div className='flex'>
      {[...Array(5)].map((_, index) => (
        <Star key={index} fill={index < rating ? '#111111' : '#E5E5EC'} />
      ))}
    </div>
  );
};

const QuoteBoxDetail = () => {
  const { id } = useParams();
  const { setTopTabbarTitle, setTopTabbarState } = useSystemStore();
  const { setOnResume } = useOnResumeStore();
  const [isQuoteBoxOpen, setQuoteBoxOpen] = useState(true);
  const [currentInfo, setCurrentInfo] = useState<'model' | 'buy'>('model');
  const [noColor, setNoColor] = useState(true);
  const [isFloatingOpen, setFloatingOpen] = useState(false);
  const [isUsedCarPopupOpen, setUsedCarPopupOpen] = useState(false);
  const [isChoiceOpen, setChoiceOpen] = useState(false);
  const [selectManager, setSelectManager] = useState<string>();
  const [isCancel, setIsCancel] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isExpectedOpen, setExpectedOpen] = useState(false);
  SwiperCore.use([Navigation]);
  // 견적상세
  const [quoteDetail, setQuoteDetail] = useState<CustomerOrderDetail>();

  // const actions = [
  //   {
  //     label: '계약',
  //     icon: <PaperIcon />,
  //     url: () => {},
  //   },
  //   {
  //     label: '계약 취소',
  //     icon: <TrashCanIcon />,
  //     url: () => {},
  //   },
  //   {
  //     label: '계약 보류',
  //     icon: <PencilIcon />,
  //     url: () => {},
  //   },
  //   {
  //     label: '담당 결정',
  //     icon: <CalculatorIcon />,
  //     url: () => {},
  //   },
  // ];
  const navigationOptions: NavigationOptions | boolean = useMemo(() => {
    return {
      prevEl: '.mySwiper-prev',
      nextEl: '.mySwiper-next',
      disabledClass: '!bg-[#00000033] !cursor-default',
    };
  }, []);

  const handleVoidEvents = {
    getQuoteDetail: async () => {
      try {
        const result = await getCustomerQuoteDetailApi(id as string);
        if (result) {
          setQuoteDetail(result);
          console.info(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    },
  };
  const handleClickEvents = {
    openTooltip: () => {
      setTooltipVisible(!isTooltipVisible);
    },
    quoteBoxOpen: () => {
      setQuoteBoxOpen(!isQuoteBoxOpen);
    },
    choiceManager: async () => {
      if (selectManager && id) {
        const reqData = {
          orderId: id,
          managerId: selectManager,
        };
        try {
          const result = await selectManagerApi(reqData);
          if (result) {
            CarsayoToast.text(`${result.manager?.name_nick}님이 선택됐습니다.`);
            setQuoteDetail(result);
            handleVoidEvents.getQuoteDetail();
          }
        } catch (error: any) {
          console.error(error);
        }
      }
    },
    fileOpen: (e: React.MouseEvent<HTMLDivElement>, url: string | null) => {
      if (url) {
        e.preventDefault();
        window.open(url, '_blank');
      } else {
        return;
      }
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setTopTabbarTitle('상세보기');
    if (id) {
      handleVoidEvents.getQuoteDetail();
    }
  }, []);

  useEffect(() => {
    setOnResume(async () => {
      try {
        const result = await getCustomerQuoteDetailApi(id as string);
        if (result) {
          setQuoteDetail(result);
          console.info(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    });
  }, []);

  return (
    <>
      <div className='bg-white px-4'>
        {/* 상단 매니저 정보, 기본 정보 */}
        <div className='w-full pt-8'>
          {quoteDetail?.processCardList &&
          quoteDetail.processCardList.length > 0 ? (
            <div className='w-full flex flex-row justify-between items-stretch border-b-[1px] border-[#E5E5EC] pb-4'>
              {/* 상단 낙찰 매니저 정보 */}
              <div className='w-full flex flex-row gap-6 justify-start items-stretch'>
                <img
                  src={
                    quoteDetail?.manager?.profileImage_url
                      ? quoteDetail.manager.profileImage_url
                      : CarsayoImg
                  }
                  alt='프로필 이미지'
                  className='w-[12.8vw] h-[12.8vw] object-fill rounded-full'
                />
                <div className='flex flex-col justify-start items-start gap-[8px]'>
                  <p className='text-[#767676]'>
                    {quoteDetail?.manager?.name_nick ? (
                      quoteDetail.manager.name_nick
                    ) : (
                      <span className='text-[#999999]'>닉네임 없음</span>
                    )}
                  </p>
                  <p className='text-[#111111] font-[600]'>
                    <span>
                      {quoteDetail?.manager?.name_real
                        ? quoteDetail.manager.name_real === '비공개'
                          ? '비공개'
                          : `${quoteDetail.manager.name_real}님`
                        : '이름 없음'}
                    </span>
                  </p>
                </div>
              </div>
              <div className='py-[14px] min-w-[80px] max-h-[50px] max-w-[90px] px-[16px] w-[20vw] flex justify-center items-center bg-[#0075FF] rounded-[6px] text-white font-[500]'>
                {quoteDetail?.state
                  ? convertOrderStatus[quoteDetail.state]
                  : '없음'}
              </div>
            </div>
          ) : (
            <div className='w-full flex justify-between items-center border-b-[1px] border-[#E5E5EC] pb-4'>
              {/* 상단 낙찰 전 상태값 */}
              <div className='w-full flex flex-row gap-6 justify-start items-center'>
                <img
                  src='https://cdn.carsayo.net/resource/image/profileImage_default_1.svg'
                  alt='샘플 프로필 이미지'
                  className='w-[12.8vw] h-[12.8vw] object-fill rounded-full'
                />
                <div className='flex flex-col justify-start items-start gap-[8px]'>
                  <p className='text-[#767676] text-[15px]'>
                    담당 매니저가 없습니다
                  </p>
                </div>
              </div>
              <div className='py-[14px] min-w-[74px] px-[16px] w-[20vw] flex justify-center items-center bg-[#0075FF] rounded-[6px] text-white font-[500]'>
                {quoteDetail?.state
                  ? convertOrderStatus[quoteDetail?.state]
                  : '낙찰 전'}
              </div>
            </div>
          )}
          {/* 구매유형 박스 */}
          {quoteDetail?.type !== 'sellCar' && (
            <div className='w-full pt-4 pb-0'>
              <ul className='w-full flex flex-col justify-start items-start'>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>주문번호</p>
                  <p className='text-[#111111]'>{quoteDetail?.code}</p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>유형</p>
                  <p className='text-[#111111]'>
                    {quoteDetail ? (
                      (quoteDetail?.purchase &&
                        quoteDetail.purchase?.purchaseInfo?.method &&
                        quoteDetail.purchase?.purchaseInfo.method) ??
                      '없음'
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                {quoteDetail?.purchase?.purchaseInfo.method &&
                  quoteDetail.purchase.purchaseInfo.method !== '일시불' && (
                    <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                      <p className='text-[#767676] min-w-[23vw]'>
                        {quoteDetail.purchase.purchaseInfo?.할부
                          ? '할부기간'
                          : '계약기간'}
                      </p>
                      <p className='text-[#111111]'>
                        {quoteDetail.purchase.purchaseInfo?.리스 ? (
                          `${quoteDetail.purchase.purchaseInfo.리스.contractTerm}개월`
                        ) : quoteDetail.purchase.purchaseInfo?.렌트 ? (
                          `${quoteDetail.purchase.purchaseInfo.렌트.contractTerm}개월`
                        ) : quoteDetail.purchase.purchaseInfo?.할부 ? (
                          `${quoteDetail.purchase.purchaseInfo.할부.installmentTerm}개월`
                        ) : (
                          <span className='text-[#999999]'>없음</span>
                        )}
                      </p>
                    </li>
                  )}
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>차량가격</p>
                  <div className='text-[#111111] flex justify-between items-center gap-[10px] w-full pr-2'>
                    <span>
                      {quoteDetail?.price ? (
                        slicePrice(quoteDetail.price)
                      ) : (
                        <span className='text-[#999999]'>구매 계약서 확인</span>
                      )}
                    </span>
                    <div
                      className={cn(
                        'relative',
                        !quoteDetail?.price && 'hidden',
                      )}
                    >
                      <div
                        className='w-[20px] h-[20px] cursor-pointer'
                        onClick={() => setExpectedOpen(true)}
                      >
                        <img
                          src={NofiIcon}
                          alt='알림아이콘'
                          className='object-contain w-[20px] h-[20px]'
                        />
                      </div>
                    </div>
                  </div>
                </li>
                {(quoteDetail?.purchase?.purchaseInfo.method === '일시불' ||
                  quoteDetail?.purchase?.purchaseInfo.method === '할부') && (
                  <>
                    <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                      <p className='text-[#767676] min-w-[23vw]'>거주지역</p>
                      <p className='text-[#111111]'>
                        {quoteDetail.purchase.purchaseInfo.method === '일시불'
                          ? quoteDetail.purchase.purchaseInfo.일시불
                              ?.residenceRegion
                            ? `${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .residenceRegion,
                                )?.sido.name ?? ''
                              } ${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .residenceRegion,
                                )?.name ?? ''
                              }`
                            : '없음'
                          : quoteDetail.purchase.purchaseInfo.method ===
                                '할부' &&
                              quoteDetail.purchase.purchaseInfo.할부
                                ?.residenceRegion
                            ? `${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .residenceRegion,
                                )?.sido.name ?? ''
                              } ${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .residenceRegion,
                                )?.name ?? ''
                              }`
                            : '없음'}
                      </p>
                    </li>
                    <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                      <p className='text-[#767676] min-w-[23vw]'>인수지역</p>
                      <p className='text-[#111111]'>
                        {quoteDetail.purchase.purchaseInfo.method === '일시불'
                          ? quoteDetail.purchase.purchaseInfo.일시불
                              ?.deliveryRegion
                            ? `${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .deliveryRegion,
                                )?.sido.name ?? ''
                              } ${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .deliveryRegion,
                                )?.name ?? ''
                              }`
                            : '없음'
                          : quoteDetail.purchase.purchaseInfo.method ===
                                '할부' &&
                              quoteDetail.purchase.purchaseInfo.할부
                                ?.deliveryRegion
                            ? `${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .deliveryRegion,
                                )?.sido.name ?? ''
                              } ${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .deliveryRegion,
                                )?.name ?? ''
                              }`
                            : '없음'}
                      </p>
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
          {/* 팔기유형 박스 */}
          {quoteDetail?.type === 'sellCar' && (
            <div className='w-full pt-4 pb-0'>
              <ul className='w-full flex flex-col justify-start items-start'>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>주문번호</p>
                  <p className='text-[#111111]'>{quoteDetail?.code}</p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>유형</p>
                  <p className='text-[#111111]'>
                    {quoteDetail?.type ? (
                      convertDealerType[quoteDetail.type]
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>시세조회</p>
                  <div className='text-[#111111] flex justify-between items-center gap-[10px] w-full pr-2'>
                    <span>
                      {quoteDetail?.price ? (
                        slicePrice(quoteDetail.price)
                      ) : (
                        <span className='text-[#999999]'>없음</span>
                      )}
                    </span>
                    <div className='relative'>
                      <div
                        className='w-[20px] h-[20px] cursor-pointer'
                        onClick={handleClickEvents.openTooltip}
                      >
                        <img
                          src={NofiIcon}
                          alt='알림아이콘'
                          className='object-contain w-[20px] h-[20px]'
                        />
                      </div>
                      {/* 차량 가격 확인하기 */}
                      {quoteDetail.selling && (
                        <SellingCarEstimatePrice
                          isOpen={isTooltipVisible}
                          setIsOpen={setTooltipVisible}
                          sellingOrderInfo={quoteDetail.selling}
                          mode={'info'}
                        />
                      )}
                      {/* <div
                        id='price_tooltip'
                        className={classNames(
                          isTooltipVisible
                            ? 'flex justify-start translate-x-[0px] items-center opacity-1 !text-[12px] z-[40] !rounded-[10px] !bg-[#111111] !border-[#111111]'
                            : 'opacity-0 translate-x-[50px] !text-transparent z-[-999]',
                          'transition-all !duration-300 w-[120px] h-auto min-h-[60px] absolute p-2 top-0 bottom-0 my-auto right-[25px] text-[#fff]  shadow-sm',
                        )}
                      >
                        예상시세는 실제가격과 달라질 수 있습니다.
                      </div> */}
                    </div>
                  </div>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>판매지역</p>
                  <p className='text-[#111111]'>
                    {quoteDetail?.selling?.sellRegionId ? (
                      `${
                        parseSigunguId(quoteDetail.selling.sellRegionId)?.sido
                          .name ?? ''
                      } ${
                        parseSigunguId(quoteDetail.selling.sellRegionId)
                          ?.name ?? ''
                      }`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* 견적 상태 타임라인 */}
        <div className='w-full py-4 mb-[12px]'>
          {/* 견적 타임라인 */}
          <div
            className={classNames(
              'transition-all',
              isQuoteBoxOpen
                ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
                : 'h-0 opacity-0 translate-y-[50px] z-[-100] pointer-events-none fixed',
            )}
          >
            <div className='w-full py-4'>
              <ul className='w-full flex flex-col gap-4 justify-start items-start'>
                {/* 카드 박스 - 낙찰 된 후 타임라인 */}
                {quoteDetail?.processCardList &&
                  quoteDetail.processCardList.length > 0 &&
                  quoteDetail.processCardList.map((item, index) => {
                    return (
                      <li
                        key={`processCard_${index}`}
                        className='w-full flex flex-col justify-start items-start gap-4 text-[#111111]'
                      >
                        {/* 카드 상단 */}
                        <div className='w-full flex flex-row justify-between items-center'>
                          <div className='flex flex-row items-center gap-[5px]'>
                            <span className='bg-[#111111] py-[2px] px-1 text-white text-[11px] leading-[16px]'>
                              {/* {index + 1 < 10 ? `0${index + 1}` : index + 1} */}
                              {quoteDetail.processCardList.length - index < 10
                                ? `0${quoteDetail.processCardList.length - index}`
                                : quoteDetail.processCardList.length - index}
                            </span>
                            <p className='text-[15px]'>{item.title}</p>
                          </div>
                          <div className='flex flex-row items-center gap-1'>
                            <span
                              className={classNames(
                                'min-w-[27px] py-[2px] px-[4px] border text-[11px] tracking-[-0.28px]',
                                item.state &&
                                  `!border-[${parseOrderProcessState(item.state).color}] !text-[${parseOrderProcessState(item.state).color}]`,
                              )}
                            >
                              {item.state && item.created_at
                                ? `${parseOrderProcessState(item.state).text} ${dateToStringMMDD(item.created_at)} ${dateToStringHHMM(item.created_at)}`
                                : '상태없음'}
                            </span>
                          </div>
                        </div>
                        {/* 카드 중앙 */}
                        <div className='w-full bg-[#F7F7FB] p-4 flex flex-col gap-[12px] '>
                          {(item.file_1 || item.file_2 || item.file_3) && (
                            <ul>
                              {item.file_1 && (
                                <li className='w-full'>
                                  <div
                                    className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                    onClick={() => {
                                      item.file_1 !== null
                                        ? download(item.file_1)
                                        : null;
                                    }}
                                  >
                                    {item.file_1.name}
                                  </div>
                                </li>
                              )}
                              {item.file_2 && (
                                <li className='w-full'>
                                  <div
                                    className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                    onClick={() => {
                                      item.file_2 !== null
                                        ? download(item.file_2)
                                        : null;
                                    }}
                                  >
                                    {item.file_2.name}
                                  </div>
                                </li>
                              )}
                              {item.file_3 && (
                                <li className='w-full'>
                                  <div
                                    className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                    onClick={() => {
                                      item.file_3 !== null
                                        ? download(item.file_3)
                                        : null;
                                    }}
                                  >
                                    {item.file_3.name}
                                  </div>
                                </li>
                              )}
                            </ul>
                          )}
                          {(item.additionalInfo_1 ||
                            item.additionalInfo_2 ||
                            item.additionalInfo_3) && (
                            <ul>
                              {item.additionalInfo_1 && (
                                <li>
                                  <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-stretch'>
                                    <span className='text-[#767676] max-w-[50%]'>
                                      {item.additionalInfo_1.title}
                                    </span>
                                    <strong className='max-w-[50%] whitespace-pre-wrap'>
                                      {item.additionalInfo_1.content
                                        ? ReactHtmlParser(
                                            item.additionalInfo_1.content,
                                          )
                                        : '없음'}
                                    </strong>
                                  </div>
                                </li>
                              )}
                              {item.additionalInfo_2 && (
                                <li>
                                  <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-center'>
                                    <span className='text-[#767676] max-w-[50%]'>
                                      {item.additionalInfo_2.title}
                                    </span>
                                    <strong className='max-w-[50%] whitespace-pre-wrap'>
                                      {item.additionalInfo_2.content
                                        ? ReactHtmlParser(
                                            item.additionalInfo_2.content,
                                          )
                                        : '없음'}
                                    </strong>
                                  </div>
                                </li>
                              )}
                              {item.additionalInfo_3 && (
                                <li>
                                  <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-center'>
                                    <span className='text-[#767676] max-w-[50%]'>
                                      {item.additionalInfo_3.title}
                                    </span>
                                    <strong className='max-w-[50%] whitespace-pre-wrap'>
                                      {item.additionalInfo_3.content
                                        ? ReactHtmlParser(
                                            item.additionalInfo_3.content,
                                          )
                                        : '없음'}
                                    </strong>
                                  </div>
                                </li>
                              )}
                            </ul>
                          )}
                          <p className='whitespace-pre-wrap w-full text-left text-[15px] leading-[22px]'>
                            {item.content
                              ? ReactHtmlParser(item.content)
                              : '견적 내용 없음'}
                          </p>
                        </div>
                        {/* 카드 하단 */}
                        {/* {item.state === 'accepted' && (
                          <div className='w-full text-[#111111] border border-[#E5E5EC] py-4 px-2 flex flex-row justify-start items-center gap-2'>
                            <span className='w-[16px] h-[16px] flex justify-center items-center mr-1'>
                              <SoundIcon />
                            </span>
                            {quoteDetail.type === 'sellCar'
                              ? '미팅 날짜가 잡히면 다음 단계로 진행됩니다.'
                              : '계약서가 준비되면 곧 업데이트됩니다.'}
                          </div>
                        )}
                        {item.state === 'progressing' && (
                          <div className='w-full flex gap-[33px]'>
                            <div
                              className='flex h-9 grow items-center justify-center border text-desc'
                              onClick={() => setIsCancel(true)}
                            >
                              취소
                            </div>
                            <div
                              className='flex h-9 grow items-center justify-center border text-desc'
                              onClick={() => setIsCancel(true)}
                            >
                              보류
                            </div>
                          </div>
                        )}
                        {item.state === 'holded' && (
                          <div className='w-full flex gap-[33px]'>
                            <div
                              className='flex h-9 grow items-center justify-center border text-desc'
                              onClick={() => setIsCancel(true)}
                            >
                              재계약
                            </div>
                          </div>
                        )} */}
                      </li>
                    );
                  })}
                {/* 견적서 있을 때 타임라인(낙찰 전 입찰리스트) */}
                {quoteDetail?.bidList &&
                  quoteDetail?.bidList.length > 0 &&
                  quoteDetail?.processCardList.length === 0 && (
                    <div className='w-full'>
                      <p className='text-[18px] font-[600]'>참여 카 매니저</p>
                      {quoteDetail.bidList.map((item, index) => {
                        return (
                          <div
                            className='w-full bg-white px-0 py-6'
                            key={`bidderData_${index}`}
                          >
                            <div className='flex gap-2 border-b pb-4'>
                              <div className='flex h-[50px] w-[50px] items-center justify-center overflow-hidden rounded-full'>
                                {item.dealer.profileImage_url ? (
                                  <img
                                    src={item.dealer.profileImage_url}
                                    alt={item.dealer.name_nick}
                                  />
                                ) : (
                                  <img
                                    src='/assets/images/v2/Ellipse.png'
                                    alt='딜러프로필샘플이미지'
                                  />
                                )}
                              </div>
                              <div className='flex-1'>
                                <div className='flex items-center justify-between'>
                                  <div className='text-desc text-[#767676]'>
                                    카 매니저
                                  </div>
                                  <div className='flex items-center gap-1'>
                                    <div className='flex items-center'>
                                      <StarRating rating={4} />
                                    </div>
                                    <div className='text-desc'>4.0</div>
                                  </div>
                                </div>
                                <div className='mt-2.5'>
                                  {item.dealer.name_nick ?? '닉네임 없음'}
                                </div>
                              </div>
                            </div>
                            <div className='flex justify-between items-center gap-1 py-4 text-title'>
                              <div className='flex flex-row items-center gap-[5px]'>
                                <div className='flex h-[22px] w-5 items-center justify-center bg-[#111111] text-[11px] text-white'>
                                  {/* {index + 1 < 10 ? `0${index + 1}` : index + 1} */}
                                  {quoteDetail.bidList.length - index < 10
                                    ? `0${quoteDetail.bidList.length - index}`
                                    : quoteDetail.bidList.length - index}
                                </div>
                                <div className='text-[#767676]'>견적서</div>
                              </div>
                              <div className='flex flex-row items-center gap-1'>
                                <span
                                  className={classNames(
                                    'min-w-[27px] py-[2px] px-[4px] border text-[11px] tracking-[-0.28px]',
                                    item.state &&
                                      `!border-[${parseOrderProcessState(item.state).color}] !text-[${parseOrderProcessState(item.state).color}]`,
                                  )}
                                >
                                  {item.state && item.created_at
                                    ? `${parseOrderProcessState(item.state).text} ${dateToStringMMDD(item.created_at)} ${dateToStringHHMM(item.created_at)}`
                                    : '상태없음'}
                                </span>
                              </div>
                            </div>
                            <div className='bg-[#F7F7FB] p-4 text-title'>
                              {item.estimateFile && (
                                <div
                                  className='mb-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                  onClick={() => {
                                    item.estimateFile !== null
                                      ? download(item.estimateFile)
                                      : null;
                                  }}
                                >
                                  견적서 보기
                                </div>
                              )}
                              {item.purchasePrice && (
                                <div className='mb-3 flex h-12 items-center justify-between border border-[#E5E5EC] bg-white px-4 text-[#999999]'>
                                  <p className='text-[#767676] min-w-[12vw]'>
                                    매입가격
                                  </p>
                                  <p className='text-[#111111]'>
                                    {item.purchasePrice ? (
                                      `${item.purchasePrice.toLocaleString()}원`
                                    ) : (
                                      <span className='text-[#999999]'>
                                        없음
                                      </span>
                                    )}
                                  </p>
                                </div>
                              )}
                              <div className='whitespace-pre-wrap w-full mb-3'>
                                {item.content
                                  ? ReactHtmlParser(item.content)
                                  : '내용 없음'}
                              </div>
                              <div
                                className='flex h-12 items-center justify-center bg-[#7273F9] font-medium text-white'
                                onClick={() => {
                                  setSelectManager(item.dealer.id);
                                  setChoiceOpen(!isChoiceOpen);
                                }}
                              >
                                이 카매니저와 진행하고 싶어요
                              </div>
                              <Confirm
                                isOpen={isChoiceOpen}
                                setIsOpen={setChoiceOpen}
                                isCloseButton={true}
                                onConfirm={handleClickEvents.choiceManager}
                                title='담당 카 매니저 결정'
                                desc={`선택하신 카 매니저분과 진행을 하시겠습니까?`}
                              ></Confirm>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                {/* 등록된 입찰이 없을 때 타임라인 */}
                {(quoteDetail?.processCardList === undefined ||
                  quoteDetail?.processCardList.length === 0) &&
                  (quoteDetail?.bidList === undefined ||
                    quoteDetail?.bidList.length === 0) && (
                    <li className='w-full flex flex-col justify-start items-start gap-4 text-[#111111]'>
                      <div className='w-full text-center bg-[#F7F7FB] p-4 text-[15px] text-[#767676]'>
                        등록된 입찰이 없습니다.
                      </div>
                    </li>
                  )}
              </ul>
            </div>
          </div>
          {/* 견적 타임라인 여 닫는 버튼 */}
          <div className='w-full border-t-[1px] mt-4 border-[#E5E5EC] relative'>
            <button
              className='shadow-[0px_4px_10px_#0000000f] absolute left-0 right-0 mx-auto top-[-20px] p-[10px] rounded-full border border-[#E5E5EC] w-[40px] inline-flex justify-center items-center bg-white'
              onClick={handleClickEvents.quoteBoxOpen}
            >
              <img
                src={ArrowTop}
                alt='닫기버튼'
                className={classNames(
                  'transition-all',
                  isQuoteBoxOpen ? 'rotate-[0deg]' : 'rotate-[180deg]',
                )}
              />
            </button>
          </div>
        </div>

        {/* 추가 요청사항 있을 시 추가 요청사항 */}
        {quoteDetail?.purchase?.additionalRequest && (
          <div className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2'>
            <p className='text-[#767676] min-w-[23vw]'>추가 요청사항</p>
            <div className='text-[#111111] pb-4 w-full'>
              <div className='whitespace-pre-wrap w-full text-[#111111] bg-[#F7F7FB] p-4 flex flex-col gap-[12px] justify-start items-start'>
                {ReactHtmlParser(quoteDetail.purchase.additionalRequest)}
              </div>
            </div>
          </div>
        )}
        {/* 타사 견적서 */}
        {(quoteDetail?.purchase?.otherCompanyEstimateContent ||
          quoteDetail?.purchase?.otherCompanyEstimateFile) && (
          <div className='w-full flex flex-col gap-[3vw] justify-start items-start py-2'>
            <p className='text-[#767676] min-w-[23vw]'>
              {quoteDetail?.purchase.isSuperCar !== true
                ? '타사 견적서'
                : '구매 계약서'}
            </p>
            <div className='text-[#111111] pb-4 w-full'>
              <div className='whitespace-pre-wrap w-full text-[#111111] bg-[#F7F7FB] p-4 flex flex-col gap-[12px] justify-start items-start'>
                {quoteDetail?.purchase.isSuperCar !== true &&
                  quoteDetail?.purchase?.otherCompanyEstimateContent && (
                    <span className='w-full'>
                      {ReactHtmlParser(
                        quoteDetail.purchase.otherCompanyEstimateContent,
                      )}
                    </span>
                  )}
                {quoteDetail?.purchase?.otherCompanyEstimateFile && (
                  <div
                    className='w-full cursor-pointer flex h-12 items-center justify-center border border-[#111111] bg-white'
                    onClick={
                      quoteDetail.purchase.otherCompanyEstimateFile
                        ? () => {
                            quoteDetail.purchase &&
                            quoteDetail.purchase.otherCompanyEstimateFile !==
                              null
                              ? download(
                                  quoteDetail.purchase.otherCompanyEstimateFile,
                                )
                              : null;
                          }
                        : () => {}
                    }
                  >
                    {quoteDetail.purchase.otherCompanyEstimateFile.name}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* 구매유형 상세 정보 박스 */}
        {quoteDetail?.type !== 'sellCar' && (
          <div className='w-full'>
            {/* 상세 정보 탭 메뉴 */}
            <ul className='w-full flex justify-evenly items-center'>
              <li
                className={`w-full cursor-pointer transition-all text-[16px] font-[500] py-[0.875rem] px-4 text-center border-b-[1px] box-border ${currentInfo === 'model' ? 'border-[#111111] text-[#111111]' : 'border-[#E5E5EC] text-[#767676]'}`}
                onClick={() => setCurrentInfo('model')}
              >
                <span>모델정보</span>
              </li>
              <li
                className={`w-full cursor-pointer transition-all text-[16px] font-[500] py-[0.875rem] px-4 text-center border-b-[1px] box-border ${currentInfo === 'buy' ? 'border-[#111111] text-[#111111]' : 'border-[#E5E5EC] text-[#767676]'}`}
                onClick={() => setCurrentInfo('buy')}
              >
                <span>구매정보</span>
              </li>
            </ul>
            {/* 상세 정보 - 모델정보 박스 */}
            <div
              className={classNames(
                'transition-all py-[24px]',
                currentInfo === 'model'
                  ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
                  : 'h-0 opacity-0 translate-y-[20px] z-[-100] pointer-events-none fixed',
              )}
            >
              <ul className='w-full flex flex-col justify-start items-stretch gap-2'>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>브랜드</p>
                  <p className='text-[#111111]'>
                    {quoteDetail?.brand ?? '없음'}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-stretch py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>모델</p>
                  <p className='text-[#111111]'>
                    {quoteDetail?.brand ? (
                      <span className='mr-[3px]'>{quoteDetail.brand}</span>
                    ) : (
                      ''
                    )}
                    {quoteDetail?.title ?? '모델정보 없음'}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-stretch py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>상세등급</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail?.purchase?.selectedCar?.carGrade?.name ??
                      '없음'}
                  </p>
                </li>
                <li className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2 border-b-[1px] border-dashed border-[#E5E5EC]'>
                  <p className='text-[#767676] min-w-[23vw]'>색상</p>
                  <div className='text-[#111111] flex flex-col justify-start items-start gap-[10px] pl-2 w-full'>
                    {quoteDetail?.purchase?.selectedCar?.carColor ? (
                      <ul className='w-full flex flex-col justify-start items-stretch gap-2'>
                        <li className='w-full flex flex-row gap-[5.5vw] justify-between items-center py-2 pr-[5vw]'>
                          <p className='text-[#767676] min-w-[23vw]'>
                            {quoteDetail.purchase.selectedCar.carColor.name}
                          </p>
                          <p className='text-[#111111]'>
                            <span className='mr-[2px]'>
                              {quoteDetail.purchase.selectedCar.carColor.price >
                              0
                                ? `+ ${quoteDetail.purchase.selectedCar.carColor.price.toLocaleString()}`
                                : 0}
                            </span>
                            원
                          </p>
                        </li>
                      </ul>
                    ) : (
                      <label
                        htmlFor='mystery_shopper_check'
                        className={classNames(
                          'relative block pl-[30px] pb-4',
                          'after:absolute after:left-0 after:top-0',
                          'after:w-[20px] after:h-[20px] after:border after:text-center',
                          `${noColor ? 'after:content-["✓"] after:text-[#CACAD7] text-[15px] after:border-[#E5E5EC] after:bg-[#F1F1F5]' : 'after:content-[""] after:border-[#E5E5EC] after:bg-[#F1F1F5]'}`,
                        )}
                      >
                        <input
                          type='checkbox'
                          id='mystery_shopper_check'
                          className='hidden disabled:pointer-events-none'
                          checked={noColor}
                          onChange={() => {}}
                          disabled={true}
                        />
                        <span className='w-full text-[14px] text-[#111111]'>
                          색상은 상담 시 결정합니다.
                        </span>
                      </label>
                    )}
                  </div>
                </li>
                <li className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2  '>
                  <p className='text-[#767676] min-w-[23vw]'>추가옵션</p>
                  <div className='text-[#111111] flex flex-col justify-start items-start gap-[10px] w-full pb-[5vh]'>
                    {quoteDetail?.purchase?.selectedCar?.carOptionList &&
                    quoteDetail.purchase.selectedCar.carOptionList.length >
                      0 ? (
                      <ul className='w-full flex flex-col justify-start items-start'>
                        {quoteDetail.purchase.selectedCar.carOptionList.map(
                          (item, index) => {
                            return (
                              <li
                                key={`${item.id}_${index}`}
                                className={`w-full flex flex-row gap-[5vw] pl-2 justify-between items-center border-b-[1px] border-dashed border-[#E5E5EC] ${index === 0 ? 'pb-4' : 'py-4'}`}
                              >
                                <p className='text-[#767676]'>
                                  {item.name ?? '없음'}
                                </p>
                                <p className='text-[#111111] min-w-[33%] text-right pr-[5vw]'>
                                  {item.price
                                    ? `+ ${item.price.toLocaleString()}원`
                                    : '0원'}
                                </p>
                              </li>
                            );
                          },
                        )}
                      </ul>
                    ) : (
                      <span className='w-full text-[#767676] pl-2 pt-2 pb-4 border-b-[1px] border-dashed border-[#E5E5EC]'>
                        추가된 옵션이 없습니다!
                      </span>
                    )}
                  </div>
                </li>
              </ul>
            </div>
            {/* 상세 정보 - 구매정보 박스 */}
            <div
              className={classNames(
                'transition-all py-[24px] pb-8',
                currentInfo === 'buy'
                  ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
                  : 'h-0 opacity-0 translate-y-[20px] z-[-100] pointer-events-none fixed',
              )}
            >
              <ul className='w-full flex flex-col justify-start items-start gap-2'>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-stretch py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>유형</p>
                  <p className='text-[#111111] flex flex-row grow justify-between items-center'>
                    <span>
                      {quoteDetail ? (
                        quoteDetail?.purchase &&
                        quoteDetail.purchase?.purchaseInfo?.method ? (
                          quoteDetail.purchase.purchaseInfo.method
                        ) : (
                          '없음'
                        )
                      ) : (
                        <span className='text-[#999999]'>없음</span>
                      )}
                    </span>
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>구입주체</p>
                  <p className='text-[#111111]'>
                    {quoteDetail?.purchase &&
                      (quoteDetail.purchase.purchaseInfo?.리스 ? (
                        `${convertBuyType[quoteDetail.purchase.purchaseInfo.리스.buyerType]}`
                      ) : quoteDetail.purchase.purchaseInfo?.렌트 ? (
                        `${convertBuyType[quoteDetail.purchase.purchaseInfo.렌트.buyerType]}`
                      ) : quoteDetail.purchase.purchaseInfo?.할부 ? (
                        `${convertBuyType[quoteDetail.purchase.purchaseInfo.할부.buyerType]}`
                      ) : quoteDetail.purchase.purchaseInfo?.일시불 ? (
                        `${convertBuyType[quoteDetail.purchase.purchaseInfo.일시불.buyerType]}`
                      ) : (
                        <span className='text-[#999999]'>없음</span>
                      ))}
                  </p>
                </li>
                {quoteDetail?.purchase?.purchaseInfo.method &&
                  quoteDetail.purchase.purchaseInfo.method !== '일시불' && (
                    <>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                        <p className='text-[#767676] min-w-[23vw]'>
                          {quoteDetail.purchase.purchaseInfo?.할부
                            ? '할부기간'
                            : '계약기간'}
                        </p>
                        <p className='text-[#111111]'>
                          {quoteDetail.purchase.purchaseInfo?.리스 ? (
                            `${quoteDetail.purchase.purchaseInfo.리스.contractTerm}개월`
                          ) : quoteDetail.purchase.purchaseInfo?.렌트 ? (
                            `${quoteDetail.purchase.purchaseInfo.렌트.contractTerm}개월`
                          ) : quoteDetail.purchase.purchaseInfo?.할부 ? (
                            `${quoteDetail.purchase.purchaseInfo.할부.installmentTerm}개월`
                          ) : (
                            <span className='text-[#999999]'>없음</span>
                          )}
                        </p>
                      </li>
                    </>
                  )}
                {quoteDetail?.purchase?.purchaseInfo.method &&
                  quoteDetail.purchase.purchaseInfo.method === '할부' && (
                    <>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>
                          선수금(인도금)
                        </p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.할부
                            ?.prepaidPrincipalAmount
                            ? `${quoteDetail.purchase.purchaseInfo.할부.prepaidPrincipalAmount.toLocaleString()}원`
                            : '없음'}
                          {quoteDetail.purchase.purchaseInfo.할부
                            ?.isRegistrationFee &&
                          quoteDetail.purchase.purchaseInfo.할부
                            ?.isRegistrationFee === true
                            ? ' (등록비 포함)'
                            : ' (등록비 별도)'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>할부원금</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.할부
                            ?.installmentPrincipal
                            ? `${quoteDetail.purchase.purchaseInfo.할부.installmentPrincipal.toLocaleString()}원`
                            : quoteDetail.purchase.purchaseInfo.할부
                                  ?.installmentPrincipal === null
                              ? '생략'
                              : '없음'}
                        </p>
                      </li>
                    </>
                  )}
                {quoteDetail?.purchase?.purchaseInfo.method &&
                  (quoteDetail.purchase.purchaseInfo.method == '할부' ||
                    quoteDetail.purchase.purchaseInfo.method == '일시불') && (
                    <>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>구입 시기</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.method === '일시불'
                            ? quoteDetail.purchase.purchaseInfo.일시불
                                ?.purchaseDate
                              ? stringDateAddDot(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .purchaseDate,
                                )
                              : quoteDetail.purchase.purchaseInfo.일시불
                                    ?.purchaseDate === null
                                ? '1개월 이내'
                                : '없음'
                            : quoteDetail.purchase.purchaseInfo.할부
                                  ?.purchaseDate
                              ? stringDateAddDot(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .purchaseDate,
                                )
                              : quoteDetail.purchase.purchaseInfo.할부
                                    ?.purchaseDate === null
                                ? '1개월 이내'
                                : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>거주지역</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.method === '일시불'
                            ? quoteDetail.purchase.purchaseInfo.일시불
                                ?.residenceRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .residenceRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .residenceRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'
                            : quoteDetail.purchase.purchaseInfo.method ===
                                  '할부' &&
                                quoteDetail.purchase.purchaseInfo.할부
                                  ?.residenceRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .residenceRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .residenceRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>인수지역</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.method === '일시불'
                            ? quoteDetail.purchase.purchaseInfo.일시불
                                ?.deliveryRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .deliveryRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .deliveryRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'
                            : quoteDetail.purchase.purchaseInfo.method ===
                                  '할부' &&
                                quoteDetail.purchase.purchaseInfo.할부
                                  ?.deliveryRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .deliveryRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .deliveryRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>면세조건</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.method === '일시불'
                            ? quoteDetail.purchase.purchaseInfo.일시불
                                ?.dutyFreeCondition
                              ? `${
                                  DutyFreeConditionType[
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .dutyFreeCondition
                                  ] ?? ''
                                }`
                              : '없음'
                            : quoteDetail.purchase.purchaseInfo.할부
                                  ?.dutyFreeCondition
                              ? `${
                                  DutyFreeConditionType[
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .dutyFreeCondition
                                  ] ?? ''
                                }`
                              : '없음'}
                        </p>
                      </li>
                    </>
                  )}
                {quoteDetail?.purchase?.purchaseInfo.method &&
                  (quoteDetail.purchase.purchaseInfo.method == '리스' ||
                    quoteDetail.purchase.purchaseInfo.method == '렌트') && (
                    <>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>보증금</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.리스?.price_deposit
                            ? `${quoteDetail.purchase.purchaseInfo.리스.price_deposit}%`
                            : quoteDetail.purchase.purchaseInfo.렌트
                                  ?.price_deposit
                              ? `${quoteDetail.purchase.purchaseInfo.렌트.price_deposit}%`
                              : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>선납금</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.리스
                            ?.price_advancedPayment
                            ? `${quoteDetail.purchase.purchaseInfo.리스.price_advancedPayment}%`
                            : quoteDetail.purchase.purchaseInfo.렌트
                                  ?.price_advancedPayment
                              ? `${quoteDetail.purchase.purchaseInfo.렌트.price_advancedPayment}%`
                              : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>잔존가치</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.리스
                            ?.price_residual
                            ? quoteDetail.purchase.purchaseInfo.리스
                                ?.price_residual === 100
                              ? '최대'
                              : `${quoteDetail.purchase.purchaseInfo.리스.price_residual}%`
                            : quoteDetail.purchase.purchaseInfo.렌트
                                  ?.price_residual
                              ? quoteDetail.purchase.purchaseInfo.렌트
                                  ?.price_residual === 100
                                ? '최대'
                                : `${quoteDetail.purchase.purchaseInfo.렌트.price_residual}%`
                              : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>약정거리</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.리스?.agreeDistace
                            ? `${quoteDetail.purchase.purchaseInfo.리스.agreeDistace.toLocaleString()}km`
                            : quoteDetail.purchase.purchaseInfo.렌트
                                  ?.agreeDistace
                              ? `${quoteDetail.purchase.purchaseInfo.렌트.agreeDistace.toLocaleString()}km`
                              : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>인수지역</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.method === '리스'
                            ? quoteDetail.purchase.purchaseInfo.리스
                                ?.deliveryRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.리스
                                      .deliveryRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.리스
                                      .deliveryRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'
                            : quoteDetail.purchase.purchaseInfo.method ===
                                  '렌트' &&
                                quoteDetail.purchase.purchaseInfo.렌트
                                  ?.deliveryRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.렌트
                                      .deliveryRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.렌트
                                      .deliveryRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'}
                        </p>
                      </li>
                    </>
                  )}
                {quoteDetail?.purchase?.purchaseInfo.method &&
                  quoteDetail.purchase.purchaseInfo.method == '리스' && (
                    <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                      <p className='text-[#767676] min-w-[23vw]'>자동차세</p>
                      <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                        {quoteDetail.purchase.purchaseInfo.리스
                          ?.isIncludingCarTax === true
                          ? '포함'
                          : '미포함'}
                      </p>
                    </li>
                  )}
                {quoteDetail?.purchase?.purchaseInfo.method &&
                  quoteDetail.purchase.purchaseInfo.method == '렌트' && (
                    <>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>정비</p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.렌트
                            ?.maintenancePlan ?? '정비 선택 없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>
                          운전자 연령
                        </p>
                        <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.렌트?.isOver26Age
                            ? '26세 이상'
                            : '21세 이상'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>면책금</p>
                        <p className='text-[#111111] flex justify-start items-center gap-[5px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.렌트
                            ?.indemnityAmount
                            ? slicePrice(
                                quoteDetail.purchase.purchaseInfo.렌트
                                  .indemnityAmount,
                              )
                            : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>
                          보험담보 (자손)
                        </p>
                        <p className='text-[#111111] flex justify-start items-center gap-[5px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.렌트
                            ?.personalInjuryCoverage
                            ? slicePrice(
                                quoteDetail.purchase.purchaseInfo.렌트
                                  .personalInjuryCoverage,
                              )
                            : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                        <p className='text-[#767676] min-w-[23vw]'>
                          보험담보 (대물)
                        </p>
                        <p className='text-[#111111] flex justify-start items-center gap-[5px] w-full'>
                          {quoteDetail.purchase.purchaseInfo.렌트
                            ?.propertyDamageCoverage
                            ? slicePrice(
                                quoteDetail.purchase.purchaseInfo.렌트
                                  .propertyDamageCoverage,
                              )
                            : '없음'}
                        </p>
                      </li>
                    </>
                  )}
                {/* <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>만기처리</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    반납
                  </p>
                </li> */}
              </ul>
            </div>
          </div>
        )}
        {/* 팔기유형 상세 정보 박스 */}
        {quoteDetail?.type === 'sellCar' && (
          <div className='w-full'>
            {/* 중고차 정보 박스 */}
            <div className='w-full py-[14px] text-[16px] font-[500] border-b-[1px] border-[#111111]'>
              차량 정보 [{quoteDetail.selling?.usedCarInfo.carName ?? ''}]
            </div>
            {/* 사진 슬라이드 */}
            {quoteDetail?.selling?.file &&
            !Object.values(quoteDetail.selling.file).every(
              (value) => value === null,
            ) ? (
              <div className='w-full h-[37.6vh] relative mt-[24px] z-0'>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  navigation={navigationOptions}
                  modules={[Navigation]}
                  className='h-full'
                  centeredSlides={true}
                  loop={true}
                  slidesPerGroup={1}
                >
                  {Object.entries(quoteDetail.selling.file).map(
                    ([key, value], index) => {
                      if (key !== null && value !== null) {
                        return (
                          <SwiperSlide key={`file_${key}`} className='relative'>
                            <img
                              src={value.url}
                              alt={value.name}
                              className='w-full h-full object-cover'
                            />
                            <button
                              className='w-10 h-10 absolute bottom-4 right-4 p-2 rounded-full bg-[#11111199] flex justify-center items-center'
                              onClick={() => download(value)}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                height='2.25rem'
                                viewBox='0 -960 960 960'
                                width='2.25rem'
                                fill='#e8eaed'
                              >
                                <path d='M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z' />
                              </svg>
                            </button>
                          </SwiperSlide>
                        );
                      }
                    },
                  )}
                </Swiper>
                <ul className='z-[10] absolute bottom-0 top-0 my-auto left-0 w-full flex justify-between items-center h-[72px]'>
                  <li className='mySwiper-prev cursor-pointer bg-[#00000099] py-[26px] px-[8px] w-[36px] h-full justify-center items-center'>
                    <img src={PrevIcon} alt='이전 버튼' />
                  </li>
                  <li className='mySwiper-next cursor-pointer bg-[#00000099]  py-[26px] px-[8px] w-[36px] h-full justify-center items-center'>
                    <img src={NextIcon} alt='다음 버튼' />
                  </li>
                </ul>
              </div>
            ) : null}
            {/* 상세정보 */}
            <button
              className='text-white font-[600] bg-[#111111] py-[14px] px-[16px] w-full mt-[8px] z-10'
              onClick={() => setUsedCarPopupOpen(!isUsedCarPopupOpen)}
            >
              보험 처리 이력 확인
            </button>
            <div
              className={classNames(
                'transition-all py-[16px] h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto',
              )}
            >
              <ul className='w-full flex flex-col justify-start items-start gap-2'>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>모델</p>
                  <p className='text-[#111111]'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.makername} ${quoteDetail.selling.usedCarInfo.carInfo.modelname}`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>상세등급</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.seriesname}`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>연식</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.year} 년식`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'> 최초등록</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo?.firstdate ? (
                      dayjs(
                        quoteDetail.selling.usedCarInfo.carInfo.firstdate,
                      ).format('YYYY년 MM월 DD일')
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>차체형태</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.cargubun}`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>엔진타입</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.enType}`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>변속기</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.gearbox}`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>배기량</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.displacement.toLocaleString()} cc`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>연료</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.fuel}`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>연비</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.stdmileage}`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>승차인원</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.seating} 명`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>색상</p>
                  <p className='text-[#111111]'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      quoteDetail.selling.usedCarInfo.carInfo.color
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>신차가격</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                      `${quoteDetail.selling.usedCarInfo.carInfo.newprice.toLocaleString()} 원`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>구매방식</p>
                  <p className='text-[#111111]'>
                    {quoteDetail.selling?.boughtMethod ? (
                      convertBoughtType[quoteDetail.selling.boughtMethod]
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>주행거리</p>
                  <p className='text-[#111111]'>
                    {quoteDetail.selling?.drivenDistance ? (
                      `${quoteDetail.selling.drivenDistance.toLocaleString()} km`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-col gap-[3vw] justify-start items-start py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>옵션</p>
                  <div className='text-[#111111] flex flex-col justify-start items-start pl-2 w-full'>
                    {/* 옵션 없을 때 */}
                    {quoteDetail.selling?.additionalInfo.optionSelect
                      .옵션없음 && (
                      <span className='w-full text-[#111111]  py-4 '>
                        옵션없음
                      </span>
                    )}

                    {/* 옵션 선택으로 있을 때 */}
                    {quoteDetail.selling?.additionalInfo.optionSelect
                      ?.optionList &&
                      quoteDetail.selling?.additionalInfo.optionSelect
                        .optionList.length > 0 &&
                      quoteDetail.selling.additionalInfo.optionSelect.optionList.map(
                        (item, index) => {
                          return (
                            <div
                              className='w-full text-[#111111] py-4 flex justify-between items-center border-b-[1px] border-dashed border-[#E5E5EC]'
                              key={`${item.optname}_${index}`}
                            >
                              <span className='text-[#767676]'>
                                {item.optname}
                              </span>
                              <span className='mr-[2px] pr-[10px]'>
                                + {item.optprice.toLocaleString()} 원
                              </span>
                            </div>
                          );
                        },
                      )}
                    {/* 옵션 직접입력으로 할 때 */}
                    {quoteDetail.selling?.additionalInfo.optionSelect.직접입력
                      .selected && (
                      <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                        <span className='font-[600]'>직접입력</span>
                        <p className='text-[13px] whitespace-pre-wrap'>
                          {quoteDetail.selling?.additionalInfo.optionSelect
                            .직접입력.content
                            ? ReactHtmlParser(
                                quoteDetail.selling?.additionalInfo.optionSelect
                                  .직접입력.content,
                              )
                            : '없음'}
                        </p>
                      </div>
                    )}
                  </div>
                </li>
                <li className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>사고 내역</p>
                  <div className='text-[#111111] flex flex-col justify-start items-start gap-[10px] pb-4 w-full'>
                    {/* 사고 없음 */}
                    {quoteDetail.selling?.additionalInfo.accident.사고없음
                      .selected && (
                      <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                        <span className='font-[600]'>사고 없음</span>
                        {quoteDetail.selling.additionalInfo.accident.사고없음
                          ?.content && (
                          <p className='text-[13px] whitespace-pre-wrap'>
                            {quoteDetail.selling.additionalInfo.accident
                              .사고없음.content
                              ? ReactHtmlParser(
                                  quoteDetail.selling.additionalInfo.accident
                                    .사고없음.content,
                                )
                              : '없음'}
                          </p>
                        )}
                      </div>
                    )}
                    {/* 사고 선택 */}
                    {quoteDetail.selling?.additionalInfo.accident.판금교환
                      .selected && (
                      <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                        <span className='font-[600]'>
                          판금 / 교환 부위가 있습니다
                        </span>
                        {quoteDetail.selling.additionalInfo.accident.판금교환
                          ?.content && (
                          <p className='text-[13px] whitespace-pre-wrap'>
                            {quoteDetail.selling.additionalInfo.accident
                              .판금교환.content
                              ? ReactHtmlParser(
                                  quoteDetail.selling.additionalInfo.accident
                                    .판금교환.content,
                                )
                              : '없음'}
                          </p>
                        )}
                      </div>
                    )}
                    {/* 사고 모르겠음 */}
                    {quoteDetail.selling?.additionalInfo.accident.모름
                      .selected && (
                      <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                        <span className='font-[600]'>잘 모르겠습니다.</span>
                        {quoteDetail.selling.additionalInfo.accident.모름
                          ?.content && (
                          <p className='text-[13px] whitespace-pre-wrap'>
                            {quoteDetail.selling.additionalInfo.accident.모름
                              .content
                              ? ReactHtmlParser(
                                  quoteDetail.selling.additionalInfo.accident
                                    .모름.content,
                                )
                              : '없음'}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </li>
                <li className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2 pb-10 '>
                  <p className='text-[#767676] min-w-[23vw]'>차량의 상태</p>
                  <div className='text-[#111111] flex flex-col justify-start items-start gap-[10px] pb-4 w-full'>
                    {/* 타이어 교체 필요 */}
                    {quoteDetail.selling?.additionalInfo.carState.타이어교체
                      .selected && (
                      <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                        <span className='font-[600]'>타이어 교체 필요</span>
                        {quoteDetail.selling.additionalInfo.carState.타이어교체
                          ?.content && (
                          <p className='text-[13px] whitespace-pre-wrap'>
                            {quoteDetail.selling.additionalInfo.carState
                              .타이어교체.content
                              ? ReactHtmlParser(
                                  quoteDetail.selling.additionalInfo.carState
                                    .타이어교체.content,
                                )
                              : '없음'}
                          </p>
                        )}
                      </div>
                    )}
                    {/* 외판 스크래치 */}
                    {quoteDetail.selling?.additionalInfo.carState.외관스크래치
                      .selected && (
                      <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                        <span className='font-[600]'>외판 스크래치</span>
                        {quoteDetail.selling.additionalInfo.carState
                          .외관스크래치?.content && (
                          <p className='text-[13px] whitespace-pre-wrap'>
                            {quoteDetail.selling.additionalInfo.carState
                              .외관스크래치.content
                              ? ReactHtmlParser(
                                  quoteDetail.selling.additionalInfo.carState
                                    .외관스크래치.content,
                                )
                              : '없음'}
                          </p>
                        )}
                      </div>
                    )}
                    {/* 정비 및 수리 필요 */}
                    {quoteDetail.selling?.additionalInfo.carState.외관스크래치
                      .selected && (
                      <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                        <span className='font-[600]'>정비 및 수리 필요</span>
                        {quoteDetail.selling.additionalInfo.carState
                          .정비수리필요?.content && (
                          <p className='text-[13px] whitespace-pre-wrap'>
                            {quoteDetail.selling.additionalInfo.carState
                              .정비수리필요.content
                              ? ReactHtmlParser(
                                  quoteDetail.selling.additionalInfo.carState
                                    .정비수리필요.content,
                                )
                              : '없음'}
                          </p>
                        )}
                      </div>
                    )}
                    {/* 내 차의 장단점 */}
                    {quoteDetail.selling?.additionalInfo.carState.차량장단점
                      .selected && (
                      <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                        <span className='font-[600]'>내 차의 장단점</span>
                        {quoteDetail.selling.additionalInfo.carState.차량장단점
                          ?.content && (
                          <p className='whitespace-pre-wrap text-[13px]'>
                            {ReactHtmlParser(
                              quoteDetail.selling.additionalInfo.carState
                                .차량장단점.content,
                            )}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      {/* 플로팅 메뉴네비바 */}
      {/* <CarsayoFloatingNavMenu
        isOpen={isFloatingOpen}
        onAction={() => setFloatingOpen(!isFloatingOpen)}
        actions={actions}
      /> */}

      {/* 신차, 리스/렌트 차량가격 안내 팝업 */}
      <Drawer
        open={isExpectedOpen}
        onOpenChange={(open) => setExpectedOpen(open)}
      >
        <DrawerContent className='flex flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>차량 가격</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <div className='grow overflow-y-auto p-4'>
            <div className='mt-2 border-b py-4'>
              <div className='text-desc'>신청 모델</div>
              <div className='mt-2 space-y-2'>
                <div className='flex justify-between'>
                  <div className='text-[##555555]'>
                    {quoteDetail?.purchase?.selectedCar.carModel?.name ?? ''}{' '}
                    {quoteDetail?.purchase?.selectedCar.carTrim?.name ?? ''}
                  </div>
                  <div className='text-[##555555]'></div>
                </div>
              </div>
            </div>
            <div className='mt-2 border-b py-4'>
              <div className='text-desc'>상세 등급</div>
              <div className='mt-2 space-y-2'>
                <div className='flex justify-between'>
                  <div className='text-[##555555]'>
                    {quoteDetail?.purchase?.selectedCar.carGrade?.name ?? ''}
                  </div>
                  <div className='text-desc font-semibold'>
                    {quoteDetail?.purchase?.selectedCar.carGrade?.price
                      ? quoteDetail.purchase.selectedCar.carGrade.price.toLocaleString()
                      : 0}
                    원
                  </div>
                </div>
              </div>
            </div>
            <div className='mt-2 border-b py-4'>
              <div className='text-desc'>색상</div>
              {quoteDetail?.purchase?.selectedCar.carColor ? (
                <div className='mt-2 space-y-2'>
                  <div className='flex justify-between'>
                    <div className='text-[##555555]'>
                      {quoteDetail?.purchase?.selectedCar.carColor?.name}
                    </div>
                    {quoteDetail?.purchase?.selectedCar.carColor?.price &&
                    quoteDetail?.purchase?.selectedCar.carColor.price > 0 ? (
                      <div className='text-desc font-semibold'>
                        +{' '}
                        {quoteDetail?.purchase?.selectedCar.carColor?.price.toLocaleString()}
                        원
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ) : (
                <div className='mt-2 text-center'>
                  색상은 상담 시 논의합니다.
                </div>
              )}
            </div>
            <div className='mt-2 border-b py-4'>
              <div className='text-desc'>추가 옵션</div>
              {quoteDetail?.purchase?.selectedCar.carOptionList &&
              quoteDetail?.purchase?.selectedCar.carOptionList.length > 0 ? (
                <div className='mt-2 space-y-2'>
                  {quoteDetail?.purchase?.selectedCar.carOptionList.map(
                    (el) => {
                      return (
                        <div key={el.id} className='flex justify-between'>
                          <div className='text-[##555555]'>{el.name}</div>
                          <div className='text-desc font-semibold'>
                            + {el.price.toLocaleString()}원
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              ) : (
                <div className='mt-2 text-center'>추가 옵션이 없습니다.</div>
              )}
            </div>
            <div className='flex items-center justify-between py-4'>
              <div className='text-primary'>총 예상 금액</div>
              {quoteDetail?.purchase?.selectedCar && (
                <div className='font-semibold text-primary'>
                  {getPurchasePrice(
                    quoteDetail.purchase.selectedCar,
                  ).toLocaleString()}
                  원
                </div>
              )}
            </div>
            <div className='bg-[#F7F7FB] p-4 text-desc text-[#767676]'>
              · 현재 시세에 따라 금액은 변동될 수 있습니다.
            </div>
            <div className='mt-10 pb-6'>
              <Button
                onClick={() => {
                  setExpectedOpen(false);
                }}
              >
                확인
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
      {/* 내차팔기 팝업 */}
      {quoteDetail?.selling?.accidentHistory && (
        <SellingAccidentHistoryDetail
          isOpen={isUsedCarPopupOpen}
          setIsOpen={setUsedCarPopupOpen}
          accidentHistory={quoteDetail.selling.accidentHistory}
        />
      )}

      <Cancel isOpen={isCancel} setIsOpen={setIsCancel} />
    </>
  );
};
export default QuoteBoxDetail;
