/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useState } from 'react';
import { CharacterDataArray, CharacterSubDataInterface } from './data';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BackIcon from 'components/svg/BackIcon';
import Expression from 'components/svg/Expression';
import useSystemStore from 'store/useSystemStore';

const CharacterDetail = () => {
  const { setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentCharacter, setCurrentCharacter] =
    useState<CharacterSubDataInterface | null>(null);
  const [isShown, setIsShown] = useState('');

  const handleClickEvents = {
    goNext: () => {
      setIsShown('hidden');
      if (id) {
        if (id === '01') {
          navigate(`/static/character/detail/02`);
        } else if (id === '02') {
          navigate(`/static/character/detail/03`);
        } else {
          navigate(`/static/character/detail/01`);
        }
      } else {
        navigate(`/static/character/detail/01`);
      }
    },
  };

  useLayoutEffect(() => {
    setIsBottomTabbar(false);
  }, []);

  useLayoutEffect(() => {
    const goBackHome = () => {
      navigate('/static/character');
    };
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', goBackHome);
    return () => window.removeEventListener('popstate', goBackHome);
  }, [navigate]);

  useEffect(() => {
    const character = CharacterDataArray.find((char) => char.id === id) ?? null;
    setCurrentCharacter(character);
    setIsShown('active');

    return () => {
      setIsShown('hidden');
    };
  }, [id]);

  return (
    <div className='w-full h-ull bg-white'>
      {currentCharacter && (
        <div
          className={`m-character-sub ${isShown}`}
          style={{
            backgroundBlendMode: 'multiply',
            background: currentCharacter.bg,
          }}
        >
          <div className='m-character-sub__title'>
            <h3 style={{ color: currentCharacter.titleColor }}>
              {currentCharacter.title}
            </h3>
          </div>
          <div className='m-character-sub__contents'>
            <div className='m-character-sub__contents__box'>
              <div className='m-character-sub__contents__box__title'>
                <span></span>
                <p>{currentCharacter.description}</p>
              </div>
              <div className='m-character-sub__contents__box__content'>
                <ul>
                  {currentCharacter.imgArray.length > 0 &&
                    currentCharacter.imgArray.map((item, index) => {
                      return (
                        <li>
                          <img
                            src={item}
                            alt={`${currentCharacter.title} 상세 이미지 ${index + 1}`}
                          />
                        </li>
                      );
                    })}
                </ul>
                <Expression
                  className='mb'
                  textColor={currentCharacter.nextColor}
                  fill={currentCharacter.nextBg}
                  clickEvent={handleClickEvents.goNext}
                  text={currentCharacter.nextText}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CharacterDetail;
